import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// @Endpoint
import { AuthEndpoint } from 'api/endpoints';

// @Hooks
import { useLogout } from 'hooks/useLogout';
import { useGetAccessibleNavItems } from 'hooks/useUserAccess';

// @Icons
import ArriveNow from 'icons/ArriveNow.svg';

// @Routes
import routes from '../../../routes';

// @Styles
import { NavContainer, NavLink, ArriveLogo, LogoutLink } from './NavBarStyles';

// @Types
import { ShipperPortalUserInformation } from 'types/User.types';

// @Utils
import { removeLastForwardSlash, onlyAllowAlphaNumeric } from 'utils/utils';

const NavBar = (): ReactElement => {
  const queryClient = useQueryClient();
  // check if the user has accepted the terms
  const termsAccepted = (
    queryClient.getQueryData([
      'shipperPortalUserInfo',
    ]) as ShipperPortalUserInformation
  )?.UserHasAcceptedAgreement;

  // get nav items array
  const navItems = useGetAccessibleNavItems();

  const handleLogout = useLogout();
  const selectedTab = useLocation().pathname;

  const isConfirmationPage =
    removeLastForwardSlash(selectedTab) ===
    removeLastForwardSlash(routes.SHIPMENTCONFIRMATION);

  return (
    <>
      <NavContainer aria-label={'navigation-bar'}>
        <ArriveLogo>
          <ArriveNow data-testid="arrive-now-logo" />
        </ArriveLogo>
        {termsAccepted && navItems.length ? (
          navItems.map((navItem, idx) => {
            return (
              <NavLink
                aria-label={`${navItem.title.replace(' ', '-')}`}
                data-testid={`nav-link-${idx}`}
                key={navItem.title}
                selected={
                  (navItem.route === selectedTab ||
                    navItem.title.toUpperCase() ===
                      onlyAllowAlphaNumeric(selectedTab).toUpperCase() ||
                    (isConfirmationPage && idx === 0)) &&
                  termsAccepted
                }
                to={navItem.route}
              >
                {navItem.title}
              </NavLink>
            );
          })
        ) : (
          <div data-test-id="empty-nav"></div>
        )}
        <LogoutLink
          onClick={handleLogout}
          to={AuthEndpoint.auth.logout}
          aria-label="Logout Button"
          data-testid={'logout'}
        >
          Logout
        </LogoutLink>
      </NavContainer>
    </>
  );
};

export default NavBar;
