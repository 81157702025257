import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'mapbox-gl/dist/mapbox-gl.css';

//@DevTools
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

Sentry.init({
  attachStacktrace: true,
  debug: true,
  dsn: 'https://842d778bc49045b5be4c9af1930982d2@o1185635.ingest.sentry.io/6533888',
  enabled:
    process.env.NODE_ENV !== 'development' || process.env.WHICH_ENV !== 'DEV', // disable for pr envs
  environment: process.env.WHICH_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations:
    process.env.WHICH_ENV === 'PROD'
      ? [new BrowserTracing(), new Sentry.Replay()]
      : [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

// @Components
import App from './App';
import SecurityWrapper from './components/Auth/SecurityWrapper';
import { QueryClient } from '@tanstack/react-query';

// @Context
import AuthProvider from 'context/AuthProvider';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

root.render(
  <Router>
    <SecurityWrapper>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
    </SecurityWrapper>
  </Router>,
);
