import React from 'react';

// @Common Components
import { TableRow } from '@arrive/table';
import Typography from 'commonComponents/Typography/Typography';
import TableReferenceNumbers from 'commonComponents/Table/TableReferenceNumbers';
import TableStops from 'commonComponents/Table/TableStops';

// @Hooks
import { useSetQueryParams } from 'hooks/useReactRouterQueries';

// @Styles
import { TableDataCell } from 'commonComponents/Table/TableStyles';

// @Types
import { ShipmentRowProps } from '../ShipmentPage.types';

const ShipmentRow = ({ shipment }: ShipmentRowProps) => {
  const {
    LoadNumber,
    Stops: LoadStops,
    Status,
    EquipmentType,
    Mode,
    ReferenceNumbers,
  } = shipment;

  const setShipmentQueryParams = useSetQueryParams();

  const handleOpenShipmentDetailsPanel = () => {
    setShipmentQueryParams(
      {
        sidePanel: 'shipment',
        loadNumber: LoadNumber,
      },
      false,
    );
  };

  return (
    <TableRow
      data-testid={`shipment-${LoadNumber}`}
      onClick={handleOpenShipmentDetailsPanel}
    >
      <TableDataCell>
        <TableReferenceNumbers
          arriveId={LoadNumber}
          referenceNumbers={ReferenceNumbers}
        />
      </TableDataCell>
      <TableDataCell>
        <Typography variant="overline" as="p">
          {Status}
        </Typography>
      </TableDataCell>
      <TableDataCell>
        <TableStops stops={LoadStops['Pickup']} type="pickup" />
      </TableDataCell>
      <TableDataCell>
        <TableStops stops={LoadStops['Delivery']} type="delivery" />
      </TableDataCell>
      <TableDataCell>
        <Typography variant="body2" as="p">
          {EquipmentType}
        </Typography>
        <Typography variant="body2" as="p">
          {Mode}
        </Typography>
      </TableDataCell>
    </TableRow>
  );
};

export default ShipmentRow;
