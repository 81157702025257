import { DateTime } from 'luxon';
import QueryString from 'qs';

// @Types
import { StatusEnumFormat, StopType } from 'types/Shipment.types';
import {
  ShipmentDetailsPickupDeliveryInfo,
  ShipmentsTrackingDataType,
} from './ShipmentPage.types';

export const getStatusTitle = (statuses: StatusEnumFormat[]) => {
  const placeholder =
    statuses.length === 1 ? statuses[0].Description : 'Status';
  const selectedCount = statuses.length > 1 ? statuses.length : undefined;
  return { placeholder, selectedCount };
};

export const formatQueryDateString = (date: Date | null) =>
  date ? DateTime.fromJSDate(date).toString().split('T')[0] : null;

export const getDateFilterTitle = (
  date: Date | null,
  dateEnd: Date | null,
  stopType: 'Pickup' | 'Delivery',
) => {
  if (!date && !dateEnd) {
    return `${stopType} Date`;
  }
  const initialDate = DateTime.fromJSDate(date!).toFormat('ccc LLLL dd');
  const endDate = dateEnd
    ? DateTime.fromJSDate(dateEnd).toFormat('ccc LLLL dd')
    : '';
  const endDateString =
    !endDate || initialDate === endDate ? '' : ` - ${endDate}`;
  return `${stopType} ${initialDate + endDateString}`;
};

export const convertQueryValueToDate = (
  queryValue:
    | string
    | string[]
    | QueryString.ParsedQs
    | QueryString.ParsedQs[]
    | undefined,
) =>
  queryValue?.length ? DateTime.fromISO(queryValue as string).toJSDate() : null;

export const getSortedStopInformationArray = (
  Stops: Record<StopType, ShipmentDetailsPickupDeliveryInfo[]>,
): ShipmentDetailsPickupDeliveryInfo[] | [] => {
  return [Stops.Pickup, Stops.Delivery]
    .flat()
    .sort((a, b) => a.SequenceNumber - b.SequenceNumber);
};

export const formatPaginatedTrackingData = (
  trackingDataArray: ShipmentsTrackingDataType[] | undefined,
) => {
  if (trackingDataArray === undefined || trackingDataArray.length === 0) {
    return { trackingData: [], totalCount: 0 };
  }
  return trackingDataArray.reduce((acc, cv) => {
    return {
      ...acc,
      totalCount: cv.totalCount,
      trackingData: [...acc.trackingData, ...cv.trackingData],
    };
  });
};
