import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';

// @Common Components
import Section from 'commonComponents/Section/Section';
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';
import QuoteForm from 'commonComponents/QuoteForm/QuoteForm';
import ErrorMessage from 'commonComponents/Error/ErrorMessage';

// @Components
import QuoteResults from './QuoteResults/QuoteResults';
import QuoteHistory from './QuoteHistory/QuoteHistory';

// @Hooks
import { useSendQuoteRequest } from 'hooks/useGetRateDetailsForQuote';
import { useSubmitQuote } from 'hooks/useSubmitQuote';
import { useQueryParams } from 'hooks/useReactRouterQueries';
import { useGetQuoteResultStatus } from 'hooks/useGetQuoteStatus';

// @Styles
import { PageContainer } from 'styles/PageStyles';

// @Types
import { QuoteResultRate, SendQuoteOptions } from 'types/quoteTypes.d';
import { UserLoginInformation } from 'types/User.types';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const InitialLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Quotes = () => {
  const { quoteResult = '' } = useQueryParams();
  const { submitQuote, isSubmittingQuote, defaultForm } = useSubmitQuote();
  const {
    mutate: sendQuoteRequest,
    isLoading: isSendingQuoteRequest,
    isSuccess: isQuoteRequestSuccessful,
    isError: isQuoteRequestError,
  } = useSendQuoteRequest();

  const queryClient = useQueryClient();
  const customerId = (
    queryClient.getQueryData(['currentUser']) as UserLoginInformation
  )?.customerId;

  const quoteDetails = queryClient.getQueryData([
    'quoteDetails',
  ]) as QuoteResultRate;

  const { data: quoteStatus, isInitialLoading: isLoadingQuoteStatus } =
    useGetQuoteResultStatus(quoteDetails?.quoteReference || '');

  const [selectedOptions, setSelectedOptions] = useState<SendQuoteOptions>({
    truckType: 'FTL',
    equipmentType: null,
    pickupLocation: null,
    deliveryLocation: null,
    pickupDate: null,
  });

  const handleIsSubmitted = async (request: SendQuoteOptions) => {
    sendQuoteRequest(request);
    queryClient.invalidateQueries(['quoteHistory']);
  };

  const renderQuoteHistoryOrResults = () => {
    if (isSendingQuoteRequest || isLoadingQuoteStatus) {
      return (
        <LoadingContainer>
          <SpinningLoadingIndicator height={'64px'} width={'64px'} />
        </LoadingContainer>
      );
    }

    if (isQuoteRequestError || (!!quoteResult.length && !quoteDetails)) {
      return (
        <ErrorMessage
          errorTitle="Sorry, there was an error retrieving your quote."
          errorSubtitle="We recommend waiting a few minutes and trying again."
          dataTestId="quote-results-error"
        />
      );
    }

    if (!!quoteResult.length && quoteDetails && quoteStatus) {
      return (
        <QuoteResults
          submitQuote={submitQuote}
          quoteDetails={quoteDetails}
          quoteStatus={quoteStatus}
        />
      );
    }

    return (
      <QuoteHistory
        submitDisabled={isSubmittingQuote}
        submitQuote={submitQuote}
      />
    );
  };

  if (!customerId) {
    return (
      <InitialLoadingContainer>
        <SpinningLoadingIndicator height={'256'} width={'256'} />
      </InitialLoadingContainer>
    );
  }

  return (
    <PageContainer>
      <Section
        title="Ship with Arrive"
        variant="title"
        dataTestId="quote-form-ship-with-arrive"
      >
        <Section
          title={isQuoteRequestSuccessful ? '' : 'Start a new quote below.'}
          variant="subtitle"
          dataTestId="quote-form-start-new-quote"
        >
          <QuoteForm
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            handleIsSubmitted={handleIsSubmitted}
            defaultOptions={defaultForm}
            isSendingQuoteRequest={isSendingQuoteRequest}
          />
        </Section>
      </Section>
      {renderQuoteHistoryOrResults()}
    </PageContainer>
  );
};

export default Quotes;
