import { useContext, useMemo } from 'react';
import debounceAsync from 'utils/debounceAsync';
import qs from 'qs';

// @API
import { LocationServiceEndpoints } from 'api/endpoints';

// @Arrive
import { ToastContext } from '@arrive/toasts';

// @Hooks
import useAxios from './useAxios';

// @Types
import { GetAddressRequest, LocationServiceAddress } from 'types/Address.types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BEError } from 'types/Error.types';
import { GroupBase, OptionsOrGroups } from 'react-select';

/**
 * Use this hook on your Async Select or Creatable.
 * Async Select comes with built in useEffect to fetch data.
 * Therefore you do not require to write your own useEffect and fetch data
 * Good use case is React Select Async Dropdowns
 */
export const useGetAddressSuggestionLoadOptions = ({
  city,
  state,
  postalCode,
}: GetAddressRequest) => {
  const axios = useAxios();
  const addToastNotification = useContext(ToastContext);

  const fetchAddressSuggestions = async (address: string) => {
    if (address.trim().length < 3) {
      return [];
    }
    try {
      const { data } = await axios.get(
        `${LocationServiceEndpoints.getSuggestions}${qs.stringify({
          search: `${address} ${city} ${state} ${postalCode}`,
          cities: city?.trim(),
          zips: postalCode?.trim(),
        })}`,
      );

      return data.result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: BEError | any) {
      addToastNotification({
        id: 'address-suggestion-error',
        type: 'error',
        content: error.message,
      });
    }
  };

  const memoisedFetch = useMemo(
    () => debounceAsync(fetchAddressSuggestions, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as unknown as (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<
        LocationServiceAddress,
        GroupBase<LocationServiceAddress>
      >,
    ) => void,
  ) => void | Promise<
    OptionsOrGroups<LocationServiceAddress, GroupBase<LocationServiceAddress>>
  >;

  return memoisedFetch;
};
