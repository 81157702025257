import styled from '@emotion/styled';
import { palette } from 'theme/Theme';
import { spacing, fontSizes } from 'theme/Units';

export const StyledInputError = styled.p<{ isWarning?: boolean }>`
  font-size: ${fontSizes.small};
  margin: ${spacing.xsmall} 0px;
  font-weight: 300;
  display: block;
  padding-left: ${spacing.normal};
  color: ${({ isWarning }) =>
    isWarning ? palette.common.accessibleYellow : palette.common.jackknife};
`;

export const TextEntryError = styled.p`
  color: ${palette.common.jackknife};
  font-size: ${fontSizes.medium};
  margin: ${spacing.xsmall} 0px;
  font-weight: 400;
  display: block;
  padding-left: ${spacing.normal};
`;
