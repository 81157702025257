import React from 'react';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';
import TitleDetails from 'commonComponents/TitleDetails';
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Components
import PickupDeliveryReadOut from './PickupDeliveryForm/PickupDeliveryReadOut';

// @Constants
import { EquipmentTypes } from 'constants/EquipmentType';

// @Hooks
import useGetTimeZones from 'hooks/useGetTimeZones';
import { useGetShipment, useShipmentFormEnums } from './useShipmentForm';

// @Icons
import ArrowPickup from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';
import ArrowDelivery from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';

// @Styles
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';
import { LinkButton } from 'styles/Links';
import { SectionWrapper } from 'styles/Section';

// @Types
import { EquipmentTypeOptions } from 'types/quoteTypes';

const ConfirmationPageWrapper = styled.div`
  margin: ${spacing.none} ${spacing.xxlarge};
  padding: ${spacing.none} ${spacing.xxlarge} 281px ${spacing.xxlarge};
`;

const ConfirmationTitle = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: ${spacing.gutter};
    align-items: center;
  }
`;

const InitialLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

// Styles for the pickup and delivery section
const PickupDeliverySection = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex-grow: 1;
  }
`;

// Styles for the shipment section
const ShipmentSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${spacing.xlarge};
`;

const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 1;
`;

const ShipmentDetailsContainer = styled.div`
  grid-column: span 2;
  margin-top: ${spacing.xsmall};
  > div {
    margin-bottom: ${spacing.gutter};
  }
`;

const ConfirmationPage = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { shipmentId } = useParams<{ shipmentId: string }>();

  const { data: selectedQuote, isInitialLoading: isLoadingShipment } =
    useGetShipment(shipmentId);
  const { isInitialLoading: isLoadingShipmentFormEnums } =
    useShipmentFormEnums();

  const handleBackToQuotes = () => {
    queryClient.removeQueries(['quoteDetails']);
    window.localStorage.removeItem('selectedQuote');
    history.replace('/');
  };

  const { data: timezones } = useGetTimeZones(
    selectedQuote
      ? [selectedQuote.pickup.cityState, selectedQuote.delivery.cityState]
      : [],
  );

  if (isLoadingShipment || !selectedQuote || isLoadingShipmentFormEnums) {
    return (
      <InitialLoadingContainer>
        <SpinningLoadingIndicator height={'256'} width={'256'} />
      </InitialLoadingContainer>
    );
  }

  const titleSection = (
    <SectionWrapper variant="heading">
      <ConfirmationTitle>
        <Typography variant="display2">
          Thanks for shipping with Arrive!
        </Typography>
        <div>
          <Typography variant="h2">
            Your rep will follow up with you soon.
          </Typography>
          <LinkButton onClick={handleBackToQuotes}>View all quotes</LinkButton>
        </div>
      </ConfirmationTitle>
    </SectionWrapper>
  );

  const shipmentSection = (
    <SectionWrapper variant="heading" hasBorder>
      <ShipmentSection>
        <RateContainer>
          <Typography variant="subtitle1">Your Rate</Typography>
          <Typography variant="display1">
            {`$${selectedQuote.allInRate.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}`}
          </Typography>
          {!!selectedQuote.totalMiles && (
            <Typography margin="0px">
              {selectedQuote.totalMiles?.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}
              {` miles `}
              <Typography color={palette.common.LFGray}>
                $
                {(selectedQuote.allInRate / selectedQuote.totalMiles).toFixed(
                  2,
                )}
                /mi
              </Typography>
            </Typography>
          )}
        </RateContainer>
        <ShipmentDetailsContainer>
          <TitleDetails title="Mode" details={selectedQuote.shipmentType} />
          <TitleDetails
            title="Equipment"
            details={
              EquipmentTypes[
                selectedQuote.equipmentType as EquipmentTypeOptions
              ].displayName
            }
          />
          <TitleDetails
            title="Items"
            details={[
              selectedQuote.commodityName,
              `${
                selectedQuote.pieceCount
              } ${selectedQuote.pieceUnit?.toLowerCase()}`,
              `${selectedQuote.weightLbs.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })} lbs`,
            ]}
          />
          <TitleDetails
            title="Shipment Value"
            details={`$${selectedQuote.cargoValue?.toString()}`}
          />
          {selectedQuote.equipmentType === 'R' && (
            <TitleDetails
              title="Additional Services"
              details={`Pre-Cool ${selectedQuote.preCoolTempF}ºF, ${selectedQuote.minTempF}ºF - ${selectedQuote.maxTempF}ºF`}
            />
          )}
        </ShipmentDetailsContainer>
        <ShipmentDetailsContainer>
          {!!selectedQuote.loadComments?.length && (
            <TitleDetails
              title="Shipment Notes"
              details={selectedQuote.loadComments}
            />
          )}
          <TitleDetails
            title="Shipment ID"
            details={`#${selectedQuote.externalShipmentId}`}
          />
        </ShipmentDetailsContainer>
      </ShipmentSection>
    </SectionWrapper>
  );

  const pickupDeliverySection = (
    <SectionWrapper variant="heading" hasBorder>
      <PickupDeliverySection>
        <div data-testid="pickup-readout">
          <PickupDeliveryReadOut
            title="Pickup"
            pickupDelivery={selectedQuote.pickup}
            icon={<ArrowPickup />}
            timezone={
              timezones ? timezones[selectedQuote['pickup'].postalCode] : ''
            }
            pickupDate={selectedQuote.pickupDate}
          />
        </div>
        <div data-testid="delivery-readout">
          <PickupDeliveryReadOut
            title="Delivery"
            pickupDelivery={selectedQuote.delivery}
            icon={<ArrowDelivery />}
            timezone={
              timezones ? timezones[selectedQuote['delivery'].postalCode] : ''
            }
          />
        </div>
      </PickupDeliverySection>
    </SectionWrapper>
  );

  return (
    <ConfirmationPageWrapper>
      {titleSection}
      {shipmentSection}
      {pickupDeliverySection}
    </ConfirmationPageWrapper>
  );
};

export default ConfirmationPage;
