import styled from '@emotion/styled';
import { rgba } from 'polished';

export const SidePanelOverlay = styled.div<{ show: boolean }>`
  background-color: ${({ theme }) => rgba(theme.palette.common.axle, 0.5)};
  bottom: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  height: 100%;
  justify-content: flex-end;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: ${({ show }) => (show ? 'auto' : 'hidden')};
  position: fixed;
  right: 0;
  top: 52px;
  z-index: 1050;
`;

export const SidePanelDialog = styled.div<{ show: boolean; width?: string }>`
  animation: fade-in 0.3s ease-in-out 0s forwards;
  background-color: ${({ theme }) => theme.palette.common.ethanol};
  box-shadow: -6px 5px 6px 2px rgb(0 0 0 / 12%);
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: 100%;
  position: fixed;
  right: ${({ show, width }) => (show ? 0 : width)};
  width: ${({ width }) => width};
  z-index: 1051;
  @keyframes fade-in {
    from {
      transform: ${({ width }) => `translateX(${width})`};
    }
    to {
      transform: translateX(0);
    }
  }
`;
