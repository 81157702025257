import { useQuery, UseQueryResult } from '@tanstack/react-query';

// @Api
import { CRMEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from 'hooks/useAxios';

// @Types
import {
  ContactType,
  TeamMemberResponse,
} from 'commonComponents/Footer/ContactInformation/ContactInformation.types';

// @Utils
import { convertPhoneNumber } from 'utils/utils';

export const useGetContactInformation = (
  customerId: number | undefined,
  contactCodes: Array<string>,
): UseQueryResult<Array<ContactType>> => {
  const axios = useAxios();

  const fetchContactInformation = async () => {
    const { data } = await axios.get(
      CRMEndpoints.getCustomerOverview(customerId!),
    );
    return data.teamMembers.flatMap((member: TeamMemberResponse) => {
      if (!contactCodes.includes(member.code)) {
        return [];
      }

      return {
        ...member.users[0],
        title:
          member.name === 'Customer Relations Rep' ? 'Accounting' : member.name,
        phoneNumber: convertPhoneNumber(member.users[0].phoneNumber),
      };
    });
  };

  return useQuery(
    ['contactInformation', customerId],
    fetchContactInformation,

    { enabled: !!customerId },
  );
};
