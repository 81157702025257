import React from 'react';

// @Common Components
import TitleSubtitle from 'commonComponents/TitleSubtitle';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import QuoteMap from './QuoteMap/QuoteMap';
import ExpirationMessage from './ExpirationMessage';
import RateResults from './RateResults';

// @Constants
import { EquipmentTypes } from 'constants/EquipmentType';

// @Icons
import ArrowPickup from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';
import ArrowDelivery from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';
import MilesIcon from '@arrive/ui-icons/svg/ic_miles.svg';
import VanIcon from '@arrive/ui-icons/svg/ic_dry_van.svg';
import ModeIcon from '@arrive/ui-icons/svg/ic_drop_trailer.svg';

// @Libraries
import { format, parseISO } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';

// @Hooks
import { useSetQueryParams } from 'hooks/useReactRouterQueries';
import { useExpirationCountdown } from 'hooks/useExpirationCountdown';

// @Styles
import {
  QuoteInformationContainer,
  QuoteInformationTitle,
  QuoteMapContainer,
  QuoteResultsContainer,
  TruckInformation,
  PickupDeliveryContainer,
} from './QuoteResultsStyles';
import { LinkButton } from 'styles/Links';

// @Theme
import { palette } from 'theme/Theme';

// @Types
import { QuoteRate, QuoteResultRate, QuoteStatus } from 'types/quoteTypes.d';

interface QuoteResultsProps {
  submitQuote: (quote: QuoteRate) => Promise<void>;
  quoteDetails: QuoteResultRate;
  quoteStatus: QuoteStatus;
}

const QuoteResults = ({
  submitQuote,
  quoteDetails,
  quoteStatus,
}: QuoteResultsProps) => {
  const setQueryParams = useSetQueryParams();
  const queryClient = useQueryClient();

  const { singleUpdatedExpiration: updatedQuoteStatus } =
    useExpirationCountdown(quoteStatus, true);

  const fullQuoteDetails = {
    ...quoteDetails,
    ...(updatedQuoteStatus as QuoteStatus),
  };

  const handleSeeAllQuotes = () => {
    queryClient.removeQueries(['quoteDetails']);
    setQueryParams({});
  };

  const quoteInformationTitle = (
    <QuoteInformationTitle>
      <Typography color={palette.common.ethanol} variant="h2">
        Your Quote Results
      </Typography>
      {!!updatedQuoteStatus && (
        <ExpirationMessage
          expirationStatus={updatedQuoteStatus?.expirationStatus}
          align="right"
          theme="dark"
          direction="row"
          timeLeft={updatedQuoteStatus.timeLeftUntilQuoteExpires}
        />
      )}
    </QuoteInformationTitle>
  );

  const truckInformation = (
    <TruckInformation
      numberOfElements={!!quoteDetails.totalMiles ? 3 : 2}
      data-testid="truck-info"
    >
      {!!quoteDetails.totalMiles && (
        <div>
          <MilesIcon />
          <Typography variant="body2" color={palette.common.ethanol}>
            {quoteDetails.totalMiles?.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}{' '}
            miles
          </Typography>
        </div>
      )}
      <div>
        <ModeIcon />
        <Typography variant="body2" color={palette.common.ethanol}>
          {quoteDetails.mode}
        </Typography>
      </div>
      <div>
        <VanIcon />
        <Typography variant="body2" color={palette.common.ethanol}>
          {quoteDetails?.equipmentType
            ? EquipmentTypes[quoteDetails.equipmentType].displayName
            : ''}
        </Typography>
      </div>
    </TruckInformation>
  );

  const pickupDeliveryInformation = (
    <PickupDeliveryContainer>
      <TitleSubtitle
        title={`${quoteDetails?.pickupLocation} ${quoteDetails?.pickupPostalCode}`}
        subtitle={
          quoteDetails?.pickupDateString ??
          format(parseISO(quoteDetails?.pickupDate), 'EE, MMMM dd')
        }
        icon={<ArrowPickup />}
        variants={{
          title: 'h2',
          subtitle: 'body1',
        }}
      />
      <TitleSubtitle
        title={`${quoteDetails?.deliveryLocation} ${quoteDetails?.deliveryPostalCode}`}
        subtitle=""
        icon={<ArrowDelivery />}
        variants={{
          title: 'h2',
          subtitle: 'body1',
        }}
      />
    </PickupDeliveryContainer>
  );

  return (
    <>
      <QuoteResultsContainer data-testid="quote-results">
        <QuoteInformationContainer>
          {quoteInformationTitle}
          <RateResults
            quoteDetails={fullQuoteDetails}
            submitQuote={submitQuote}
          />
          {truckInformation}
        </QuoteInformationContainer>
        <QuoteMapContainer>
          {pickupDeliveryInformation}
          <QuoteMap
            hasQuoteRate={!!quoteDetails.rate}
            pickupGeolocation={quoteDetails.pickupGeolocation}
            deliveryGeolocation={quoteDetails.deliveryGeolocation}
            totalMiles={quoteDetails.totalMiles}
          />
          <LinkButton
            onClick={handleSeeAllQuotes}
            data-testid="contact-footer-all-quotes"
          >
            See All Quotes
          </LinkButton>
        </QuoteMapContainer>
      </QuoteResultsContainer>
    </>
  );
};

export default QuoteResults;
