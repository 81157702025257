import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

// @arrive
import Button from '@arrive/button';

// @Common Components
import NavBar from 'commonComponents/NavBar/NavBar';
import Typography from 'commonComponents/Typography/Typography';
import Footer from 'commonComponents/Footer/Footer';

// @Icons
import FourZeroFour from 'icons/404.svg';
import FourZeroThree from 'icons/403.svg';
import FiveHundred from 'icons/500.svg';

// @Style
import { spacing } from 'theme/Units';

// @Types
import { AxiosError } from 'axios';

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  & > svg {
    width: 534px;
    height: 266;
  }

  & > button {
    width: 212px;
    margin-bottom: ${spacing.medium};
  }
`;

const ErrorComponent = ({ error }: { error: AxiosError }) => {
  const { response } = error;

  // history
  const history = useHistory();

  const handleGoBack = () => {
    history.replace('/');
    window.location.reload();
  };

  const renderErrorMessages = () => {
    switch (response?.status) {
      case 403:
        return (
          <>
            <Typography variant="display2" margin={`0 0 ${spacing.gutter} 0`}>
              Error: 403 Forbidden
            </Typography>
            <FourZeroThree />
            <Typography margin={`${spacing.large} 0`}>
              {response.statusText}
            </Typography>
          </>
        );
      case 500:
        return (
          <>
            <Typography variant="display2" margin={`0 0 ${spacing.gutter} 0`}>
              {`Error: 500 ${response.statusText}`}
            </Typography>
            <FiveHundred />
            <Typography margin={`${spacing.large} 0`}>
              {/*@ts-ignore */}
              {(response?.data?.Errors && response?.data?.Errors[0]) ||
                'Something is not right.'}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="display2" margin={`0 0 ${spacing.gutter} 0`}>
              Error: 404 Page Not Found
            </Typography>
            <FourZeroFour />
            <Typography margin={`${spacing.large} 0`}>
              Seems like the page you’re looking for isn’t there.
            </Typography>
          </>
        );
    }
  };

  return (
    <>
      <NavBar />
      <ErrorWrapper>
        {renderErrorMessages()}
        <Button onClick={handleGoBack}>Go Back</Button>
      </ErrorWrapper>
      <Footer />
    </>
  );
};

export default ErrorComponent;
