import { nanoid } from 'nanoid';
import { fromUnixTime, format } from 'date-fns';
import { NavigationKeys } from 'constants/NavigationKeys';
import { DateTime } from 'luxon';

export const isObjectEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0;
};

// validate email
export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// validate phone number
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const re = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
  return re.test(String(phoneNumber));
};

// validate number or navigation accessability key
export const isValidNumberOrNavigationKey = (
  e: React.KeyboardEvent<HTMLDivElement>,
): boolean => {
  return /\d/.test(e.key) || NavigationKeys.includes(e.key);
};

export const buildObjectWithNanoId = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arrayToBeConverted: any[],
  initialStateForTheItem: unknown,
) => {
  if (arrayToBeConverted.length) {
    return arrayToBeConverted.reduce((acc, cv) => {
      return { ...acc, [nanoid(10)]: cv };
    }, {});
  }

  return { [nanoid(10)]: initialStateForTheItem };
};

//insert dashes in phone number, this also removes all non-numeric characters except dashes
export const insertDashesInPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.length > 10) {
    return phoneNumber;
  }
  return phoneNumber
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

// format time stamp from unix time
export const formatTimeStampFromUnixTime = (
  unixTimeStamp: number,
  formatOption: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) => {
  const formattedDate = fromUnixTime(unixTimeStamp);
  return format(formattedDate, formatOption, options);
};

// only allow alphanumeric characters
export const onlyAllowAlphaNumeric = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

//only allow numbers
export const onlyAllowNumbers = (value: string): string => {
  return value.replace(/\D/g, '');
};

// only allow numbers and commas and add commas to numbers
export const onlyAllowNumbersAndCommas = (value: string): string => {
  const onlyNumbers = onlyAllowNumbers(value);
  return onlyNumbers ? parseInt(onlyNumbers).toLocaleString() : '';
};

// only allow numbers and minus sign
export const onlyAllowNumbersAndMinus = (value: string) => {
  return value.replace(/[^0-9-]/g, '');
};

// convert first letter to uppercase
export const convertToTitleCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// only allow 5 char numeric zip code
export const onlyAllowZipCode = (value: string | null): string => {
  return value?.replace(/\D/g, '').slice(0, 5) || '';
};

// remove last forward slash from url
export const removeLastForwardSlash = (url: string): string => {
  return url.slice(0, url.lastIndexOf('/'));
};

// when utc time stamp is given, it will return the date stamp in the given format and timezone
export const getDateStampForGivenIanaTimeZoneAndFormat = (
  utcDate: string | null,
  formatOption: string,
  IanaTimeZone: string | null,
): string | null => {
  if (utcDate === null || IanaTimeZone === null) {
    return null;
  }

  return DateTime.fromISO(utcDate as string, { zone: 'utc' })
    .setZone(IanaTimeZone as string)
    .toFormat(formatOption);
};

export const filterNavigationKeys = (
  //@ts-ignore
  array: any,
  keyToBeFiltered: string,
  propertyName: string,
  flag: boolean,
) => {
  //@ts-ignore
  const filteredArray = array.filter((item: any) => {
    if (flag && item[propertyName] === keyToBeFiltered) {
      return true;
    }

    return item[propertyName] !== keyToBeFiltered;
  });

  return filteredArray;
};

export const formatDates = (
  earliestTime: number | undefined,
  latestTime: number | undefined,
  IANATimeZone: string | undefined,
  displayTime = true,
) => {
  const dateFormat = 'ccc, MMM dd, HH:mm ZZZZ';
  const startDate = earliestTime
    ? DateTime.fromSeconds(earliestTime, { zone: IANATimeZone })
    : null;
  const endDate = latestTime
    ? DateTime.fromSeconds(latestTime, { zone: IANATimeZone })
    : null;

  if (!startDate || !endDate || (earliestTime === latestTime && displayTime)) {
    const dateToShow = startDate || endDate;
    return dateToShow ? dateToShow.toFormat(dateFormat) : null;
  }
  const sameDay = startDate
    .startOf('day')
    .hasSame(endDate.startOf('day'), 'day');

  if (!displayTime) {
    const format = 'ccc, MMM dd, yyyy';
    return sameDay
      ? startDate.toFormat(format)
      : `${startDate.toFormat(format)} - ${endDate.toFormat(format)}`;
  }

  return `${startDate.toFormat('ccc, MMM dd, HH:mm')} - ${endDate.toFormat(
    sameDay ? 'HH:mm ZZZZ' : dateFormat,
  )}`;
};

export const formatNumberAmericanStandard = (
  number: number | null,
  showCents?: boolean,
) => {
  if (number === null) {
    return null;
  }
  return number.toLocaleString('en-US', {
    maximumFractionDigits: !!showCents ? 2 : 0,
    minimumFractionDigits: !!showCents ? 2 : 0,
  });
};

export const convertPhoneNumber = (phoneNumber: string | null) => {
  if (phoneNumber?.length) {
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    if (digitsOnly.length === 11) {
      const areaCode = digitsOnly.substring(1, 4);
      const rest = digitsOnly.substring(4);
      return `(${areaCode}) ${rest.substring(0, 3)}-${rest.substring(3)}`;
    }
  }

  return phoneNumber;
};
