import React, { useLayoutEffect, useReducer } from 'react';

// @Common Components
import { StyledTable } from 'commonComponents/Table/TableStyles';
import TableHeader from 'commonComponents/Table/TableHeader';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import ShipmentDetailsPanel from './ShipmentDetailsPanel/ShipmentDetailsPanel';
import ShipmentFilters from './ShipmentsTable/ShipmentFilters/ShipmentFilters';
import ShipmentsRowContentLoader from './ShipmentsTable/ShipmentsRowContentLoader';
import ShipmentTable from './ShipmentsTable/ShipmentTable';

// @Hooks
import { useQueryParams, useSetQueryParams } from 'hooks/useReactRouterQueries';
import {
  initialStateForShipmentsFilters,
  useShipmentFiltersReducer,
  useGetShipments,
} from './useGetShipments';
import { useGetShipmentFilterEnums } from 'hooks/useGetShipmentFilterEnums';

// @Styles
import { PageContainer, HeaderContainer } from 'styles/PageStyles';

//@Types
import { ShipmentFiltersActionKind } from './ShipmentPage.types';

const shipmentsHeaders = [
  'Reference',
  'Status',
  'Pickup',
  'Delivery',
  'Shipment Type',
];

const Shipments = () => {
  // fetch the enums
  const { data: shipmentFilterEnums } = useGetShipmentFilterEnums();
  // get and set query params
  const { sidePanel, ...queryParams } = useQueryParams();
  const setQueryParams = useSetQueryParams({ arrayFormat: 'repeat' });

  const shipmentFiltersReducer = useShipmentFiltersReducer(
    queryParams,
    setQueryParams,
  );

  const [state, dispatch] = useReducer(
    shipmentFiltersReducer,
    initialStateForShipmentsFilters,
  );

  // fetch shipments
  const {
    data: shipmentsData,
    isInitialLoading: isLoadingShipments,
    error: shipmentsError,
  } = useGetShipments();

  // handle clear filters
  const handleClearAllFilters = () => {
    dispatch({ type: ShipmentFiltersActionKind.CLEAR_ALL_FILTERS });
  };

  // if user came from existing url set the params as initial state
  useLayoutEffect(() => {
    if (shipmentFilterEnums) {
      dispatch({
        type: ShipmentFiltersActionKind.RESET_STATE,
        payload: queryParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentFilterEnums]);

  return (
    <>
      {sidePanel === 'shipment' && <ShipmentDetailsPanel />}
      <PageContainer>
        <HeaderContainer data-testid="shipments-header">
          <Typography variant="h1">Shipments</Typography>
        </HeaderContainer>
        <ShipmentFilters
          handleClearAllFilters={handleClearAllFilters}
          state={state}
          dispatch={dispatch}
          totalShipments={shipmentsData?.total || 0}
          queryParams={queryParams}
        />
        <StyledTable data-testid="shipments">
          <TableHeader dataTestId="shipments" headers={shipmentsHeaders} />
          {isLoadingShipments ? (
            <ShipmentsRowContentLoader />
          ) : (
            <ShipmentTable
              shipmentsData={shipmentsData}
              shipmentsError={shipmentsError}
              handleClearAllFilters={handleClearAllFilters}
              queryParams={queryParams}
            />
          )}
        </StyledTable>
      </PageContainer>
    </>
  );
};

export default Shipments;
