import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import {
  useQuery,
  UseQueryResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import qs from 'qs';

// @API
import { FacilityEndpoints, ShipyardEndpoints } from 'api/endpoints';

// @Arrive
import { ToastContext } from '@arrive/toasts';

// @Helpers
import {
  convertShipmentDetailsToShipmentRequest,
  convertShipmentResult,
} from './ShipmentFormHelpers';
import { buildObjectWithNanoId } from 'utils/utils';

// @Hooks
import useAxios from 'hooks/useAxios';

// @Types
import {
  ShipmentFormEnums,
  ShipyardEnumFormat,
  PickupDeliveryType,
  ShipmentDetails,
} from 'types/Shipment.types';
import { UserLoginInformation } from 'types/User.types';
import { GetAddressRequest } from 'types/Address.types';
import { FacilityAPIResponse, FacilityResult } from 'types/Facility.types';

export const initialReferenceNumberState = {
  type: null,
  number: '',
};

export const defaultPickupDeliveryState: PickupDeliveryType = {
  address1: '',
  address2: null,
  cityState: '',
  facilityId: null,
  facilityName: '',
  facilityClose: null,
  facilityOpen: null,
  notes: '',
  postalCode: '',
  referenceNumbers: buildObjectWithNanoId([], initialReferenceNumberState),
  schedulingContact: {
    fullName: null,
    phoneNumber: null,
    email: null,
  },
  schedulingType: null,
  stopType: 'Pickup',
  appointment: { hasAppointment: false },
  isValidatedLocation: false,
};

export const initialSelectedQuoteState = {
  allInRate: 0,
  cargoValue: null,
  commodityName: '',
  delivery: {
    ...defaultPickupDeliveryState,
    stopType: 'Delivery',
  },
  deliveryDate: getUnixTime(new Date()), // do not have a delivery date yet
  equipmentType: null,
  loadComments: '',
  maxTempF: '',
  minTempF: '',
  pickup: {
    ...defaultPickupDeliveryState,
    stopType: 'Pickup',
  },
  pickupDate: getUnixTime(new Date()),
  pieceCount: 0,
  pieceUnit: '',
  preCoolTempF: '',
  quoteRefNumber: '',
  shipmentType: 'FTL',
  externalShipmentId: '',
  weightLbs: 0,
} as ShipmentDetails;

export const useGetShipment = (
  shipmentId: string,
): UseQueryResult<ShipmentDetails> => {
  const axios = useAxios();
  return useQuery(
    ['shipmentInformation', shipmentId],
    async () => {
      const { data } = await axios.get<ShipmentDetails>(
        ShipyardEndpoints.shipment.getShipment(shipmentId),
      );
      return convertShipmentResult(data);
    },
    {
      useErrorBoundary: true,
    },
  );
};

export const useShipmentFormEnums = (): UseQueryResult<ShipmentFormEnums> => {
  const axios = useAxios();
  const fetchEnums = async () => {
    const { data } = await axios.get<ShipmentFormEnums>(
      ShipyardEndpoints.shipment.getShipmentFormEnums,
    );

    return {
      pieceUnits: (data.pieceUnits as ShipyardEnumFormat[]).sort((a, b) =>
        a.displayName > b.displayName ? 1 : -1,
      ),
      referenceNumberTypes: (
        data.referenceNumberTypes as ShipyardEnumFormat[]
      ).sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
      schedulingTypes: (data.schedulingTypes as ShipyardEnumFormat[]).sort(
        (a, b) => (a.displayName > b.displayName ? 1 : -1),
      ),
    };
  };
  return useQuery(['shipmentFormEnums'], () => fetchEnums(), {
    useErrorBoundary: true,
  });
};

export const useCreateShipment = () => {
  const axios = useAxios();
  const history = useHistory();
  const addToastNotification = useContext(ToastContext);

  return useMutation(
    async (shipmentDetails: ShipmentDetails) => {
      return axios.post(
        ShipyardEndpoints.shipment.createShipment,
        convertShipmentDetailsToShipmentRequest(shipmentDetails),
      );
    },
    {
      onSuccess: (resp) => {
        history.replace(`/shipment-confirmation/${resp.data.id}`);
      },
      onError: () => {
        addToastNotification({
          id: 'shipment-form-error',
          type: 'error',
          content: 'Something went wrong.',
          secondaryErrorText:
            'Try submitting again or reach out to us at shippernowCS@arrivelogistics.com',
        });
      },
    },
  );
};

export const useGetFacilities = ({
  city,
  state: stateCode,
  postalCode,
}: GetAddressRequest): UseQueryResult<FacilityResult[]> => {
  const addToastNotification = useContext(ToastContext);
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { customerId } = queryClient.getQueryData([
    'currentUser',
  ]) as UserLoginInformation;

  const fetchFacilities = async () => {
    const { data } = await axios.get<FacilityAPIResponse>(
      `${FacilityEndpoints.getFacilities}?${qs.stringify({
        customerId,
        city: city?.trim(),
        stateCode: stateCode?.trim(),
        postalCode,
      })}`,
    );

    return data.Result;
  };

  return useQuery(
    [
      'getFacilitiesByCustomerId',
      {
        customerId,
        city,
        stateCode,
        postalCode,
      },
    ],
    () => fetchFacilities(),
    {
      enabled: !!customerId,
      onError: () =>
        addToastNotification({
          id: 'address-suggestion-error',
          type: 'error',
          content: 'Something broken. Problem with fetching facilities.',
        }),
    },
  );
};
