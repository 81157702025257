import styled from '@emotion/styled';

// @arrive
import Pill from '@arrive/pill';

// @Theme
import theme from 'theme/Theme';

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ToggleButtonContainer = styled.button<{
  left?: boolean;
  isSelected: boolean;
}>`
  align-items: center;
  background: none;
  border-radius: ${({ left }) =>
    left ? '50px 0px 0px 50px' : '0px 50px 50px 0px'};
  border: ${({ isSelected }) =>
    !isSelected
      ? `1px solid ${theme.palette.common.chrome}`
      : `2px solid ${theme.palette.common.arrive}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  text-transform: capitalize;
  width: 240px;
  height: 50px;
  color: ${({ isSelected }) =>
    !isSelected ? theme.palette.common.smokey : theme.palette.common.axle};

  & > svg {
    fill: ${({ isSelected }) =>
      !isSelected ? theme.palette.common.smokey : theme.palette.common.axle};
    margin-right: ${theme.spacing.xsmall};
  }

  &:hover:enabled {
    transition: all 0.2s cubic-bezier(0, 0, 0.23, 1);
    color: ${theme.palette.common.axle};
    border: 2px solid ${theme.palette.common.arrive};
    background-position: 0%;
    margin: -1.5px 0;

    svg {
      fill: ${theme.palette.common.axle};
    }
  }

  &:disabled {
    color: ${theme.palette.common.chrome};
    cursor: not-allowed;
    border: ${({ isSelected }) =>
      isSelected && `1px solid ${theme.palette.common.chrome}`};

    svg {
      fill: ${theme.palette.common.chrome};
    }
  }
`;

export const PillButton = styled(Pill)`
  margin: 0px;
  &:hover,
  &:focus {
    padding-right: 14px;
    padding-left: 14px;
  }
`;
