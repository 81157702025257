import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

// @Arrive
import LOAD_ICON from '@arrive/ui-icons/svg/ic_load_detail.svg';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Components
import ShipmentDetailsStopInformation from './ShipmentDetailsStopInformation';
import { ShipmentDetailsTabContentLoader } from 'components/Shipments/ShipmentDetailsPanel/ShipmentDetailsContentLoaders';

// @Hooks
import { useQueryParams } from 'hooks/useReactRouterQueries';

// @Styles
import {
  SummaryContainer,
  SummaryContainerHeader,
  SummaryDetails,
  SummaryTitles,
  ShipmentDetailsTabContainer,
} from '../ShipmentDetailsPanelTabStyles';

// @Types
import {
  ShipmentDetailsByLoadNumber,
  ShipmentTabShipmentSummaryProps,
} from 'components/Shipments/ShipmentPage.types';

const ShipmentTabShipmentSummary = ({
  CargoValue,
  IsHazmat,
  IsTeam,
  Rate,
  Weight,
}: ShipmentTabShipmentSummaryProps) => {
  const getHazmatOrTeam = () => {
    if (IsHazmat && IsTeam) {
      return 'Hazmat, Team';
    }
    if (IsHazmat) {
      return 'Hazmat';
    }
    if (IsTeam) {
      return 'Team';
    }
    return 'None';
  };

  return (
    <SummaryContainer>
      <SummaryContainerHeader>
        <LOAD_ICON />
        <Typography variant="subtitle1">Shipment Details</Typography>
      </SummaryContainerHeader>
      <SummaryDetails paddingBottom>
        <div>
          <SummaryTitles variant="overline" as="div">
            Total Rate
          </SummaryTitles>
          <Typography
            variant="body1"
            as="div"
            data-testid="shipment-details-rate"
          >
            ${Rate}
          </Typography>
        </div>
        <div>
          <SummaryTitles variant="overline" as="div">
            Cargo Value
          </SummaryTitles>
          <Typography
            variant="body1"
            as="div"
            data-testid="shipment-details-cargo-value"
          >
            ${CargoValue}
          </Typography>
        </div>
      </SummaryDetails>
      <SummaryDetails>
        <div>
          <SummaryTitles variant="overline" as="div">
            Total Weight
          </SummaryTitles>
          <Typography
            variant="body1"
            as="div"
            data-testid="shipment-details-total-weight"
          >
            {Weight} lbs
          </Typography>
        </div>
        <div>
          <SummaryTitles variant="overline" as="div">
            Requirements
          </SummaryTitles>
          <Typography
            variant="body1"
            as="div"
            data-testid="shipment-details-requirements"
          >
            {getHazmatOrTeam()}
          </Typography>
        </div>
      </SummaryDetails>
    </SummaryContainer>
  );
};

const ShipmentDetailsPanelShipmentTab = ({
  isLoadingShipmentDetails,
}: {
  isLoadingShipmentDetails: boolean;
}) => {
  const queryClient = useQueryClient();
  const { loadNumber } = useQueryParams();
  const shipment = queryClient.getQueryData([
    'shipmentDetailsByLoadId',
    loadNumber,
  ]) as ShipmentDetailsByLoadNumber;

  if (isLoadingShipmentDetails) {
    return <ShipmentDetailsTabContentLoader />;
  }
  return (
    <ShipmentDetailsTabContainer>
      <ShipmentTabShipmentSummary
        CargoValue={shipment?.CargoValue}
        IsHazmat={shipment?.IsHazmat}
        IsTeam={shipment?.IsTeam}
        Rate={shipment?.Rate}
        TotalMiles={shipment?.TotalMiles}
        Weight={shipment?.Weight}
      />
      <ShipmentDetailsStopInformation stopInformation={shipment.Stops} />
    </ShipmentDetailsTabContainer>
  );
};

export default ShipmentDetailsPanelShipmentTab;
