import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

// @Api
import { MapEndpoints } from 'api/endpoints';

// @Types
import { Route } from '../QuoteResults.types';

export type RouteRequest = {
  pickupLat: number;
  pickupLng: number;
  deliveryLat: number;
  deliveryLng: number;
};

export const useGetRoute = () => {
  return useMutation(
    async ({
      pickupLat,
      pickupLng,
      deliveryLat,
      deliveryLng,
    }: RouteRequest) => {
      const routeCoordinates = {
        Route: {
          Stops: [
            {
              Coords: {
                Lat: pickupLat,
                Lon: pickupLng,
              },
            },
            {
              Coords: {
                Lat: deliveryLat,
                Lon: deliveryLng,
              },
            },
          ],
        },
      };

      const { data } = await axios.post<Route>(
        MapEndpoints.getRoutes,
        routeCoordinates,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${process.env.ALK_MAPS_API_KEY}`,
          },
        },
      );
      return data;
    },
  );
};
