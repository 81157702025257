import React, { ReactElement, Fragment } from 'react';

// @Arrive
import DELIVERY_ICON from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';
import PICKUP_ICON from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Helpers
import { getSortedStopInformationArray } from 'components/Shipments/ShipmentsHelpers';

// @Styles
import {
  PickupDeliveryInformation,
  ShipmentDetailsTabPickupDeliveryContainer,
  StopDetails,
  SummaryContainer,
  SummaryDetails,
  SummaryTitles,
} from '../ShipmentDetailsPanelTabStyles';

// @Types
import {
  ShipmentDetailsPickupDeliveryInfo,
  ShipmentDetailsStopInformationProps,
} from 'components/Shipments/ShipmentPage.types';
import { Commodity } from 'common/Types/Shipment.types';

// @Utils
import { formatDates } from 'utils/utils';

const IconType = {
  Delivery: <DELIVERY_ICON />,
  Pickup: <PICKUP_ICON />,
};

const CommodityInformation = ({
  commodities,
}: {
  commodities: Commodity[];
}): ReactElement => {
  return (
    <>
      {!!commodities.length &&
        commodities.map((commodity) => {
          return (
            <Fragment key={commodity.Description + commodity.Weight}>
              <SummaryDetails paddingBottom>
                <div>
                  <SummaryTitles variant="overline" as="div">
                    Commodity
                  </SummaryTitles>
                  <Typography
                    variant="body1"
                    as="div"
                    margin="2px 0"
                    data-testid="shipment-details-commodity-description"
                  >
                    {commodity.Description}
                  </Typography>
                </div>
                <div>
                  <SummaryTitles variant="overline" as="div">
                    Pieces/Packaging
                  </SummaryTitles>
                  <Typography
                    variant="body1"
                    as="div"
                    margin="2px 0"
                    data-testid="shipment-details-piece-description"
                  >
                    {commodity.PieceCount} {commodity.PieceTypeDescription}
                  </Typography>
                </div>
              </SummaryDetails>
              <SummaryDetails paddingBottom>
                <div>
                  <SummaryTitles variant="overline" as="div">
                    Weight
                  </SummaryTitles>
                  <Typography
                    variant="body1"
                    as="div"
                    margin="2px 0"
                    data-testid="shipment-details-weight"
                  >
                    {commodity.Weight} lbs
                  </Typography>
                </div>
              </SummaryDetails>
            </Fragment>
          );
        })}
    </>
  );
};

const ShipmentDetailsStopInformation = ({
  stopInformation,
}: ShipmentDetailsStopInformationProps) => {
  const StopInformationArray = getSortedStopInformationArray(stopInformation);
  return (
    <>
      {StopInformationArray.map((stop: ShipmentDetailsPickupDeliveryInfo) => {
        return (
          <SummaryContainer key={`${stop.EarliestTime}-${stop.CityState}`}>
            <ShipmentDetailsTabPickupDeliveryContainer>
              <PickupDeliveryInformation>
                <div>
                  {IconType[stop.StopType]}{' '}
                  <Typography
                    variant="subtitle1"
                    margin="0"
                    data-testid={`shipment-details-${stop.StopType}-${stop.CityState}-${stop.PostalCode}`}
                  >
                    {stop.StopType} in {stop.CityState} {stop.PostalCode}
                  </Typography>
                </div>
                <Typography
                  variant="body2"
                  as="div"
                  margin="2px"
                  data-testid={`shipment-details-${stop.StopType}-time`}
                >
                  {formatDates(
                    stop.EarliestTime,
                    stop.LatestTime,
                    stop.TimeZoneIANA,
                  )}
                </Typography>
              </PickupDeliveryInformation>
              <StopDetails>
                <Typography
                  variant="body2"
                  as="div"
                  margin="2px 0px"
                  data-testid={`shipment-details-${stop.StopType}-information`}
                >
                  Stop {stop.SequenceNumber} of {StopInformationArray.length}
                </Typography>
                <Typography
                  variant="body2"
                  as="div"
                  margin="0"
                  data-testid={`shipment-details-${stop.StopType}-scheduling-type`}
                >
                  {stop.SchedulingType}
                </Typography>
              </StopDetails>
            </ShipmentDetailsTabPickupDeliveryContainer>
            <SummaryDetails paddingBottom>
              <div>
                <SummaryTitles variant="overline" as="div">
                  Address
                </SummaryTitles>
                <Typography
                  variant="body1"
                  as="div"
                  margin="2px 0"
                  data-testid={`shipment-details-${stop.StopType}-address1`}
                >
                  {stop.Address1}
                </Typography>

                {!!stop.Address2?.length ? (
                  <Typography
                    variant="body1"
                    as="div"
                    margin="0"
                    data-testid={`shipment-details-${stop.StopType}-address2`}
                  >
                    {stop.Address2}
                  </Typography>
                ) : null}
                <Typography
                  variant="body1"
                  as="div"
                  margin="2px 0"
                  data-testid={`shipment-details-${stop.StopType}-city-state`}
                >
                  {stop.CityState} {stop.PostalCode}
                </Typography>
              </div>
              {!!stop.ReferenceNumbers.length && (
                <div>
                  <SummaryTitles variant="overline" as="div">
                    Reference #s
                  </SummaryTitles>
                  {stop.ReferenceNumbers.map((referenceNumber, idx) => {
                    return (
                      <Typography
                        key={referenceNumber.ReferenceNumber}
                        variant="body1"
                        as="div"
                        margin="2px 0"
                        data-testid={`shipment-details-${stop.StopType}-reference-numbers`}
                      >
                        {`${referenceNumber.Description} ${
                          referenceNumber.ReferenceNumber
                        }${
                          idx === stop.ReferenceNumbers.length - 1 ? '' : ','
                        }`}
                      </Typography>
                    );
                  })}
                </div>
              )}
            </SummaryDetails>
            <CommodityInformation commodities={stop.Commodities} />
          </SummaryContainer>
        );
      })}
    </>
  );
};

export default ShipmentDetailsStopInformation;
