import React from 'react';
import { nanoid } from 'nanoid';
import { useFlags } from 'launchdarkly-react-client-sdk';

// @Common Components
import TableContentLoader from 'commonComponents/Table/TableContentLoader';

// @Styles
import {
  TableRowLoading,
  TableDataCell,
} from 'commonComponents/Table/TableStyles';
import {
  BalanceDueDataCell,
  LoadStopsDataCell,
  AmountLoadingContainer,
  InvoiceTableDataCell,
  AmountDataCell,
} from './InvoiceTableStyles';

const InvoiceLoadingRow = ({ index }: { index: number }) => {
  const { featureCurrencySubsidiary } = useFlags();

  return (
    <TableRowLoading data-testid={`invoice-row-loading-${index}`}>
      <TableDataCell>
        <TableContentLoader width={110} />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader width={80} containerWidth={80} />
        <TableContentLoader width={140} containerWidth={140} />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader width={32} />
      </TableDataCell>
      <LoadStopsDataCell>
        <TableContentLoader width={80} />
        <TableContentLoader width={120} />
      </LoadStopsDataCell>
      <LoadStopsDataCell>
        <TableContentLoader width={80} />
        <TableContentLoader width={120} />
      </LoadStopsDataCell>
      <InvoiceTableDataCell>
        <TableContentLoader width={50} containerWidth={50} alignRight />
      </InvoiceTableDataCell>
      <InvoiceTableDataCell>
        <TableContentLoader width={55} containerWidth={55} />
        <TableContentLoader width={35} containerWidth={35} />
      </InvoiceTableDataCell>
      <AmountDataCell>
        <AmountLoadingContainer>
          <TableContentLoader width={70} containerWidth={70} alignRight />
        </AmountLoadingContainer>
      </AmountDataCell>
      <BalanceDueDataCell>
        <TableContentLoader width={70} containerWidth={70} alignRight />
      </BalanceDueDataCell>
      {featureCurrencySubsidiary && (
        <TableDataCell data-testid={`currency-content-loader-${index}`}>
          <TableContentLoader width={40} />
        </TableDataCell>
      )}
    </TableRowLoading>
  );
};

const InvoiceRowContentLoading = () => {
  return (
    <>
      {Array.from({ length: 8 }).map((_, index) => (
        <InvoiceLoadingRow
          key={`invoice-loading-row-${nanoid(10)}`}
          index={index}
        />
      ))}
    </>
  );
};

export default InvoiceRowContentLoading;
