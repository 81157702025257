import { DateTime } from 'luxon';

// @Constants
import {
  MILITARY_EXPIRATION_HOUR,
  MAX_DAYS_FROM_PICKUP,
} from 'constants/QuoteTime';

// @Theme
import { palette } from 'theme/Theme';

// @Types
import { QuoteStatus } from 'types/quoteTypes';

export enum ExpirationTypes {
  ACTIVE = 'active',
  EXPIRING = 'expiring',
  EXPIRED = 'expired',
  ACCEPTED = 'accepted',
  DEFAULT = 'default', //status not yet determined
}

export const ExpirationOptions = {
  active: {
    color: palette.common.frogger,
    darkColor: palette.common.gatorguts,
    buttonMessage: 'Accept',
    executeFunction: 'accept',
  },
  expiring: {
    color: palette.common.accessibleYellow,
    darkColor: palette.common.diesel,
    buttonMessage: 'Accept',
    executeFunction: 'accept',
  },
  expired: {
    color: palette.common.LFGray,
    darkColor: palette.common.LFGray,
    buttonMessage: 'Quote Again',
    executeFunction: 'requote',
  },
  accepted: {
    color: palette.common.arrive,
    darkColor: palette.common.blueBayou,
    buttonMessage: 'Quote Again',
    executeFunction: 'requote',
  },
} as {
  [key: string]: {
    color: string;
    darkColor: string;
    buttonMessage: string;
    executeFunction: 'accept' | 'requote';
  };
};

export const getMinimumDate = () => {
  const cstDate = DateTime.now().setZone('America/Chicago');
  const minimumDateCST = cstDate.plus({
    days: cstDate.hour < MILITARY_EXPIRATION_HOUR ? 1 : 2,
  });

  return minimumDateCST
    .set({
      hour: MILITARY_EXPIRATION_HOUR,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toJSDate();
};

export const getMaximumPickupDate = () => {
  const cstDate = DateTime.now().setZone('America/Chicago');
  const maximumDateCST = cstDate.plus({ days: MAX_DAYS_FROM_PICKUP });

  return maximumDateCST.toJSDate();
};

export const getExpirationStatus = (
  minutes: number | undefined | null,
  accepted: boolean,
): ExpirationTypes => {
  const isMinutesNumber = typeof minutes === 'number';

  if (accepted) {
    return ExpirationTypes.ACCEPTED;
  }
  if (isMinutesNumber && minutes <= 0) {
    return ExpirationTypes.EXPIRED;
  }
  if (isMinutesNumber && minutes <= 60 * MILITARY_EXPIRATION_HOUR) {
    return ExpirationTypes.EXPIRING;
  }
  if (
    isMinutesNumber &&
    minutes > 0 &&
    minutes > 60 * MILITARY_EXPIRATION_HOUR
  ) {
    return ExpirationTypes.ACTIVE;
  }
  return ExpirationTypes.DEFAULT;
};

export const convertMinutesToTimeLeft = (minutes: number) => {
  const updatedHours = Math.floor(minutes / 60);
  const updatedMinutes = minutes % 60;

  return {
    hours: updatedHours <= 0 ? 0 : updatedHours,
    minutes: updatedMinutes <= 0 ? 0 : updatedMinutes,
    expired: updatedHours <= 0 && updatedMinutes <= 0,
  };
};

export const updateQuoteStatusTimeAndExpiration = (
  quoteStatus: QuoteStatus,
) => {
  const minutesUntilQuoteExpires =
    quoteStatus.timeLeftUntilQuoteExpires.hours * 60 +
    quoteStatus.timeLeftUntilQuoteExpires.minutes;

  const updatedMinutes = Math.max(minutesUntilQuoteExpires - 1, 0);

  return {
    ...quoteStatus,
    timeLeftUntilQuoteExpires: convertMinutesToTimeLeft(updatedMinutes),
    expirationStatus: getExpirationStatus(updatedMinutes, quoteStatus.accepted),
  };
};
