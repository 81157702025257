import React, { useRef, useState } from 'react';
import { components } from 'react-select';

// @arrive
import Checkbox from '@arrive/checkbox';
import Pill from '@arrive/pill';

// @Components
import SearchableDropdown from 'commonComponents/Dropdowns/SearchableDropdown';
import Typography from 'commonComponents/Typography/Typography';
import ApplyButtonBar from 'commonComponents/Buttons/ApplyButtonBar';

// @Hooks
import useEventListener from 'hooks/useEventListener';

// @Styles
import { palette } from 'theme/Theme';
import { spacing } from 'theme/Units';

// @Types
import {
  ControlOverrideProps,
  MenuListOverrideProps,
  OptionOverrideProps,
  SearchableDropdownSelectProps,
} from './DropdownTypes.types';
import { ApplyButtonBarProps } from 'commonComponents/Buttons/ButtonTypes.types';

const { Control, Option, MenuList } = components;

const MenuListOverride = ({
  children,
  handleApply,
  handleClear,
  ...props
}: MenuListOverrideProps & ApplyButtonBarProps) => {
  return (
    <div>
      <MenuList {...props}>
        {React.Children.map(children, (child) => child)}
      </MenuList>
      <ApplyButtonBar handleApply={handleApply} handleClear={handleClear} />
    </div>
  );
};

const FilterControlOverride = ({
  children,
  onClick,
  selectedCount,
  ...props
}: ControlOverrideProps & {
  onClick: () => void;
  selectedCount: number | undefined;
}) => {
  return (
    <Control {...props}>
      <Pill
        title={props.selectProps.placeholder || ''}
        selectedCount={selectedCount}
        onClick={onClick}
      />
      {children}
    </Control>
  );
};

const OptionOverride = (props: OptionOverrideProps) => {
  return (
    <Option {...props}>
      <div>
        <Checkbox
          checked={props.isSelected}
          onChange={/* istanbul ignore next */ () => null}
        />
        <Typography variant="body1" margin="0">
          {props.label}
        </Typography>
      </div>
    </Option>
  );
};

const FilterDropdown = ({
  handleApply,
  handleClear,
  handleReset,
  selectedCount,
  ...rest
}: SearchableDropdownSelectProps &
  ApplyButtonBarProps & {
    handleReset: () => void;
    selectedCount?: number;
  }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEventListener('mousedown', (event) => {
    if (
      menuOpen &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      handleReset();
      return setMenuOpen(false);
    }
  });

  const executeApplyFunction = () => {
    setMenuOpen(false);
    handleApply && handleApply();
  };

  const executeClearFunction = () => {
    setMenuOpen(false);
    handleClear && handleClear();
  };

  const handleClickPill = () => {
    if (menuOpen) {
      setMenuOpen(false);
      handleReset();
    } else {
      setMenuOpen(true);
    }
  };

  const componentsOverride = {
    // @ts-ignore
    Option: (props) => <OptionOverride {...props} />,
    // @ts-ignore
    MenuList: (props) => (
      <MenuListOverride
        handleApply={executeApplyFunction}
        handleClear={executeClearFunction}
        {...props}
      />
    ),
    // @ts-ignore
    Control: (props) => (
      <FilterControlOverride
        {...props}
        isFocused={menuOpen}
        onClick={handleClickPill}
        selectedCount={selectedCount}
      />
    ),
    DropdownIndicator: /* istanbul ignore next */ () => null,
    IndicatorsContainer: /* istanbul ignore next */ () => null,
  };

  return (
    <div ref={menuRef}>
      <SearchableDropdown
        isSearchable={false}
        components={componentsOverride}
        isMulti
        menuIsOpen={menuOpen}
        controlShouldRenderValue={false}
        menuPosition="absolute"
        styles={{
          option: (styles) => ({
            ...styles,
            backgroundColor: `${palette.common.ethanol} !important`,
            width: 'fit-content',
            '> div': {
              display: 'flex',
              cursor: 'pointer',
            },
            '.arrive_Checkbox__checkboxContainer': {
              marginLeft: spacing.normal,
              marginRight: spacing.xxsmall,
              top: '2px',
            },
          }),
          control: (styles, { isFocused }) => ({
            ...styles,
            border: 'none',
            boxShadow: '0',
            minHeight: '30px',
            '.arrive_Pill__pill': {
              margin: '0 1px 0 1px',
              border: `solid ${
                isFocused ? `2px ${palette.common.arrive}` : '1px auto'
              }`,
              padding: isFocused ? '0 14px 0 14px' : 'auto',
              ':hover': {
                padding: '0 14px 0 14px',
              },
            },
          }),
          menu: (styles) => ({
            ...styles,
            width: '400px',
            bottom: 0,
            marginTop: spacing.small,
            '> div': {
              boxShadow: '0 4px 4px hsl(0deg 0% 0% / 25%)',
              backgroundColor: palette.common.ethanol,
              paddingBottom: spacing.small,
              '> div:first-of-type': {
                paddingTop: spacing.small,
                paddingBottom: spacing.small,
                maxHeight: 'unset',
              },
            },
          }),
          valueContainer: (styles) => ({
            ...styles,
            display: 'none',
          }),
        }}
        {...rest}
      />
    </div>
  );
};

export default FilterDropdown;
