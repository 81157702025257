import styled from '@emotion/styled';
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

export const ShipmentDetailsPanelTabContainer = styled.div`
  margin-top: ${spacing.gutter};
`;

export const ShipmentDetailsPanelTabContentContainer = styled.div`
  padding: ${spacing.gutter};
  max-height: calc(100vh - 547px);
  overflow-y: auto;
`;

export const LocationDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  > span {
    margin: 0 ${spacing.xsmall};
  }

  svg {
    fill: ${palette.common.LFGray};
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    margin: 0 0 0 ${spacing.large};
  }
`;

export const TimeLineContainer = styled.div`
  padding: 40px ${spacing.large};

  ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 0 52px 0;

    > li:last-of-type {
      padding-bottom: 12px;
      span {
        border: none;
      }
    }
  }
`;

export const ActivityListItem = styled.li`
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  padding-bottom: 40px;

  > div {
    position: relative;
    margin-left: ${spacing.xsmall};
    top: -10px;

    > div {
      margin: 0 0 2px 0;
    }
  }
`;

export const ActivityDot = styled.span`
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  height: 100%;
  border: 2px solid ${palette.common.arrive};
  &:before {
    content: '';
    position: absolute;
    left: -9px;
    width: 18px;
    height: 18px;
    background-color: ${palette.common.arrive};
    border: 2px solid ${palette.common.ethanol};
    border-radius: 100%;
    box-shadow: 0 0 0 2px ${palette.common.arrive};
  }

  &:before {
    top: -10px;
  }

  &:after {
    top: 100%;
  }
`;

export const ShipmentDetailsDefaultTrackingContent = styled.div`
  padding: 56px 24px 24px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ShipmentDetailsTabContainer = styled.div`
  max-height: calc(100vh - 600px);
  overflow-y: auto;
  padding-bottom: ${spacing.large};
`;

export const SummaryContainer = styled.div`
  border-bottom: 1px solid ${palette.common.chrome};
  padding: ${spacing.gutter};
`;
export const SummaryContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${spacing.gutter};

  svg {
    fill: ${palette.common.LFGray};
  }

  span:first-of-type {
    margin-left: ${spacing.xsmall};
  }
`;

export const SummaryDetails = styled.div<{ paddingBottom?: boolean }>`
  padding: 0 ${spacing.large};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? spacing.normal : 0};
  display: flex;
  align-items: baseline;

  > div {
    flex: 1 1 220px;
    max-width: 220px;
    overflow-wrap: break-word;
    margin-right: ${spacing.xsmall};
  }
`;

export const SummaryTitles = styled(Typography)`
  text-transform: uppercase;
  color: ${palette.common.smokey};
`;

export const ShipmentDetailsTabPickupDeliveryContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${spacing.gutter};

  svg {
    fill: ${palette.common.LFGray};
  }

  span:first-of-type {
    margin-left: ${spacing.xsmall};
  }
`;

export const PickupDeliveryInformation = styled.div`
  flex: 1;

  > div {
    display: flex;
    align-items: center;
  }

  > div:nth-of-type(2) {
    margin-left: ${spacing.large};
  }
`;
export const StopDetails = styled.div`
  text-align: right;
  > div {
    color: ${palette.common.smokey};
  }
`;

export const TrackingDetailsScrollToTop = styled.div`
  text-align: end;
  display: block;

  svg {
    transform: rotateX(180deg);
    fill: ${palette.common.white};
    transition: padding-top 0.3s ease-out;
  }

  button {
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: #2aaae2d9;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.12), 3px 4px 8px 0px rgba(0, 0, 0, 0.11);
  }

  button:hover {
    background-color: ${palette.common.arrive};
  }

  svg:hover {
    padding-top: ${spacing.xxsmall};
  }
`;
