import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

const useCopyToClipboard: () => [CopiedValue, CopyFn] = () => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    /*can't unit test multi browsers, who doesn't have copy to clipboard */
    /* istanbul ignore next  */
    if (!navigator?.clipboard) {
      //TODO: In future add toast notification instead of console.warn
      console.warn('Clipboard not supported');
      return false;
    }
    /* istanbul ignore end */

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      /* istanbul ignore next can't test browsers who doesn't have copy to clipboard */
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
      /* istanbul ignore end */
    }
  };

  return [copiedText, copy];
};

export default useCopyToClipboard;

/** Usage Information
 * import React from 'react'

import  useCopyToClipboard  from 'hooks/useCopyToClipboard'

export default function Component() {
  const [value, copy] = useCopyToClipboard()
  return (
    <>
      <h1>Click to copy:</h1>
      <div style={{ display: 'flex' }}>
        <button onClick={() => copy('A')}>A</button>
        <button onClick={() => copy('B')}>B</button>
        <button onClick={() => copy('C')}>C</button>
      </div>
      <p>Copied value: {value ?? 'Nothing is copied yet!'}</p>
    </>
  )
}

 */
