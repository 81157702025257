import React from 'react';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Theme
import { palette } from 'theme/Theme';

// @Types
import { TableStopsProps } from './Table.types';

// @Utils
import { formatDates } from 'utils/utils';

const TableStops = ({ stops, type, displayTime = true }: TableStopsProps) => {
  const mainStop = stops[type === 'pickup' ? 0 : stops.length - 1];
  const additionalStops = stops.length > 1 ? ' +' + (stops.length - 1) : '';
  const dates = formatDates(
    mainStop.EarliestTime,
    mainStop.LatestTime,
    mainStop.TimeZoneIANA,
    displayTime,
  );

  return (
    <>
      <Typography variant="body1" as="p">
        {`${mainStop.CityState}${additionalStops}`}
      </Typography>
      {dates && (
        <Typography variant="body2" as="p" color={palette.common.smokey}>
          {dates}
        </Typography>
      )}
    </>
  );
};

export default TableStops;
