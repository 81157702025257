import { getUnixTime } from 'date-fns';
import { DateTime } from 'luxon';

// @InitialState
import { initialSelectedQuoteState } from 'components/ShipmentForm/useShipmentForm';

// @Types
import { QuoteRate } from 'types/quoteTypes.d';
import {
  ShipmentRequest,
  ShipmentDetails,
  PickupDeliveryType,
  ReferenceNumberWithNanoId,
  AppointmentsType,
} from 'types/Shipment.types';
import { ShipmentFormStatus } from './ShipmentForm.types';

export const updateFormStatus = (
  state: {
    [key: string]: ShipmentFormStatus;
  },
  action: {
    type: string;
    payload: { section: 'pickup' | 'delivery' | 'shipment' };
  },
) => {
  const { section } = action.payload;

  switch (action.type) {
    // If a section is completed, set it to read only and open the next section
    case 'COMPLETE':
      const completedSection = {
        readOnly: true,
        completed: true,
        hidden: false,
      };
      const nextSection = { readOnly: false, completed: false, hidden: false };
      if (section === 'pickup' && !state['delivery'].completed) {
        return { ...state, [section]: completedSection, delivery: nextSection };
      } else {
        return {
          pickup: completedSection,
          delivery: completedSection,
          shipment: nextSection,
        };
      }
    // If the user clicks on the edit button of a section, make that section editable and hide all other sections
    case 'EDIT':
      const editableSection = {
        readOnly: false,
        completed: true,
        hidden: false,
      };
      return {
        ...state,
        [section]: editableSection,
        [section === 'pickup' ? 'delivery' : 'pickup']: {
          ...state.delivery,
          hidden: true,
        },
        shipment: { ...state.shipment, hidden: true },
      };
    default:
      return state;
  }
};

export const getDefaultAppointment = (
  pickupDeliveryType: 'pickup' | 'delivery',
  pickupDate?: number,
) => {
  return {
    hasAppointment: false,
    appointmentDate: pickupDeliveryType === 'pickup' ? pickupDate : undefined,
  };
};

export const convertQuoteDetailsToShipmentDetails = (
  quoteDetails: QuoteRate,
): ShipmentDetails => {
  const {
    deliveryLocation,
    deliveryPostalCode,
    equipmentType,
    mode,
    pickupDate,
    pickupLocation,
    pickupPostalCode,
    quoteReference,
    rate,
    totalMiles,
    quoteId,
  } = quoteDetails;

  return {
    ...initialSelectedQuoteState,
    delivery: {
      ...initialSelectedQuoteState.delivery,
      appointment: getDefaultAppointment('delivery'),
      cityState: deliveryLocation,
      facilityClose: null,
      facilityOpen: null,
      postalCode: deliveryPostalCode,
    },
    deliveryDate: getUnixTime(new Date()),
    equipmentType: equipmentType,
    pickup: {
      ...initialSelectedQuoteState.pickup,
      appointment: getDefaultAppointment(
        'pickup',
        getUnixTime(new Date(pickupDate)),
      ),
      cityState: pickupLocation,
      facilityClose: null,
      facilityOpen: null,
      postalCode: pickupPostalCode,
    },
    pickupDate: getUnixTime(new Date(pickupDate)),
    shipmentType: mode,
    quoteRefNumber: quoteReference,
    allInRate: rate,
    totalMiles: totalMiles,
    quoteId: quoteId,
  };
};

export const validateReferenceNumberFields = (
  referenceNumbers: ReferenceNumberWithNanoId,
): boolean => {
  const referenceNumberValues = Object.values(referenceNumbers);

  return referenceNumberValues.every(
    (refNumber) => refNumber.type && !!refNumber.number.length,
  );
};

const validateAppointment = (appointment?: AppointmentsType | null) => {
  if (!appointment) {
    return false;
  }
  const {
    hasAppointment,
    earliestTime,
    latestTime,
    appointmentTime,
    appointmentDate,
  } = appointment;
  if (!hasAppointment) {
    return earliestTime && latestTime ? earliestTime <= latestTime : true;
  } else {
    return appointmentTime && appointmentDate;
  }
};

export const validatePickupDeliveryForm = (
  PickupDeliveryDetails: PickupDeliveryType,
): boolean => {
  const isValidAppointment =
    PickupDeliveryDetails.schedulingType !== 'APPOINTMENT' ||
    validateAppointment(PickupDeliveryDetails.appointment);

  const {
    address1,
    facilityName,
    schedulingType,
    postalCode,
    cityState,
    referenceNumbers,
  } = PickupDeliveryDetails;
  return (
    !!(
      address1?.length &&
      cityState.length &&
      facilityName.length &&
      postalCode?.length &&
      schedulingType &&
      isValidAppointment
    ) && validateReferenceNumberFields(referenceNumbers)
  );
};

export const convertShipmentDetailsToShipmentRequest = (
  shipmentDetails: ShipmentDetails,
): ShipmentRequest => {
  const {
    cargoValue: originalCargoValue,
    delivery: originalDeliveryState,
    pickup: originalPickupState,
    preCoolTempF: originalPreCoolTempF,
    minTempF: originalMinTempF,
    maxTempF: originalMaxTempF,
    equipmentType: originalEquipmentType,
  } = shipmentDetails;

  // remove nanoid from reference numbers, convert type to string and convert empty phone numbers to null
  const deliveryContact = originalDeliveryState.schedulingContact;
  const pickupContact = originalPickupState.schedulingContact;
  const delivery = {
    ...originalDeliveryState,
    stopType: 'DELIVERY' as 'DELIVERY' | 'PICKUP',
    referenceNumbers: Object.values(
      originalDeliveryState!.referenceNumbers,
    ).map((refNum) => {
      return { ...refNum, type: refNum.type?.enumValue || '' };
    }),
    schedulingContact: {
      ...deliveryContact,
      phoneNumber: !!deliveryContact?.phoneNumber?.length
        ? deliveryContact?.phoneNumber
        : null,
    },
    appointment:
      originalDeliveryState.schedulingType === 'APPOINTMENT'
        ? originalDeliveryState.appointment
        : null,
  };
  const pickup = {
    ...originalPickupState,
    stopType: 'PICKUP' as 'DELIVERY' | 'PICKUP',
    referenceNumbers: Object.values(originalPickupState!.referenceNumbers).map(
      (refNum) => {
        return { ...refNum, type: refNum.type?.enumValue || '' };
      },
    ),
    schedulingContact: {
      ...pickupContact,
      phoneNumber: !!pickupContact?.phoneNumber?.length
        ? pickupContact?.phoneNumber
        : null,
    },
    appointment:
      originalPickupState.schedulingType === 'APPOINTMENT'
        ? originalPickupState.appointment
        : null,
  };

  // strip commas from cargo value
  const cargoValue = originalCargoValue
    ? originalCargoValue.toString().replace(/,/g, '')
    : 0;

  // create new request object
  const request = {
    ...shipmentDetails,
    delivery,
    pickup,
    cargoValue: parseInt(cargoValue as string, 10) || 0,
    preCoolTempF:
      originalEquipmentType === 'R'
        ? parseInt(originalPreCoolTempF, 10) || 0
        : null,
    minTempF:
      originalEquipmentType === 'R'
        ? parseInt(originalMinTempF, 10) || 0
        : null,
    maxTempF:
      originalEquipmentType === 'R'
        ? parseInt(originalMaxTempF as string, 10) || 0
        : null,
    loadComments: !!shipmentDetails.loadComments?.length
      ? shipmentDetails.loadComments
      : null,
  };
  return request;
};

export const validateLoadForm = (loadDetails: ShipmentDetails): boolean => {
  const {
    cargoValue,
    commodityName,
    equipmentType,
    externalShipmentId,
    pieceCount,
    pieceUnit,
    shipmentType,
    weightLbs,
    preCoolTempF,
    minTempF,
    maxTempF,
  } = loadDetails;

  const refrigeratedTempValidation =
    (equipmentType === 'R' &&
      !!preCoolTempF.length &&
      !!minTempF.length &&
      !!maxTempF?.length &&
      !isNaN(parseInt(preCoolTempF, 10)) &&
      !isNaN(parseInt(minTempF, 10)) &&
      !isNaN(parseInt(maxTempF, 10)) &&
      !getTemperatureError(preCoolTempF, minTempF, maxTempF).length) ||
    equipmentType !== 'R';

  const cargoValueInt = !!cargoValue?.length
    ? parseInt(cargoValue?.replace(/,/g, ''), 10)
    : 0;

  return (
    !!(
      cargoValue?.length &&
      commodityName?.length &&
      equipmentType &&
      externalShipmentId?.length &&
      pieceCount &&
      pieceCount <= 1000 &&
      pieceUnit &&
      shipmentType &&
      weightLbs &&
      weightLbs <= 47000 &&
      refrigeratedTempValidation
    ) &&
    cargoValueInt <= 100000 &&
    cargoValueInt > 0
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertShipmentResult = (shipmentResult: any): ShipmentDetails => {
  const { pickup: originalPickup, delivery: originalDelivery } = shipmentResult;
  const pickup = {
    ...originalPickup,
    schedulingType: originalPickup.schedulingType.enumValue,
  };
  const delivery = {
    ...originalDelivery,
    schedulingType: originalDelivery.schedulingType.enumValue,
  };

  const newValue = {
    ...shipmentResult,
    pieceUnit: shipmentResult.pieceUnit.enumValue,
    shipmentType: shipmentResult.shipmentType.enumValue,
    pickup: pickup,
    delivery: delivery,
  };
  return newValue;
};

export const getTemperatureError = (
  preCoolTempF?: string,
  minTempF?: string,
  maxTempF?: string | null,
): Array<string> => {
  const tempErrors: string[] = [];

  if (!preCoolTempF?.length || !minTempF?.length || !maxTempF?.length) {
    return tempErrors;
  }

  const preCoolTemp = parseInt(preCoolTempF, 10);
  const minTemp = parseInt(minTempF, 10);
  const maxTemp = parseInt(maxTempF, 10);

  if (preCoolTemp > minTemp) {
    tempErrors.push('Pre-cool temperature can not be greater than Min');
  }

  if (minTemp > maxTemp) {
    tempErrors.push(
      'Minimum temperature must be less than maximum temperature',
    );
  }

  return tempErrors;
};

export const cargoValueErrorText = (cargoValue: string) => {
  const cargoValueInt = cargoValue?.length
    ? parseInt(cargoValue.replace(/,/g, ''), 10)
    : '';

  if (cargoValueInt === 0) {
    return 'Cargo value must be greater than 0';
  }

  if ((cargoValueInt as number) > 100000) {
    return 'The maximum cargo value is $100,000. Please update the value to continue.';
  }

  return '';
};

export const getAppointment = (
  pickupDeliveryType: string,
  timezone: string,
  appointment?: AppointmentsType | null,
  pickupDate?: number | null,
) => {
  let appointmentsDetails = [] as Array<{
    title: string;
    details: { [s: string]: string };
  }>;
  if (!appointment) {
    if (!pickupDate) {
      return appointmentsDetails;
    }
    return [
      {
        title: 'Pickup Date',
        details: {
          pickupDate:
            DateTime.fromSeconds(pickupDate).toFormat('MMMM dd, yyyy'),
        },
      },
    ];
  }
  appointmentsDetails = [
    {
      title: `${pickupDeliveryType} Scheduled`,
      details: { isScheduled: appointment.hasAppointment ? 'Yes' : 'No' },
    },
  ];
  if (appointment.appointmentDate) {
    appointmentsDetails = [
      ...appointmentsDetails,
      {
        title: `${pickupDeliveryType} Date`,
        details: {
          date: DateTime.fromSeconds(appointment.appointmentDate).toFormat(
            'MMMM dd, yyyy',
          ),
        },
      },
    ];
  }
  if (
    appointment?.earliestTime ||
    appointment?.latestTime ||
    appointment?.appointmentTime
  ) {
    const timeRange =
      appointment.earliestTime && appointment.latestTime
        ? `${appointment.earliestTime} - ${appointment.latestTime}`
        : appointment.earliestTime || appointment.latestTime;

    appointmentsDetails = [
      ...appointmentsDetails,
      {
        title: `${pickupDeliveryType} Time`,
        details: {
          time:
            (appointment?.hasAppointment
              ? appointment.appointmentTime || ''
              : timeRange) +
            ' ' +
            timezone,
        },
      },
    ];
  }
  return appointmentsDetails;
};
