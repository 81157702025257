import React from 'react';
import styled from '@emotion/styled';

// @Components
import Typography from 'commonComponents/Typography/Typography';

// @Theme
import { spacing } from 'theme/Units';
import { palette, sectionSettings } from 'theme/Theme';

// @Types
import { TypographyType } from 'theme/themeType';

const TitleContainer = styled.div<{ hasBorder: boolean }>`
  padding-top: ${({ hasBorder }) => hasBorder && spacing.large};
  border-top: ${({ hasBorder }) =>
    hasBorder && `solid 1px ${palette.common.chrome}`};
`;

const BodyContainer = styled.div<{ padding: string }>`
  padding-top: ${({ padding }) => padding};
  padding-bottom: ${({ padding }) => padding};
`;

interface SectionProps {
  title?: string;
  children: React.ReactNode;
  variant: 'smokey' | 'heading' | 'subtitle' | 'title';
  hasBorder?: boolean;
  dataTestId?: string;
}

const Section = ({
  title,
  children,
  variant,
  hasBorder = false,
  dataTestId,
}: SectionProps) => {
  const sectionOption = sectionSettings[variant];

  return (
    <>
      {!!title?.length && (
        <TitleContainer
          hasBorder={hasBorder}
          data-testid={`${dataTestId}-title-container`}
        >
          <Typography
            data-testid={`${dataTestId}-title`}
            variant={sectionOption.variant as keyof TypographyType}
            color={sectionOption.color}
          >
            {title}
          </Typography>
        </TitleContainer>
      )}
      <BodyContainer padding={sectionOption.padding}>{children}</BodyContainer>
    </>
  );
};

export default Section;
