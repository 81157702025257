import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

// @Common components
import FilterDropdown from 'commonComponents/Dropdowns/FilterDropdown';

// @Helpers
import { getStatusTitle } from 'components/Shipments/ShipmentsHelpers';

// @Types
import { StatusEnumFormat } from 'types/Shipment.types';
import {
  ShipmentStatusFilterProps,
  ShipmentFiltersActionKind,
} from 'components/Shipments/ShipmentPage.types';

const ShipmentStatusFilter = ({
  dispatch,
  statuses,
}: ShipmentStatusFilterProps) => {
  const queryClient = useQueryClient();
  const statusEnums = queryClient.getQueryData([
    'shipmentsFilterEnums',
  ]) as Array<StatusEnumFormat>;

  const handleApply = () => {
    dispatch({
      type: ShipmentFiltersActionKind.APPLY_FILTERS,
      payload: {
        Statuses: statuses.length
          ? statuses.map((status) => status.Name)
          : null,
      },
    });
  };

  const handleClear = () => {
    dispatch({
      type: ShipmentFiltersActionKind.SET_STATUSES,
      payload: [],
    });
    dispatch({
      type: ShipmentFiltersActionKind.APPLY_FILTERS,
      payload: { Statuses: [] },
    });
  };

  const handleReset = () => {
    dispatch({
      type: ShipmentFiltersActionKind.RESET_STATE,
    });
  };

  const { placeholder, selectedCount } = getStatusTitle(statuses);

  return (
    <FilterDropdown
      handleApply={handleApply}
      handleClear={handleClear}
      handleReset={handleReset}
      options={statusEnums}
      onChange={(value) => {
        dispatch({
          type: ShipmentFiltersActionKind.SET_STATUSES,
          payload: value,
        });
      }}
      getOptionLabel={(option) => (option as StatusEnumFormat).Description}
      getOptionValue={(option) => (option as StatusEnumFormat).Name}
      value={statuses}
      placeholder={placeholder}
      selectedCount={selectedCount}
      aria-label="Filter by status"
    />
  );
};

export default ShipmentStatusFilter;
