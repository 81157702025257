import React from 'react';
import styled from '@emotion/styled';

// @Components
import Typography from 'commonComponents/Typography/Typography';
import Section from 'commonComponents/Section/Section';

// @Icons
import Phone from 'icons/Phone.svg';
import Email from 'icons/Email.svg';

// @Theme
import { spacing } from 'theme/Units';

// @Types
import { ContactType } from './ContactInformation.types';

// @Styles
import { EmailLink } from 'styles/Links';

interface InformationSectionProps {
  contact: ContactType;
}

const InformationSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 36px;

  svg {
    transform: scale(0.75) translateY(10px);
    margin-right: ${spacing.xsmall};
  }
`;

const InformationSection = ({ contact }: InformationSectionProps) => {
  const { fullName, email, phoneNumber, title, userId, phoneExtension } =
    contact;

  return (
    <InformationSectionContainer data-testid={`contact-${userId}`}>
      <Section title={title} variant="smokey">
        <div>
          <Typography margin="0px">{fullName}</Typography>
        </div>
        <div>
          <Email />
          <EmailLink
            to="#"
            onClick={(e) => {
              window.location.href = `mailto: ${email}`;
              e.preventDefault();
            }}
          >
            {email}
          </EmailLink>
        </div>
        {phoneNumber && (
          <div>
            <Phone />
            <Typography>
              {phoneNumber} {phoneExtension && ` x${phoneExtension}`}
            </Typography>
          </div>
        )}
      </Section>
    </InformationSectionContainer>
  );
};

export default InformationSection;
