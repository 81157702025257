import React from 'react';
import styled from '@emotion/styled';

// @Common components
import TitleDetails from 'commonComponents/TitleDetails';
import Typography from 'commonComponents/Typography/Typography';

// @Styles
import { ReadOutContainer } from './PickupDeliveryFormStyles';
import { LinkButton } from 'styles/Links';
import { palette } from 'theme/Theme';
import { spacing } from 'theme/Units';

// @Types
import { PickupDeliveryType, ShipmentFormEnums } from 'types/Shipment.types';

// @Utils
import { insertDashesInPhoneNumber } from 'utils/utils';
import { getAppointment } from '../ShipmentFormHelpers';
import { useQueryClient } from '@tanstack/react-query';

const ReadOutWrapper = styled.div`
  display: flex;

  svg {
    fill: ${palette.common.arrive};
    margin-right: ${spacing.xsmall};
  }
`;

interface PickupDeliveryReadOutProps {
  pickupDelivery: PickupDeliveryType;
  title: string;
  timezone: string;
  handleEditPickupDelivery?: () => void;
  icon?: React.ReactNode;
  pickupDate?: number;
}

const PickupDeliveryReadOut = ({
  pickupDelivery,
  title,
  timezone,
  handleEditPickupDelivery,
  icon,
  pickupDate,
}: PickupDeliveryReadOutProps) => {
  const queryClient = useQueryClient();
  const ShipmentFormEnums = queryClient.getQueryData([
    'shipmentFormEnums',
  ]) as ShipmentFormEnums;
  const [reference1, reference2, reference3, reference4] = Object.values(
    pickupDelivery.referenceNumbers,
  );

  const { fullName, phoneNumber, email } = pickupDelivery.schedulingContact ?? {
    fullName: null,
    phoneNumber: null,
    email: null,
  };

  const facilityHours =
    pickupDelivery.facilityClose || pickupDelivery.facilityOpen
      ? [
          {
            title: 'Facility Hours',
            details: {
              hours:
                (pickupDelivery.facilityOpen && pickupDelivery.facilityClose
                  ? `${pickupDelivery.facilityOpen} - ${pickupDelivery.facilityClose}`
                  : pickupDelivery.facilityOpen ||
                    pickupDelivery.facilityClose) +
                ' ' +
                timezone,
            },
          },
        ]
      : [];
  const pickupDeliveryNotes = !!pickupDelivery.notes?.length
    ? [
        {
          title: `${title} Notes`,
          details: {
            notes: pickupDelivery.notes,
          },
        },
      ]
    : [];
  const pickupDeliveryInformation = [
    {
      title: 'Facility',
      details: {
        name: pickupDelivery.facilityName,
        adress1: pickupDelivery.address1,
        address2: pickupDelivery.address2,
        cityState: `${pickupDelivery.cityState} ${pickupDelivery.postalCode}`,
      },
    },
    ...facilityHours,
    {
      title: 'Scheduling',
      details: {
        type:
          ShipmentFormEnums.schedulingTypes.find(
            (type) => type.enumValue === pickupDelivery.schedulingType,
          )?.displayName || '',
        contact:
          fullName && phoneNumber
            ? `${fullName}, ${insertDashesInPhoneNumber(phoneNumber)}`
            : fullName || insertDashesInPhoneNumber(phoneNumber || ''),
        contactEmail: email,
      },
    },
    ...getAppointment(
      title,
      timezone,
      pickupDelivery.schedulingType === 'APPOINTMENT'
        ? pickupDelivery.appointment
        : null,
      pickupDate,
    ),
    ...pickupDeliveryNotes,
    {
      title: `${title} Reference`,
      details: {
        reference1: !!reference1?.type
          ? `${reference1.type?.displayName} ${reference1.number}`
          : '',
        reference2: !!reference2?.type
          ? `${reference2.type?.displayName} ${reference2.number}`
          : '',
        reference3: !!reference3?.type
          ? `${reference3.type?.displayName} ${reference3.number}`
          : '',
        reference4: !!reference4?.type
          ? `${reference4.type?.displayName} ${reference4.number}`
          : '',
      },
    },
  ];

  return (
    <ReadOutWrapper>
      <div>{icon}</div>
      <div>
        <Typography variant="h2">{title}</Typography>
        <ReadOutContainer>
          {pickupDeliveryInformation.map((element) => (
            <TitleDetails
              key={element.title}
              title={element.title}
              details={Object.values(element.details)}
            />
          ))}
          {!!handleEditPickupDelivery && (
            <div>
              <LinkButton onClick={handleEditPickupDelivery}>
                Edit {title}
              </LinkButton>
            </div>
          )}
        </ReadOutContainer>
      </div>
    </ReadOutWrapper>
  );
};

export default PickupDeliveryReadOut;
