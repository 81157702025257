import styled from '@emotion/styled';
import { math } from 'polished';

// @Common Components
import TextArea from '@arrive/text-area';
import Typography from 'commonComponents/Typography/Typography';

// @Styles
import { spacing } from 'theme/Units';

export const ShipmentFormSubHeading = styled(Typography)`
  margin: 0 0 ${spacing.gutter} 0;
  display: block;
`;

export const NotesContainer = styled(TextArea)`
  border: none;
  width: 100%;
  resize: none;

  &:focus-visible {
    outline: none;
  }

  padding: ${spacing.xsmall};
`;

export const ReferenceContainer = styled.div<{ length: number }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & > div:first-of-type {
    width: 240px;
  }

  & > div:nth-of-type(2) {
    width: ${({ length }) => (length === 1 ? '400px' : '350px')};
  }
`;

export const CircularButtonContainer = styled.div`
  display: flex;
  margin-top: ${spacing.gutter};
  align-items: center;

  & > button {
    border-color: ${({ theme }) => theme.palette.common.smokey};
  }

  & > span {
    margin-left: ${spacing.normal};
  }
`;
export const ShipmentFormSectionFooterContainer = styled.div`
  max-width: 660px;
  display: flex;
  justify-content: flex-end;
  padding: ${spacing.large} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.chrome};

  & > button:nth-of-type(2) {
    width: 220px;
  }
`;

export const ShipmentFormDisabledSectionHeader = styled.div`
  color: ${({ theme }) => theme.palette.common.chrome};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.chrome};
  width: 660px;
  margin: 0 ${math(`${spacing.medium} * 3`)};
  padding: ${math(`${spacing.medium} * 2`)} 0;
`;

export const ShipmentSectionWrapper = styled.div`
  padding: ${math(`${spacing.medium} * 3`)} ${math(`${spacing.medium} * 3`)} 0
    ${math(`${spacing.medium} * 3`)};
  max-width: 960px;
`;

export const FormContainer = styled.div`
  margin-top: ${spacing.large};
  min-width: 650px;

  & > div {
    margin-bottom: ${spacing.gutter};
    max-width: 660px;
  }

  & > .arrive_TextArea__inputWrapper.arrive_TextArea__lg {
    height: 100px !important;
    max-width: 660px;
  }
`;
