import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

//@Okta
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

type OktaSignInWrapperProps = {
  children: React.ReactNode;
};
const oktaConfig = {
  clientId: process.env.OKTA_CLIENT_ID,
  issuer: process.env.OKTA_ISSUER,
  pkce: true,
  redirectUri: process.env.OKTA_REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
};

const SecurityWrapper = ({ children }: OktaSignInWrapperProps) => {
  const oktaAuth = new OktaAuth(oktaConfig);
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    async (_oktaAuth: unknown, originalUri: string) => {
      history.replace(
        toRelativeUrl(originalUri || '/', window.location.origin),
      );
    },
    [history],
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default SecurityWrapper;
