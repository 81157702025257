import React, { forwardRef, useEffect, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { format } from 'date-fns';

// @Components
import {
  DateTimePickerInput,
  DateContainer,
  DatePickerHeader,
} from './DateTimePickersElements';

// @Helpers
import { validateDateInRange } from './DateTimePickersHelpers';

// @Icons
import Calendar from '@arrive/ui-icons/duo-tone/ic_calendar_duo.svg';

// @Styles
import { StyledDatePicker } from './DateTimePickerStyles';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledInputError } from 'styles/ErrorStyles';

type DateInputProps = ReactDatePickerProps & {
  onClick: () => void;
  submitInput: (date: Date | null) => void;
  currentValue?: Date | null;
};

const DateInput = forwardRef(
  (
    {
      onClick,
      placeholderText,
      submitInput,
      currentValue,
      dateInputRef,
      minDate,
      maxDate,
      required,
      disabled,
      ...rest
    }: DateInputProps & { dateInputRef?: React.RefObject<HTMLInputElement> },
    _ref,
  ) => {
    const renderInput = () => {
      return (
        // @ts-ignore - added to be able to use the onChange prop coming from the custom input
        <input
          {...rest}
          disabled={disabled}
          aria-label="Datepicker input"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onBlur={handleSubmitInput}
          onKeyUp={(event) =>
            event.key === 'Enter' ? handleSubmitInput() : ''
          }
          ref={dateInputRef}
        />
      );
    };

    const [inputValue, setInputValue] = useState(
      currentValue ? format(currentValue, 'MMMM dd, yyyy') : '',
    );
    useEffect(() => {
      setInputValue(currentValue ? format(currentValue, 'MMMM dd, yyyy') : '');
    }, [currentValue]);

    const handleSubmitInput = () => {
      if (!inputValue) {
        submitInput(null);
      }

      const updatedDateInputValue = currentValue
        ? format(currentValue, 'MMMM dd, yyyy')
        : '';
      const newDate = Date.parse(inputValue);
      return newDate && validateDateInRange(new Date(newDate), minDate, maxDate)
        ? submitInput(new Date(newDate))
        : setInputValue(updatedDateInputValue);
    };

    return (
      <DateTimePickerInput
        hasValue={!!inputValue.length}
        onClick={onClick}
        required={required}
        disabled={disabled}
        placeholderText={placeholderText}
        svgIcon={<Calendar />}
      >
        {renderInput()}
      </DateTimePickerInput>
    );
  },
);

DateInput.displayName = 'DateInput';

const DatePicker = ({
  placeholderText,
  minDate,
  maxDate,
  selected,
  onChange,
  datePickerRef,
  errorText,
  ...rest
}: ReactDatePickerProps & {
  datePickerRef?: React.RefObject<HTMLInputElement>;
  errorText?: string;
}) => {
  return (
    <div aria-label={selected ? format(selected, 'MMMM dd, yyyy') : ''}>
      <StyledDatePicker
        monthsShown={2}
        calendarContainer={DateContainer}
        selected={selected}
        onChange={onChange}
        customInput={
          <DateInput
            dateInputRef={datePickerRef}
            placeholderText={placeholderText}
            currentValue={selected}
            submitInput={(date: Date | null) => onChange(date, undefined)}
            minDate={minDate}
            maxDate={maxDate}
            onChange={() => {
              return;
            }}
            onClick={() => {
              return;
            }}
          />
        }
        renderCustomHeader={DatePickerHeader}
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
      {!!errorText?.length && <StyledInputError>{errorText}</StyledInputError>}
    </div>
  );
};

export default DatePicker;
