import React from 'react';

// Common Component
import Typography from '../Typography/Typography';
import TimePicker from './TimePicker';

// @Libraries
import { format } from 'date-fns';

// @Styles
import { spacing } from 'theme/Units';
import { TimeRangePickerContainer } from './DateTimePickerStyles';

type TimeRangePickerProps = {
  startTime: string;
  endTime: string;
  startLabel: string;
  endLabel: string;
  setRangeStart: (value: string) => void;
  setRangeEnd: (value: string) => void;
  errorText?: string;
  required?: boolean;
  timeFormat?: string;
};

const TimeRangePicker = ({
  startTime,
  endTime,
  startLabel,
  endLabel,
  setRangeStart,
  setRangeEnd,
  errorText = '',
  required = false,
  timeFormat = 'HH:mm',
}: TimeRangePickerProps) => {
  return (
    <TimeRangePickerContainer>
      <TimePicker
        ariaLabel={startLabel}
        dataTestId="open-hours"
        onChange={(e) => e && setRangeStart(format(e, timeFormat))}
        placeholderText={startLabel}
        value={startTime}
        required={required}
        timeFormat={timeFormat}
      />
      <Typography margin={`${spacing.normal}`}>to</Typography>
      <TimePicker
        ariaLabel={endLabel}
        dataTestId="endTime"
        onChange={(e) => e && setRangeEnd(format(e, timeFormat))}
        placeholderText={endLabel}
        value={endTime}
        errorText={errorText}
        required={required}
        timeFormat={timeFormat}
      />
    </TimeRangePickerContainer>
  );
};

export default TimeRangePicker;
