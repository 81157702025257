import { DateTime } from 'luxon';

// @Types
import { Invoice } from 'types/Shipment.types';
import { ColorOptions } from 'commonComponents/Pill/StatusPillTypes';

export const InvoiceColors = {
  Processing: 'text',
  ['Partially Paid']: 'warning',
  Invoiced: 'error',
  ['Under Review']: 'text',
  Paid: 'success',
} as Record<string, ColorOptions>;

export const getInvoiceDate = (invoice: Invoice) => {
  const { InvoiceStatus, InvoicePaidOn, InvoiceCreatedOn } = invoice;
  if (InvoiceStatus === 'Paid') {
    return InvoicePaidOn
      ? `Paid ${DateTime.fromSeconds(InvoicePaidOn).toFormat('LLL dd, yyyy')}`
      : null;
  }
  return InvoiceCreatedOn
    ? `Invoiced ${DateTime.fromSeconds(InvoiceCreatedOn).toFormat(
        'LLL dd, yyyy',
      )}`
    : null;
};
