import React, { memo } from 'react';

// @Common components
import Typography from 'commonComponents/Typography/Typography';
import TimeRangePicker from 'commonComponents/DateTimePickers/TimeRangePicker';
import DatePicker from 'commonComponents/DateTimePickers/DatePicker';
import TimePicker from 'commonComponents/DateTimePickers/TimePicker';

// @Libraries
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import RadioButton from '@arrive/radio-button';

// @Styles
import { FormContainer } from '../ShipmentFormStyles';
import {
  AppointmentsSection,
  RadioContainer,
  SchedulingContainer,
  TimePickerContainer,
} from './PickupDeliveryFormStyles';

// @Types
import { AppointmentsType } from 'types/Shipment.types';

// @Utils
import { convertToTitleCase, formatTimeStampFromUnixTime } from 'utils/utils';
import { spacing } from 'theme/Units';

type AppointmentsProps = {
  appointment: AppointmentsType;
  pickupDeliveryType: 'pickup' | 'delivery';
  handleAppointmentsChange: (value: AppointmentsType) => void;
  pickupDate: number;
  timezone: string;
};

export const Appointments = ({
  appointment,
  pickupDeliveryType,
  handleAppointmentsChange,
  pickupDate,
  timezone,
}: AppointmentsProps) => {
  const {
    earliestTime,
    latestTime,
    appointmentDate,
    hasAppointment,
    appointmentTime,
  } = appointment;

  const getScheduleLabel = () => {
    if (pickupDeliveryType === 'pickup') {
      const formattedDate = (
        <Typography variant="subtitle1">
          {appointmentDate
            ? formatTimeStampFromUnixTime(appointmentDate, 'MMMM dd, yyyy')
            : ''}
        </Typography>
      );
      return hasAppointment ? (
        <>Scheduled appointment on {formattedDate}</>
      ) : (
        <>Pickup appointment request on {formattedDate}</>
      );
    }
    return (
      <>
        {hasAppointment
          ? 'What is the date and time of the requested delivery?'
          : 'Do you have a date and time you’d like delivery by?'}
      </>
    );
  };

  const handleUpdateTime = (name: string, value: string) => {
    handleAppointmentsChange({
      ...appointment,
      [name]: value,
    });
  };

  const handleUpdateAppointment = (value: boolean) => {
    const fieldsToUpdate = value
      ? {
          earliestTime: undefined,
          latestTime: undefined,
        }
      : {
          appointmentTime: undefined,
        };
    handleAppointmentsChange({
      ...appointment,
      ...fieldsToUpdate,
      hasAppointment: value,
    });
  };

  return (
    <FormContainer>
      <Typography
        variant="subtitle1"
        margin={`${spacing.xsmall} 0 ${spacing.normal} 0`}
        as="p"
      >
        Appointments
      </Typography>
      <AppointmentsSection>
        <Typography variant="body1" margin="0 0 8px 0">
          Do you have an appointment scheduled at this facility?
        </Typography>
        <RadioContainer>
          <RadioButton
            label="Yes"
            value="Yes"
            checked={hasAppointment}
            onChange={() => {
              handleUpdateAppointment(true);
            }}
          ></RadioButton>
          <RadioButton
            label="No"
            value="No"
            checked={!hasAppointment}
            onChange={() => {
              handleUpdateAppointment(false);
            }}
          ></RadioButton>
        </RadioContainer>
      </AppointmentsSection>
      <AppointmentsSection>
        <div
          data-testid={`${pickupDeliveryType}-${
            hasAppointment ? 'appointment' : 'no-appointment'
          }`}
        >
          <Typography variant="body1" margin={`${spacing.xsmall} 0 0 0`} as="p">
            {getScheduleLabel()}
          </Typography>
          {(!hasAppointment || pickupDeliveryType === 'delivery') && (
            <Typography
              variant="body2"
              margin={`${spacing.xxsmall} 0 ${spacing.xsmall} 0`}
              as="p"
            >
              A member of our team will confirm the availability of the date and
              time.
            </Typography>
          )}
        </div>
        <SchedulingContainer showDateRange={!hasAppointment}>
          {pickupDeliveryType === 'delivery' && (
            <DatePicker
              required={hasAppointment}
              minDate={new Date(pickupDate * 1000)}
              placeholderText="Delivery Date"
              onChange={(date: Date) => {
                handleAppointmentsChange({
                  ...appointment,
                  appointmentDate: DateTime.fromJSDate(date)
                    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                    .toSeconds(),
                });
              }}
              selected={
                appointmentDate ? new Date(appointmentDate * 1000) : null
              }
            />
          )}
          <TimePickerContainer>
            {hasAppointment ? (
              <TimePicker
                placeholderText={`${convertToTitleCase(
                  pickupDeliveryType,
                )} Time`}
                required
                timeFormat="HH:mm"
                ariaLabel="appointmentTime"
                dataTestId="appointmentTime"
                onChange={(time) =>
                  handleUpdateTime(
                    'appointmentTime',
                    time ? format(time, 'HH:mm') : '',
                  )
                }
                value={appointmentTime || ''}
              />
            ) : (
              <TimeRangePicker
                startTime={earliestTime || ''}
                endTime={latestTime || ''}
                startLabel="Earliest Time"
                endLabel="Latest Time"
                setRangeStart={(time) => handleUpdateTime('earliestTime', time)}
                setRangeEnd={(time) => handleUpdateTime('latestTime', time)}
                errorText={
                  (earliestTime &&
                    latestTime &&
                    earliestTime > latestTime &&
                    'Latest time must be after earliest time') ||
                  ''
                }
                timeFormat="HH:mm"
              />
            )}
            <Typography margin={`${spacing.normal}`}>{timezone}</Typography>
          </TimePickerContainer>
        </SchedulingContainer>
      </AppointmentsSection>
    </FormContainer>
  );
};

export default memo(Appointments);
