import React from 'react';
import ContentLoader from 'react-content-loader';

// @Styles
import { palette } from 'theme/Theme';

interface ContentLoadingProps {
  width: number;
  id?: string;
  alignRight?: boolean;
  containerWidth?: number;
}

const TableContentLoader = ({
  width,
  id,
  alignRight = false,
  containerWidth = 160,
}: ContentLoadingProps) => {
  return (
    <ContentLoader
      speed={2}
      width={containerWidth}
      height={20}
      viewBox={`0 0 ${containerWidth} 20`}
      backgroundColor={palette.common.tandem}
      foregroundColor={palette.common.chrome}
      id={id}
      rtl={alignRight}
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height="20" />
    </ContentLoader>
  );
};

export default TableContentLoader;
