import styled from '@emotion/styled';

export const Asterisk = styled.div<{ isDisabled?: boolean }>`
  display: inline;
  ::after {
    content: '*';
    color: ${({ isDisabled, theme }) =>
      isDisabled
        ? theme.palette.common.chrome
        : theme.palette.common.jackknife};
  }
`;
