/** istanbul ignore next */
//TODO: write tests for this

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounceAsync = <T, Callback extends (...args: any[]) => Promise<T>>(
  callback: Callback,
  wait: number,
): ((...args: Parameters<Callback>) => Promise<T>) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<T>((resolve) => {
      const timeoutPromise = new Promise<void>((resolve) => {
        timeoutId = setTimeout(resolve, wait);
      });
      timeoutPromise.then(async () => {
        resolve(await callback(...args));
      });
    });
  };
};
/** istanbul ignore end */

export default debounceAsync;
