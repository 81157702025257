import { useEffect } from 'react';

const useDisableBodyScroll = (disableScroll: boolean) => {
  useEffect(() => {
    if (disableScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [disableScroll]);
};

export default useDisableBodyScroll;
