import { UserLoginInformation } from 'types/User.types';

export const initializePendo = (user: UserLoginInformation) => {
  const pendoValues = {
    visitor: {
      id: user.email,
      email: user.email,
      full_name: user.name,
      first_name: user.given_name,
      last_name: user.family_name,
      customer_id: user.customerId,
    },
    account: {
      id: user.customerId
        ? `External Customer: ${user.customerId}`
        : 'Internal Employee',
      UserType: user.customerId ? 'Customer' : 'Internal',
      is_paying: '',
      monthly_value: 1,
    },
  };
  // @ts-ignore
  window.pendo.initialize(pendoValues);
};
