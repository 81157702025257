import axios, { AxiosInstance } from 'axios';
const BASE_URL = 'http://127.0.0.1:3000';

// default instance of axios instance. The base url is the url we use for webpack dev server
export const axiosSecureInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});
