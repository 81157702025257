import styled from '@emotion/styled';

// @Arrive
import { TableHeader, TableRow } from '@arrive/table';

// @Common Components
import {
  TableDataCell,
  TableRowLoadingColumn,
} from 'commonComponents/Table/TableStyles';

// @Theme
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';

export const BalanceDueDataCell = styled(TableDataCell)`
  text-align: right;
  padding-right: ${spacing.normal};
`;

export const LoadStopsDataCell = styled(TableDataCell)`
  max-width: 240px;
`;

export const StatusDataCell = styled(TableDataCell)`
  padding: ${spacing.medium} 0px 0px ${spacing.normal};
`;

export const AmountDataCell = styled(TableDataCell)`
  text-align: right;
  padding-right: ${spacing.normal};
`;

export const AmountLoadingContainer = styled(TableRowLoadingColumn)`
  margin-left: ${spacing.large};
`;

export const InvoiceTableDataCell = styled(TableDataCell)`
  padding-left: ${spacing.large};
`;

export const InvoiceHeader = styled(TableHeader)`
  padding: 0px ${spacing.normal};
`;

export const DueHeader = styled(InvoiceHeader)`
  width: 80px;
  text-align: right;
`;

export const AmountHeader = styled(InvoiceHeader)`
  text-align: right;
`;

export const InvoiceTableRow = styled(TableRow)`
  &:hover {
    background-color: ${palette.common.tandem};
  }
`;
