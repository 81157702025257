import React from 'react';

// @arrive
import Button from '@arrive/button';

// @Components
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Styles
import { LoadingButton } from 'styles/Buttons';
import styled from '@emotion/styled';
import { spacing } from 'theme/Units';

export const ButtonFooterContainer = styled.div`
  max-width: 660px;
  display: flex;
  justify-content: flex-end;
  padding: ${spacing.large} 0;
  & > button:nth-of-type(2) {
    width: 220px;
  }
`;

interface ButtonFooterProps {
  handleSecondaryButton: () => void;
  handlePrimaryButton: () => void;
  primaryButtonText: string;
  secondaryButtonText: string;
  isEnabled?: boolean;
  isLoadingSubmit?: boolean;
}

const ButtonFooter = ({
  handleSecondaryButton,
  handlePrimaryButton,
  primaryButtonText,
  secondaryButtonText,
  isEnabled,
  isLoadingSubmit = false,
}: ButtonFooterProps) => {
  return (
    <ButtonFooterContainer>
      <Button text type="secondary" onClick={handleSecondaryButton}>
        {secondaryButtonText}
      </Button>
      <LoadingButton
        disabled={!isEnabled || isLoadingSubmit}
        onClick={handlePrimaryButton}
      >
        {isLoadingSubmit && <SpinningLoadingIndicator />}
        {primaryButtonText}
      </LoadingButton>
    </ButtonFooterContainer>
  );
};

export default ButtonFooter;
