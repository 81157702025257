import React, { ReactElement } from 'react';

// @Common Components
import SidePanel from 'commonComponents/SidePanel/SidePanel';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import ShipmentPickupDeliveryInformation from './ShipmentPickupDeliveryInformation';
import ShipmentDetailsShipmentSummary from './ShipmentDetailsShipmentSummary';
import { ShipmentDetailsPanelHeaderContentLoader } from './ShipmentDetailsContentLoaders';
import ShipmentDetailsPanelTabs from './ShipmentDetailsPanelTabs/ShipmentDetailsPanelTabs';

// @Hooks
import { useQueryParams, useSetQueryParams } from 'hooks/useReactRouterQueries';
import { useGetShipmentByLoadId } from 'components/Shipments/useGetShipments';

// @Icons
import CLOSE_ICON from '@arrive/ui-icons/svg/ic_close.svg';

// @Styles
import {
  ShipmentPanelHeader,
  ShipmentPanelSubheader,
} from './ShipmentDetailsPanelStyles';
import { palette } from 'theme/Theme';

// @Types
import { ShipmentDetailsPanelHeaderProps } from '../ShipmentPage.types';

// @Utils
import { formatDates } from 'utils/utils';

export const ShipmentDetailsPanelHeader = ({
  earliestTime,
  handleCloseShipmentDetails,
  isLoading,
  latestTime,
  loadNumber,
  status,
  timeZone,
}: ShipmentDetailsPanelHeaderProps) => {
  const pickupDate = formatDates(earliestTime, latestTime, timeZone)?.split(
    '-',
  );

  const renderPickupDate = () => {
    if (pickupDate) {
      if (pickupDate[1]) {
        return (
          <>
            <Typography data-test-id="shipment-pickup-date">
              Pickup scheduled {pickupDate[0]} -
            </Typography>
            <Typography>{pickupDate[1]}</Typography>
          </>
        );
      }

      return (
        <Typography data-test-id="shipment-pickup-date">
          Pickup scheduled {pickupDate[0]}
        </Typography>
      );
    }

    return null;
  };

  return (
    <ShipmentPanelHeader>
      <ShipmentPanelSubheader>
        <Typography
          color={palette.common.ethanol}
          margin="0"
          data-testid="arrive-load-number"
        >
          Arrive {loadNumber}
        </Typography>
        <button
          aria-label="close-sidepanel"
          data-testid="close-sidepanel"
          onClick={handleCloseShipmentDetails}
        >
          <CLOSE_ICON aria-labelledby="close-sidepanel" />
        </button>
      </ShipmentPanelSubheader>
      <div data-tesid="shipment-details-header">
        {isLoading ? (
          <ShipmentDetailsPanelHeaderContentLoader />
        ) : (
          <>
            <Typography variant="display2">{status}</Typography>
            {renderPickupDate()}
          </>
        )}
      </div>
    </ShipmentPanelHeader>
  );
};

const ShipmentDetailsPanel = (): ReactElement => {
  // query params
  const setQueryParams = useSetQueryParams();

  // router queries
  const { sidePanel, loadNumber, ...restQs } = useQueryParams();

  // handle close side panel
  const handleCloseShipmentDetails = () => {
    setQueryParams({ ...restQs, sidePanel: null, loadNumber: null });
  };

  // check if side panel needs to be opened
  const isShipmentDetailsPanelOpen = sidePanel === 'shipment';

  // fetch data
  const { isInitialLoading: isLoadingShipmentDetails, data: ShipmentDetails } =
    useGetShipmentByLoadId(loadNumber as string);

  // pick up info
  const PickUpDetails = ShipmentDetails?.Stops['Pickup'][0];

  // delivery details
  const DeliveryDetails =
    ShipmentDetails?.Stops['Delivery'][
      ShipmentDetails?.Stops['Delivery'].length - 1
    ];

  return (
    <SidePanel
      handleCloseSideModal={handleCloseShipmentDetails}
      show={isShipmentDetailsPanelOpen}
      width="550px"
    >
      <ShipmentDetailsPanelHeader
        earliestTime={PickUpDetails?.EarliestTime}
        handleCloseShipmentDetails={handleCloseShipmentDetails}
        isLoading={isLoadingShipmentDetails}
        latestTime={PickUpDetails?.LatestTime}
        loadNumber={parseInt(loadNumber as string)}
        status={ShipmentDetails?.Status}
        timeZone={PickUpDetails?.TimeZoneIANA}
        data-testid="shipment-details-panel-header"
      />
      <ShipmentPickupDeliveryInformation
        deliveryDetails={DeliveryDetails}
        pickupDetails={PickUpDetails}
        isLoading={isLoadingShipmentDetails}
      />
      <ShipmentDetailsShipmentSummary
        equipmentType={ShipmentDetails?.EquipmentType}
        isLoading={isLoadingShipmentDetails}
        miles={ShipmentDetails?.TotalMiles}
        mode={ShipmentDetails?.Mode}
        referenceNumbers={ShipmentDetails?.ReferenceNumbers}
      />
      <ShipmentDetailsPanelTabs
        isLoadingShipmentDetails={isLoadingShipmentDetails}
      />
    </SidePanel>
  );
};

export default ShipmentDetailsPanel;
