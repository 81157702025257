import React from 'react';
import styled from '@emotion/styled';

// @Common components
import Typography from 'commonComponents/Typography/Typography';

// @Theme
import { palette } from 'theme/Theme';
import { TypographyType } from 'theme/themeType';
import { spacing } from 'theme/Units';

const TitleSubtitleContainer = styled.div`
  > div {
    display: flex;
  }

  svg {
    fill: ${palette.common.arrive};
    margin-top: ${spacing.xxsmall};
    margin-right: ${spacing.xsmall};
    transform: scale(0.8);
  }
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const IconContainer = styled.div`
  width: ${spacing.large};
`;

interface TitleSubtitleProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  overline?: string;
  variants?: {
    title?: keyof TypographyType;
    subtitle?: keyof TypographyType;
  };
  dataTestId?: string;
}

const TitleSubtitle = ({
  title,
  subtitle,
  icon,
  overline,
  variants,
  dataTestId,
}: TitleSubtitleProps) => {
  return (
    <TitleSubtitleContainer>
      {!!overline && (
        <Typography
          variant="overline"
          margin={`${spacing.xxsmall} ${!!icon ? spacing.large : spacing.none}`}
          color={palette.common.smokey}
        >
          {overline}
        </Typography>
      )}
      <div>
        {!!icon && <IconContainer>{icon}</IconContainer>}
        <FlexColumnContainer>
          <Typography
            variant={variants?.title ?? 'body1'}
            margin={`${spacing.xxsmall} ${spacing.none}`}
            color={palette.common.axle}
            data-testid={dataTestId || 'title-subtitle'}
          >
            {title}
          </Typography>
          {!!subtitle && (
            <Typography
              variant={variants?.subtitle ?? 'body2'}
              margin={`${spacing.none}`}
              color={palette.common.smokey}
            >
              {subtitle}
            </Typography>
          )}
        </FlexColumnContainer>
      </div>
    </TitleSubtitleContainer>
  );
};

export default TitleSubtitle;
