import styled from '@emotion/styled';
import { math } from 'polished';

// @Theme
import { spacing } from 'theme/Units';

export const PageContainer = styled.div`
  padding: ${math(`${spacing.large} * 2`)} ${math(`${spacing.xlarge} * 3.5`)};
  padding-bottom: 80px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};
`;
