import { RouterDictionaryType, RoleTypes } from 'types/NavItem.types';
import ROUTES from '../../routes';

export const getReactRouterItems = (
  navObject: RouterDictionaryType,
  userRoles: string[] | [] | undefined,
) => {
  // update the route path
  const routerItems = userRoles
    ? userRoles.flatMap((role, idx) => {
        const routerItem = navObject[role as RoleTypes];

        if (routerItem) {
          // get router's dependant items
          const { dependents, ...rest } = routerItem;
          if (idx === 0) {
            return [
              // Return new object with default path
              { ...rest, path: ROUTES.DEFAULT },
              // Return original object with original path
              { ...rest },
              ...dependents,
            ];
          }

          return [rest, ...dependents];
        }

        return [];
      })
    : [];
  return routerItems;
};

export const getPathSegments = (pathname: string) => {
  const pathSegments = pathname.split('/');
  return pathSegments.length > 1 ? `/${pathSegments[1]}` : '/';
};
