/**
 * How to use
 * Simply import the component that you need as usual
 * When to use
 * Use InlineSpinningLoadingIndicator for text inputs where you have to validate the user input from a backend api
 * ex: Validate PostalCode after user typed in.
 * Use InlineSquareLoadingIndicator for accordion modules where you fetch data once the accordion is open.
 * Wrap the component in div tag and style the div accordingly.
 * Example screenshots are attached to the pr https://github.com/Arrive-Logistics/crm-ui/pull/309/
 */

import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { spacing } from 'theme/Units';

const StyledSVGRect = styled.svg`
  padding: ${`0px ${spacing.xsmall} `};
  rect {
    fill: ${(props) => props.theme.palette.common.arrive};
  }
`;

const StyledSVGSpinningCircle = styled.svg`
  padding: ${`0px ${spacing.xsmall} `};
  fill: ${(props) => props.theme.palette.common.arrive};
`;

const SpinningLoadingIndicator = ({
  height = '34px',
  width = '34px',
  fillColor,
}: {
  height?: string;
  width?: string;
  fillColor?: string;
}): ReactElement => {
  return (
    <StyledSVGSpinningCircle
      data-testid="spinning-loading-indicator"
      version="1.1"
      id="spinning-circle"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      xmlSpace="preserve"
    >
      <path
        fill={fillColor}
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </StyledSVGSpinningCircle>
  );
};

const SquareLoadingIndicator = ({
  height = '60px',
  width = '48px',
  fillColor,
}: {
  height?: string;
  width?: string;
  fillColor?: string;
}): ReactElement => {
  return (
    <StyledSVGRect
      data-testid="square-loading-indicator"
      fill={fillColor}
      version="1.1"
      id="square-indicator"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      xmlSpace="preserve"
    >
      <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.15s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="0.2; 1; .2"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          attributeType="XML"
          values="10; 20; 10"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          attributeType="XML"
          values="10; 5; 10"
          begin="0.3s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </StyledSVGRect>
  );
};

export { SpinningLoadingIndicator, SquareLoadingIndicator };
