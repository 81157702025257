import React from 'react';
import { nanoid } from 'nanoid';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common Component
import TableContentLoader from 'commonComponents/Table/TableContentLoader';
import {
  TableRowLoading,
  TableDataCell,
  TableRowLoadingColumn,
} from 'commonComponents/Table/TableStyles';

const LoadingRow = ({ index }: { index: number }) => {
  return (
    <TableRowLoading data-testid={`quote-history-row-loading-${index}`}>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            containerWidth={100}
            width={100}
            id={`pickup-location-loading-${index}`}
          />
          <TableContentLoader
            containerWidth={100}
            width={60}
            id={`pickup-date-loading-${index}`}
          />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={100}
          width={100}
          id={`delivery-location-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            containerWidth={80}
            width={60}
            id={`rate-loading-${index}`}
          />
          <TableContentLoader
            containerWidth={80}
            width={60}
            id={`quoteReference-loading-${index}`}
          />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            containerWidth={60}
            width={40}
            id={`quoteMiles-loading-${index}`}
          />
          <TableContentLoader
            containerWidth={60}
            width={60}
            id={`rate-per-mile-loading-${index}`}
          />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            containerWidth={80}
            width={80}
            id={`quoteDate-${index}`}
          />
          <TableContentLoader
            containerWidth={80}
            width={40}
            id={`quoteYear-${index}`}
          />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={100}
          width={100}
          id={`equipmentType-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={60}
          width={40}
          id={`mode-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={80}
          width={80}
          id={`expirationStatus-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={100}
          width={100}
          id={`quoteHistory-loading-${index}`}
        />
      </TableDataCell>
    </TableRowLoading>
  );
};

const QuoteHistoryRowContentLoader = () => {
  return (
    <TableBody data-testid="quote-history-loading-container">
      {Array.from({ length: 8 }).map((_, index) => (
        <LoadingRow key={`loading-row-${nanoid(10)}`} index={index} />
      ))}
    </TableBody>
  );
};

export default QuoteHistoryRowContentLoader;
