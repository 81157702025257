import { useOktaAuth } from '@okta/okta-react';

export const useLogout = () => {
  const { oktaAuth } = useOktaAuth();

  return async () => {
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut();
  };
};
