import React, { useState } from 'react';
import styled from '@emotion/styled';

// @Common components
import { AsyncSearchableDropdown } from 'commonComponents/Dropdowns/SearchableDropdown';

// @Hooks
import { useGetLoadLocation } from 'hooks/useGetLoadLocation';

// @Icons
import Switch from '@arrive/ui-icons/svg/ic_switch.svg';
import ArrowPickup from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';
import ArrowDelivery from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';

// @Types
import { LoadLocation, SendQuoteOptions } from 'types/quoteTypes';

// @Utils
import { onlyAllowZipCode } from 'utils/utils';

const PickupDeliveryContainer = styled.div`
  display: flex;
  min-width: 500px;
  position: relative;
  gap: 16px;

  > div {
    width: 100%;
  }
`;

const StyledButtonSwitch = styled.button<{ swapped: boolean }>`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  top: 8px;
  z-index: 2;
  border: ${(props) => `solid 1px ${props.theme.palette.common.chrome}`};
  border-radius: 50%;
  height: 38px;
  width: 38px;
  background-color: ${(props) => props.theme.palette.common.ethanol};
  fill: ${(props) => props.theme.palette.common.smokey};
  transform: ${({ swapped }) => (swapped ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease-in;

  &:hover {
    fill: ${({ theme }) => theme.palette.common.smokey};
    cursor: pointer;
  }
`;

interface PickupDeliveryProps {
  selectedOptions: SendQuoteOptions;
  setSelectedOptions: (options: SendQuoteOptions) => void;
  dataTestId?: string;
}

const PickupDelivery = ({
  selectedOptions,
  setSelectedOptions,
  dataTestId,
}: PickupDeliveryProps) => {
  const loadLocationOptions = useGetLoadLocation();
  const [locationsSwitched, setLocationsSwitched] = useState(false);
  const [pickupValue, setPickupValue] = useState('');
  const [deliveryValue, setDeliveryValue] = useState('');

  const { pickupLocation, deliveryLocation } = selectedOptions;

  const handlePickupInputChange = (
    value: string | null,
    { action }: { action: string },
  ) => {
    if (action === 'input-change') {
      setPickupValue(onlyAllowZipCode(value));
    }

    if (action === 'menu-close' && !pickupLocation) {
      setPickupValue('');
    }
  };
  const handleDeliveryInputChange = (
    value: string | null,
    { action }: { action: string },
  ) => {
    if (action === 'input-change') {
      setDeliveryValue(onlyAllowZipCode(value));
    }

    if (action === 'menu-close' && !deliveryLocation) {
      setDeliveryValue('');
    }
  };

  const switchLocations = () => {
    setLocationsSwitched(!locationsSwitched);
    setSelectedOptions({
      ...selectedOptions,
      pickupLocation: deliveryLocation,
      deliveryLocation: pickupLocation,
    });
  };

  return (
    <PickupDeliveryContainer>
      <AsyncSearchableDropdown
        dataTestId={`${dataTestId}-pickup`}
        getOptionValue={(option) => option as string}
        getOptionLabel={(option) =>
          `${(option as LoadLocation).CityName}, ${
            (option as LoadLocation).CityState
          } ${(option as LoadLocation).PostalCode}`
        }
        loadOptions={loadLocationOptions}
        placeholder="Pickup ZIP"
        showArrow={false}
        prefix={<ArrowPickup />}
        required
        inputValue={pickupValue}
        value={pickupLocation}
        onChange={(e) => {
          setSelectedOptions({
            ...selectedOptions,
            pickupLocation: e as LoadLocation,
          });
          setPickupValue('');
        }}
        isClearable
        aria-label="Pickup dropdown"
        onInputChange={handlePickupInputChange}
      />
      <StyledButtonSwitch
        data-testid={`${dataTestId}-switch`}
        swapped={locationsSwitched}
        onClick={switchLocations}
      >
        <Switch />
      </StyledButtonSwitch>
      <AsyncSearchableDropdown
        dataTestId={`${dataTestId}-delivery`}
        getOptionValue={(option) => option as string}
        getOptionLabel={(option) =>
          `${(option as LoadLocation).CityName}, ${
            (option as LoadLocation).CityState
          } ${(option as LoadLocation).PostalCode}`
        }
        loadOptions={loadLocationOptions}
        placeholder="Delivery ZIP"
        showArrow={false}
        prefix={<ArrowDelivery />}
        required
        inputValue={deliveryValue}
        value={deliveryLocation}
        onChange={(e) => {
          setSelectedOptions({
            ...selectedOptions,
            deliveryLocation: e as LoadLocation,
          });
          setDeliveryValue('');
        }}
        isClearable
        aria-label="Delivery dropdown"
        onInputChange={handleDeliveryInputChange}
      />
    </PickupDeliveryContainer>
  );
};

export default PickupDelivery;
