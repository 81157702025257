import React from 'react';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common components
import TableErrorMessage from 'commonComponents/Error/TableErrorMessage';
import TablePagination from 'commonComponents/Table/TablePagination';

// @Components
import QuoteHistoryRowContentLoader from './QuoteHistoryRowContentLoader';
import QuoteHistoryRow from './QuoteHistoryRow';

// @Types
import { QuotesStatus } from 'types/quoteTypes';
import { QuoteHistoryTableProps, SingleQuote } from '../QuoteHistory.types';

const QuoteHistoryTable = ({
  quotesData,
  quotesError,
  submitQuote,
  submitDisabled,
  updatedQuotesStatus,
  isLoading,
}: QuoteHistoryTableProps) => {
  if (isLoading) {
    return <QuoteHistoryRowContentLoader />;
  }

  if (!!quotesError || !quotesData) {
    return (
      <TableErrorMessage
        col={9}
        errorTitle="Sorry, there was an error retrieving the quotes."
        errorSubtitle="We recommend waiting a few minutes and trying again."
        dataTestId="quote-history-error"
      />
    );
  }

  if (!quotesData?.quotes?.length) {
    return (
      <TableErrorMessage
        col={9}
        errorTitle="No quotes yet."
        errorSubtitle="Get started with a new quote above."
        dataTestId="no-quotes-view"
      />
    );
  }

  return (
    <>
      <TableBody>
        {quotesData?.quotes.map((row: SingleQuote, index: number) => (
          <QuoteHistoryRow
            key={row.quoteId}
            data={{
              ...row,
              ...(updatedQuotesStatus as QuotesStatus)?.[row.quoteReference],
            }}
            index={index}
            submitQuote={submitQuote}
            submitDisabled={submitDisabled}
          />
        ))}
      </TableBody>
      <TablePagination
        dataTestId="quotes-pagination"
        numberOfColumns={9}
        totalRecords={quotesData?.total}
        itemType="Quotes"
      />
    </>
  );
};

export default QuoteHistoryTable;
