export const NavigationKeys = [
  'Backspace',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
  'ArrowLeft',
  'Enter',
  'Delete',
];
