export const DateSortOptions = [
  {
    label: 'Earliest Pickup Date',
    value: 'EarliestPickup',
  },
  {
    label: 'Latest Pickup Date',
    value: 'LatestPickup',
  },
  {
    label: 'Earliest Delivery Date',
    value: 'EarliestDelivery',
  },
  {
    label: 'Latest Delivery Date',
    value: 'LatestDelivery',
  },
];
