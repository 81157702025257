import React, { Fragment } from 'react';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common Components
import { LinkButton } from 'styles/Links';
import TablePagination from 'commonComponents/Table/TablePagination';
import Typography from 'commonComponents/Typography/Typography';
import TableErrorMessage from 'commonComponents/Error/TableErrorMessage';

// @Components
import ShipmentRow from './ShipmentsRow';

// @Theme
import { fontSizes } from 'theme/Units';

// @Types
import { ShipmentTableProps } from '../ShipmentPage.types';
import { Shipment } from 'types/Shipment.types';

const ShipmentTable = ({
  shipmentsData,
  shipmentsError,
  handleClearAllFilters,
  queryParams,
}: ShipmentTableProps) => {
  const { ReferenceNumber, ...restQs } = queryParams;

  if (!!ReferenceNumber?.length && shipmentsData?.total === 0) {
    return (
      <TableErrorMessage
        errorTitle="Sorry, no shipments with that reference number could be found."
        errorSubtitle="Double check that you entered the exact number or try again."
        buttonTitle="Clear Search"
        handleButtonClick={handleClearAllFilters}
        col={5}
        dataTestId="no-shipments-for-ref-number"
      />
    );
  }

  if (shipmentsData?.total === 0 && Object.values(restQs).length === 0) {
    return (
      <TableErrorMessage
        errorTitle="Sorry, no shipments could be found."
        col={5}
        dataTestId="no-shipments"
      >
        <Typography variant="body1" margin="0" as="div">
          Start a new shipment by getting a{' '}
          <LinkButton
            href="/"
            data-testid="back-to-quotes"
            fontSize={fontSizes.standard}
          >
            quote
          </LinkButton>
          .
        </Typography>
      </TableErrorMessage>
    );
  }

  if (!!Object.keys(restQs).length && shipmentsData?.total === 0) {
    return (
      <TableErrorMessage
        errorTitle="Sorry, no shipments could be found."
        buttonTitle="Clear Search"
        handleButtonClick={handleClearAllFilters}
        col={5}
        dataTestId="no-shipments-for-filters"
      />
    );
  }

  // show filter error view when error happened while fetching data related to filters
  if (shipmentsData?.shipmentsError) {
    return (
      <TableErrorMessage
        errorTitle="Sorry, there was an error retrieving the shipments."
        buttonTitle="Clear your search and try again"
        handleButtonClick={handleClearAllFilters}
        col={5}
        dataTestId="no-shipments-due-to-filter-error"
      />
    );
  }

  if (!!shipmentsError) {
    return (
      <TableErrorMessage
        col={5}
        dataTestId="no-shipments-due-to-error"
        errorTitle="Sorry, there was an error retrieving the shipments."
        errorSubtitle="We recommend waiting a few minutes and trying again."
      />
    );
  }

  return (
    <>
      <TableBody>
        {shipmentsData?.shipments!.map((shipment: Shipment) => (
          <Fragment key={shipment.LoadNumber}>
            <ShipmentRow shipment={shipment} />
          </Fragment>
        ))}
      </TableBody>
      <TablePagination
        dataTestId="shipments-pagination"
        numberOfColumns={5}
        totalRecords={shipmentsData?.total as number}
        itemType="Shipments"
      />
    </>
  );
};

export default ShipmentTable;
