import React, { useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// @Arrive
import Button from '@arrive/button';

// @Components
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';
import DatePicker from 'commonComponents/DateTimePickers/DatePicker';
import EquipmentDropdown from 'commonComponents/Dropdowns/EquipmentDropdown';
import PickupDelivery from 'commonComponents/PickupDelivery/PickupDelivery';
import ToggleButton from 'commonComponents/Buttons/ToggleButton';
import Typography from 'commonComponents/Typography/Typography';

// @Constants
import { EquipmentTypes } from 'constants/EquipmentType';
import { truckOptions } from 'constants/TruckType';

// Dev Data
import { SelectQuoteFormDevData } from 'dev/QuoteFormDevData';

// @Helpers
import { isValidForm, getValidPickupDates } from './QuoteFormHelpers';

// @Types
import { LoadLocation } from 'types/quoteTypes';
import { EquipmentOptionType, SendQuoteOptions } from 'types/quoteTypes.d';

// @Styles
import {
  ButtonsContainer,
  DatesContainer,
  DestinationContainer,
  EQContainer,
  PickupDeliveryContainer,
  QuoteContainer,
} from './QuoteFormStyles';
import { LoadingButton } from 'styles/Buttons';

// @Theme
import theme from 'theme/Theme';

interface QuoteFormProps {
  selectedOptions: SendQuoteOptions;
  setSelectedOptions: (options: SendQuoteOptions) => void;
  handleIsSubmitted: (quoteOptions: SendQuoteOptions) => void;
  isSendingQuoteRequest?: boolean;
  defaultOptions?: SendQuoteOptions | null;
}

const QuoteForm = ({
  handleIsSubmitted,
  isSendingQuoteRequest,
  defaultOptions,
  selectedOptions,
  setSelectedOptions,
}: QuoteFormProps) => {
  const datePickerRef = useRef<HTMLInputElement>(null);

  const {
    truckType,
    equipmentType,
    pickupLocation,
    deliveryLocation,
    pickupDate,
  } = selectedOptions;

  const { enableDevInputs, enableLtlModule } = useFlags();

  useEffect(() => {
    if (defaultOptions) {
      setSelectedOptions(defaultOptions);
      datePickerRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const isFormValid =
    !isSendingQuoteRequest &&
    isValidForm(
      truckType,
      equipmentType,
      pickupLocation,
      deliveryLocation,
      pickupDate,
    );

  const { minimumPickupDate, maximumPickupDate } = getValidPickupDates();

  const handleStateChange = (
    field: keyof SendQuoteOptions,
    value: string | LoadLocation | Date | null,
  ) => {
    setSelectedOptions({ ...selectedOptions, [field]: value });
  };

  return (
    <QuoteContainer>
      <ButtonsContainer>
        <ToggleButton
          leftButtonOptions={truckOptions['FTL']}
          rightButtonOptions={{
            ...truckOptions['LTL'],
            isDisabled: !enableLtlModule,
          }}
          onClick={(e) =>
            handleStateChange(
              'truckType',
              (e.currentTarget as HTMLInputElement).value,
            )
          }
          selectedValue={truckType}
        />
        <EQContainer>
          <EquipmentDropdown
            value={equipmentType ? EquipmentTypes[equipmentType] : null}
            blackIcons
            onChange={(e) =>
              handleStateChange(
                'equipmentType',
                (e as EquipmentOptionType).value,
              )
            }
          />
        </EQContainer>
      </ButtonsContainer>
      <PickupDeliveryContainer>
        <DestinationContainer>
          <PickupDelivery
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            dataTestId="quotes-form"
          />
        </DestinationContainer>
        <DatesContainer>
          <DatePicker
            required
            placeholderText="Pickup Date"
            onChange={(date: Date) => {
              handleStateChange('pickupDate', date);
            }}
            selected={selectedOptions.pickupDate}
            minDate={minimumPickupDate}
            maxDate={maximumPickupDate}
            datePickerRef={datePickerRef}
          />
        </DatesContainer>
        <LoadingButton
          variant="primary"
          size="lg"
          disabled={!isFormValid}
          onClick={() => handleIsSubmitted(selectedOptions)}
          data-testid="quote-form-submit-button"
        >
          {isSendingQuoteRequest && <SpinningLoadingIndicator />}
          <Typography
            margin={`0 ${isSendingQuoteRequest && theme.spacing.normal} 0 0`}
            variant="body2"
            color={
              isFormValid
                ? theme.palette.common.ethanol
                : theme.palette.common.chrome
            }
          >
            Get A Quote
          </Typography>
        </LoadingButton>
        {
          /* istanbul ignore next */
          enableDevInputs && (
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => {
                const today = new Date();
                setSelectedOptions({
                  ...SelectQuoteFormDevData,
                  pickupDate: new Date(today.setDate(today.getDate() + 3)),
                });
              }}
            >
              Auto Populate
            </Button>
          )
          /* istanbul ignore end */
        }
      </PickupDeliveryContainer>
    </QuoteContainer>
  );
};

export default QuoteForm;
