import React, { ReactElement } from 'react';

// @Components
import { ShipmentDetailsShipmentSummaryContentLoader } from './ShipmentDetailsContentLoaders';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Icon
import MILES_ICON from '@arrive/ui-icons/svg/ic_miles.svg';
import FTL_ICON from '@arrive/ui-icons/svg/ic_drop_trailer.svg';
import REFRIGERATED_ICON from '@arrive/ui-icons/svg/ic_dry_van.svg';

// @Styles
import { palette } from 'theme/Theme';
import {
  ShipmentIconsContainer,
  ShipmentReferenceNumbersContainer,
} from './ShipmentDetailsPanelStyles';

// @Types
import {
  ShipmentDetailsReferenceNumber,
  ShipmentDetailsReferenceNumbersProps,
  ShipmentDetailsShipmentSummaryProps,
} from 'components/Shipments/ShipmentPage.types';

const ShipmentDetailsReferenceNumbers = ({
  referenceNumbers,
}: ShipmentDetailsReferenceNumbersProps): ReactElement => {
  const refNumbers = referenceNumbers?.length
    ? referenceNumbers.map((refNumber: ShipmentDetailsReferenceNumber) => {
        return `${refNumber.Description} ${refNumber.ReferenceNumber}`;
      })
    : [];
  return (
    <ShipmentReferenceNumbersContainer>
      <Typography variant="overline" color={palette.common.smokey} margin="0">
        Reference #s
      </Typography>
      <Typography
        variant="body2"
        data-testid="shipment-details-reference-numbers"
      >
        {refNumbers.length
          ? refNumbers.join(', ')
          : 'No related reference numbers found.'}
      </Typography>
    </ShipmentReferenceNumbersContainer>
  );
};

const ShipmentDetailsShipmentSummary = ({
  equipmentType,
  isLoading,
  miles,
  mode,
  referenceNumbers,
}: ShipmentDetailsShipmentSummaryProps): ReactElement => {
  if (isLoading) {
    return <ShipmentDetailsShipmentSummaryContentLoader />;
  }

  return (
    <>
      <ShipmentDetailsReferenceNumbers referenceNumbers={referenceNumbers} />
      <ShipmentIconsContainer>
        {miles ? (
          <div>
            <MILES_ICON />
            <Typography variant="body2" data-testid="shipment-details-miles">
              {miles} miles
            </Typography>
          </div>
        ) : null}
        <div>
          <FTL_ICON />
          <Typography variant="body2" data-testid="shipment-details-mode">
            {mode}
          </Typography>
        </div>
        <div>
          <REFRIGERATED_ICON />
          <Typography
            variant="body2"
            data-testid="shipment-details-equipment-types"
          >
            {equipmentType}
          </Typography>
        </div>
      </ShipmentIconsContainer>
    </>
  );
};

export default ShipmentDetailsShipmentSummary;
