import { format, addHours } from 'date-fns';

// @Types
import { PickupDeliveryType, ShipmentDetails } from 'types/Shipment.types';

export const PickupDeliveryFormDevData: PickupDeliveryType = {
  appointment: {
    hasAppointment: false,
    appointmentDate: 1676583795,
  },
  address1: '2010 West Braker Ln',
  address2: null,
  cityState: 'Austin,  TX',
  facilityName: 'My Facility',
  facilityClose: format(addHours(new Date(), 1), 'HH:mm'),
  facilityOpen: format(new Date(), 'HH:mm'),
  notes:
    'Lacus aliquet turpis lectus pulvinar. Elementum volutpat nunc donec sapien nullam ut sed. Sed nisl, integer libero tellus.',
  postalCode: '73301',
  referenceNumbers: {
    ABC_13: {
      type: {
        enumValue: 'BOL_NUMBER',
        displayName: 'BOL #',
        displayOrder: null,
      },
      number: '12',
    },
  },
  schedulingContact: {
    fullName: null,
    phoneNumber: null,
    email: null,
  },
  schedulingType: 'APPOINTMENT',
  stopType: 'Pickup',
  facilityId: 340981,
  isValidatedLocation: false,
};

export const LoadFormDevData: Partial<ShipmentDetails> = {
  commodityName: 'Beer',
  pieceUnit: 'PALLETS',
  pieceCount: 14,
  weightLbs: 15000,
  cargoValue: '50,000',
  maxTempF: '10',
  minTempF: '0',
  preCoolTempF: '0',
  loadComments:
    'Lacus aliquet turpis lectus pulvinar. Elementum volutpat nunc donec sapien nullam ut sed. Sed nisl, integer libero tellus.',
  externalShipmentId: '123',
};
