export const AuthEndpoint = {
  auth: {
    logout: `/auth/logout`,
    refresh: `/auth/refresh`,
    redirect: `/`,
  },
};

export const LoadServiceEndpoints = {
  getLocations: (input: string): string =>
    `${process.env.LOAD_SERVICE_ROOT}Locations/GetBySearch?isOnBlur=false&isTruckSearchLocation=false&searchInput=${input}`,
};

export const QuoteEndpoints = {
  rateRequestShipperPortal: `${process.env.QUOTE_SVC2_ROOT}CustomerRating/RateRequest/ShipperPortal`,
};

export const IPServiceEndpoints = {
  getQuoteHistory: `${process.env.IP_SERVICE_ROOT}Quotes/ShipperPortal`,
};

export const CRMEndpoints = {
  getCustomerOverview: (customerId: number): string =>
    `${process.env.CRM_SERVICE_ROOT}customer/overviewById/${customerId}`,
  getCustomerReferenceNumbers: (): string =>
    `${process.env.CRM_SERVICE_ROOT}customer/scopeTypes`,
};

export const MapEndpoints = {
  getRoutes: `${process.env.ALK_MAPS_ROOT}route/routePath`,
};

export const ShipyardEndpoints = {
  address: {
    validatePhoneNumber: (phoneNumber: string): string =>
      `${process.env.SHIPYARD_SERVICE_ROOT}address/validatePhoneNumber?phoneNumber=${phoneNumber}`,
    getTimezones: (cityStateCodes: string): string =>
      `${process.env.SHIPYARD_SERVICE_ROOT}address/getTimezonesByCityStateCodes?${cityStateCodes}`,
  },
  quote: {
    checkAcceptance: `${process.env.SHIPYARD_SERVICE_ROOT}quote/checkQuoteAcceptance`,
  },
  shipment: {
    createShipment: `${process.env.SHIPYARD_SERVICE_ROOT}shipment`,
    getShipment: (id: string): string =>
      `${process.env.SHIPYARD_SERVICE_ROOT}shipment/${id}`,
    getShipmentFormEnums: `${process.env.SHIPYARD_SERVICE_ROOT}shipment/shipmentFormEnums`,
  },
};

export const AccountEndpoints = {
  getUserInformation: `${process.env.ACCOUNT_SERVICE_ROOT}users/GetShipperPortalUserInfoByAuthToken`,
  saveAgreement: `${process.env.ACCOUNT_SERVICE_ROOT}users/SaveShipperPortalAgreementByAuthToken`,
};

export const FacilityEndpoints = {
  getFacilities: `${process.env.FACILITY_SERVICE_ROOT}Facilities/GetFacilitiesByShipperPortalCustomerId`,
};

export const LoadOverviewServiceEndpoints = {
  getShipmentDetailsById: (loadNumber: string): string =>
    `${process.env.LOAD_OVERVIEW_SERVICE_ROOT}Loads/GetShipperPortalLoadDtoByLoadNumber?loadNumber=${loadNumber}`,
  getShipments: `${process.env.LOAD_OVERVIEW_SERVICE_ROOT}Loads/ShipperPortalLoadsByAuthToken`,
  getShipmentFilterEnums: `${process.env.LOAD_OVERVIEW_SERVICE_ROOT}Loads/GetShipperPortalFilterEnums`,
  getInvoices: `${process.env.LOAD_OVERVIEW_SERVICE_ROOT}Loads/ShipperPortalLoadHistoryByAuthToken`,
};

export const TrackingServiceEndpoints = {
  getTrackingDetailsByLoadNumber: (loadNumber: string): string =>
    `${process.env.TRACKING_SERVICE_ROOT}Activities/GetByLoadNumberShipperPortal?loadNumber=${loadNumber}`,
};

export const LocationServiceEndpoints = {
  getSuggestions: `${process.env.LOCATION_SERVICE_ROOT}addresses?countries=USA&`,
};
