import React, { ReactElement, useState } from 'react';

// @Arrive
import AddIcon from '@arrive/ui-icons/svg/ic_plus.svg';

// @Configs
import SearchableDropdown, { overrideComponents } from './SearchableDropdown';

// @Styles
import { AddNewOption, NoOptionsMessage } from './DropdownStyles';

//@Types
import { AddNewOptionSearchableDropdownProps } from './DropdownTypes.types';

const MenuList = (props: {
  children: React.ReactNode;
  addNewOptionString: string;
  addNewOptionOnClick: React.MouseEventHandler<HTMLSpanElement>;
}): React.ReactElement => {
  return (
    <div data-testid="menu-list-override">
      {props.children}
      <AddNewOption role="button" onClick={props.addNewOptionOnClick}>
        <AddIcon />
        {props.addNewOptionString}
      </AddNewOption>
    </div>
  );
};

const AddNewOptionDropdown = ({
  addNewOptionString = 'Add New Option',
  addNewOptionOnClick,
  noOptionMessageString = 'No Options',
  ...props
}: AddNewOptionSearchableDropdownProps): ReactElement => {
  const [showMenu, setShowMenu] = useState(false);

  const getMenuList = (menuListProps: any) => (
    <MenuList
      addNewOptionString={addNewOptionString}
      addNewOptionOnClick={addNewOptionOnClick}
      {...menuListProps}
    />
  );

  const getOptionsMessage = () => (
    <NoOptionsMessage>{noOptionMessageString}</NoOptionsMessage>
  );

  return (
    <div
      role="button"
      onClick={() => {
        return setShowMenu(!showMenu);
      }}
      data-testid="add-new-dropdown-container"
    >
      <SearchableDropdown
        {...props}
        noOptionsMessage={getOptionsMessage}
        components={{
          ...overrideComponents(props.getOptionSubtext),
          MenuList: getMenuList,
          IndicatorSeparator: () => null,
        }}
        onBlur={() => {
          return setShowMenu(false);
        }}
      />
    </div>
  );
};

export default AddNewOptionDropdown;
