export default {
  DEFAULT: '/',
  LOGIN_CALLBACK: '/login/callback',
  LOGIN: '/login',
  LOGOUT: '/logout',
  QUOTES: '/quotes',
  SHIPMENTFORM: '/shipment-form/:quoteId',
  SHIPMENTCONFIRMATION: '/shipment-confirmation/:shipmentId',
  SHIPMENTHISTORY: '/shipment-history',
  INVOICES: '/invoices',
  SHIPMENTS: '/shipments',
};
