import React from 'react';
import styled from '@emotion/styled';

// @Arrive
import Button from '@arrive/button';

// @Common components
import Typography from 'commonComponents/Typography/Typography';

// @Types
import { ErrorMessageProps } from './Error.types';

const ErrorMessageWrapper = styled.div`
  text-align: center;
`;

const ErrorMessage = ({
  buttonTitle,
  children,
  dataTestId,
  errorSubtitle,
  errorTitle,
  handleButtonClick,
}: ErrorMessageProps) => {
  return (
    <ErrorMessageWrapper>
      <Typography variant="h2" as="div">
        {errorTitle}
      </Typography>
      <Typography variant="body1" margin="0" as="div">
        {errorSubtitle}
      </Typography>
      {buttonTitle && (
        <Button
          text
          data-testid={`${dataTestId}-clear-button`}
          onClick={handleButtonClick}
        >
          {buttonTitle}
        </Button>
      )}
      {children}
    </ErrorMessageWrapper>
  );
};

export default ErrorMessage;
