import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import styled from '@emotion/styled';

// @Common Components
import Checkbox from '@arrive/checkbox';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import ButtonFooter from './ButtonFooter';

// @Hooks
import { useLogout } from 'hooks/useLogout';
import { useUpdateTermsAcceptance } from 'hooks/useTermsAcceptance';

// @Theme
import { palette, sectionSettings } from 'theme/Theme';
import { spacing } from 'theme/Units';

// @Types
import { ShipperPortalUserInformation } from 'types/User.types';

const TermsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 660px;
  height: 100vh;
  margin: ${`${spacing.large} auto 0px auto`};
`;

const TermsScrollContainer = styled.div`
  margin: ${spacing.normal} 0;
  > iframe {
    height: 500px;
    width: 100%;
  }
`;

const Terms = () => {
  const [termsChecked, setTermsChecked] = useState(false);
  const handleLogout = useLogout();

  const { mutate: updateTerms } = useUpdateTermsAcceptance();

  const queryClient = useQueryClient();
  const shipperPortalUserInfo = queryClient.getQueryData([
    'shipperPortalUserInfo',
  ]) as ShipperPortalUserInformation;

  if (!shipperPortalUserInfo) {
    return <div data-testid="no-terms" />;
  }

  return (
    <>
      <TermsSection>
        <Typography variant="h1" padding={`${sectionSettings.title} 0px`}>
          Terms of Use
        </Typography>
        <Typography>
          Prior to using our portal, please review our Terms of Use by scrolling
          through the information below. Once you have read and agree to the
          terms, you will be granted access.
        </Typography>
        <TermsScrollContainer>
          <iframe
            title="Shipper portal terms of use"
            src="https://legal-terms-conditions.azureedge.net/legal/Arrive%20Logistics_Website_Terms_of_Use_9_13_2022.pdf"
            id="scroll-div"
          ></iframe>
        </TermsScrollContainer>
        <Typography color={palette.common.smokey} fontSize="14px">
          By clicking &apos;Agree&apos;, I have read, understood, and agree to
          the Terms of Use and Privacy Policy and I agree to receive emails and
          customer service communications from Arrive Logistics. I can
          unsubscribe at any time by clicking &apos;unsubscribe&apos; in emails.
        </Typography>
        <Checkbox
          checked={termsChecked}
          label="I have read and agree to the Terms of Use."
          labelDirection="right"
          onChange={() => setTermsChecked(!termsChecked)}
        />
        <ButtonFooter
          primaryButtonText="Agree"
          secondaryButtonText="Decline and logout"
          handlePrimaryButton={updateTerms}
          handleSecondaryButton={handleLogout}
          isEnabled={termsChecked}
        />
      </TermsSection>
    </>
  );
};

export default Terms;
