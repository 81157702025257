import { Dispatch } from 'react';
import { ParsedQs } from 'qs';

// @Types
import {
  Shipment,
  Commodity,
  StatusEnumFormat,
  StopType,
} from 'types/Shipment.types';

export type ShipmentRowProps = {
  shipment: Shipment;
};

export type ShipmentDetailsPanelHeaderProps = {
  earliestTime: number | undefined;
  handleCloseShipmentDetails: () => void;
  isLoading: boolean;
  latestTime: number | undefined;
  loadNumber: number;
  status: string | undefined;
  timeZone: string | undefined;
};

export type ShipmentPickupDeliveryInformationProps = {
  pickupDetails: ShipmentDetailsPickupDeliveryInfo | undefined;
  deliveryDetails: ShipmentDetailsPickupDeliveryInfo | undefined;
  isLoading: boolean;
};

export type ShipmentDetailsShipmentSummaryProps = {
  equipmentType: string | undefined | null;
  isLoading: boolean;
  miles: string | undefined | null;
  mode: string | undefined | null;
  referenceNumbers: ShipmentDetailsReferenceNumber[] | [] | undefined;
};

export type ShipmentDetailsReferenceNumbersProps = {
  referenceNumbers: ShipmentDetailsReferenceNumber[] | [] | undefined;
};

export type ShipmentDetailsReferenceNumber = {
  ReferenceNumber: string;
  Description: string;
};

export type ShipmentDetailsPickupDeliveryInfo = {
  ReferenceNumbers: ShipmentDetailsReferenceNumber[];
  Address1: string;
  Address2: null | string;
  Commodities: Commodity[];
  CityState: string;
  EarliestTime: number | undefined;
  LatestTime: number | undefined;
  TimeZoneIANA: string;
  Status: string | null;
  SequenceNumber: number;
  StopType: StopType;
  SchedulingType: 'Appointment' | 'FCFS';
  PostalCode: string | null;
};

export type ShipmentDetailsByLoadNumber = {
  EquipmentType: string;
  IsHazmat: boolean;
  IsTeam: boolean;
  LoadNumber: number;
  Mode: string;
  Rate: string | null;
  ReferenceNumbers: ShipmentDetailsReferenceNumber[];
  Status: string;
  Stops: Record<StopType, ShipmentDetailsPickupDeliveryInfo[]>;
  TotalMiles: string | null;
  Weight: string | null;
  CargoValue: string | null;
};

export type ShipmentDetailsTrackingInfo = {
  CheckCallType: string | null;
  EventDate: string | null;
  City: string | null;
  StateCode: string | null;
  PostalCode: number | null;
  Country: string | null;
  StopSequenceNumber: number | null;
  IanaTimeZone: string | null;
  id?: string; // generated by nanoid
};

export type ShipmentFiltersProps = {
  handleClearAllFilters: () => void;
  state: ShipmentFilterState;
  dispatch: Dispatch<ShipmentFiltersReducerAction>;
  totalShipments: number;
  queryParams: {
    [key: string]: string | string[] | ParsedQs | ParsedQs[] | undefined;
  };
};

export type ShipmentTableFilterOptions = {
  DeliveryDate: string;
  DeliveryDateEnd: string;
  OrderByColumn: string;
  PickupDate: string;
  PickupDateEnd: string;
  ReferenceNumber: string;
  Status: string;
};

export type ShipmentReferenceNumberSearchProps = {
  searchValue: string;
  dispatch: Dispatch<ShipmentFiltersReducerAction>;
  handleApplyFilters: (
    filterOption: keyof ShipmentTableFilterOptions,
    value: string,
  ) => void;
};

export type ShipmentTableProps = {
  shipmentsData:
    | {
        shipments: [] | Shipment[] | undefined;
        shipmentsError?: boolean | undefined;
        total: number | undefined;
      }
    | undefined;
  shipmentsError?: unknown;
  handleClearAllFilters: () => void;
  queryParams: {
    [key: string]: string | string[] | ParsedQs | ParsedQs[] | undefined;
  };
};

export type ShipmentFilterState = {
  Statuses: StatusEnumFormat[];
  ReferenceNumber: string;
  PickupDate: null | Date;
  PickupDateEnd: null | Date;
  DeliveryDate: null | Date;
  DeliveryDateEnd: null | Date;
};

export enum ShipmentFiltersActionKind {
  SET_REFERENCE_NUMBER = 'SET_REFERENCE_NUMBER',
  SET_STATUSES = 'SET_STATUSES',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  RESET_STATE = 'RESET_STATE',
  SET_DATES = 'SET_DATES',
  APPLY_FILTERS = 'APPLY_FILTERS',
}

export type ShipmentFiltersReducerAction = {
  type: ShipmentFiltersActionKind;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type ShipmentStatusFilterProps = {
  dispatch: Dispatch<ShipmentFiltersReducerAction>;
  statuses: [] | StatusEnumFormat[];
};

export type DateFiltersType = { date: Date | null; dateEnd: Date | null };

export type ShipmentDateFiltersProps = {
  stopType: 'Pickup' | 'Delivery';
  dateFilters: DateFiltersType;
  dispatch: Dispatch<ShipmentFiltersReducerAction>;
};

export type ShipmentTabShipmentSummaryProps =
  Partial<ShipmentDetailsByLoadNumber>;

export type ShipmentDetailsStopInformationProps = {
  stopInformation: Record<StopType, ShipmentDetailsPickupDeliveryInfo[]>;
};

export type ShipmentsTrackingDataType = {
  trackingData: ShipmentDetailsTrackingInfo[] | [];
  totalCount: number;
};
