import styled from '@emotion/styled';
import ArriveTextEntry from '@arrive/text-entry';

// @Styles
import { spacing } from 'theme/Units';

export const ReadOutContainer = styled.div`
  margin: ${`${spacing.xsmall} ${spacing.none}`};

  > div {
    margin-top: ${spacing.gutter};
  }
`;

export const CityContainer = styled.div`
  display: flex;
  & > div:first-of-type {
    width: 318px;
    margin-right: ${spacing.gutter};
  }

  & > div:nth-of-type(2) {
    width: 132px;
    margin-right: ${spacing.large};
  }

  & > div:last-of-type {
    width: 162px;
  }
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 318px;
  }

  & .arrive_TextEntry__suffix {
    border-left: none;
  }
  & .arrive_TextEntry__error {
    display: none;
  }
`;

export const FacilityHoursContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 412px;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: ${spacing.gutter};
`;

export const AppointmentsSection = styled.div`
  display: flex;
  flex-direction: column;

  label {
    ::before {
      margin: 0 ${spacing.xsmall} 0 0 !important;
    }
  }
`;

export const TimePickerContainer = styled.div`
  display: flex;
`;

export const SchedulingContainer = styled.div<{ showDateRange: boolean }>`
  margin-top: ${spacing.normal};
  display: flex;
  flex-direction: ${({ showDateRange }) => (showDateRange ? 'column' : 'row')};
  gap: ${spacing.gutter};
  > div {
    width: 228px;
  }
`;

export const AddNewFacilityHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 0 !important;
`;

export const LoadingSpinnerContainer = styled.div`
  margin-top: 6px;
`;

export const TextEntry = styled(ArriveTextEntry)`
  svg {
    display: none;
  }
`;
