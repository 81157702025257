import {
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import qs from 'qs';

// @Endpoints
import { LoadOverviewServiceEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from 'hooks/useAxios';
import { useQueryParams } from 'hooks/useReactRouterQueries';

// @Types
import { Invoice } from 'types/Shipment.types';
import { UserLoginInformation } from 'types/User.types';

export const useGetInvoices = (): UseQueryResult<{
  invoices: Invoice[] | [];
  invoiceError?: boolean;
  total: number;
}> => {
  const axios = useAxios();
  const {
    OrderByColumn,
    pageNumber: PageNumber,
    pageSize: PageSize,
    ReferenceNumber,
  } = useQueryParams();

  const queryStrings = qs.stringify({
    OrderByColumn,
    PageNumber,
    PageSize,
    ReferenceNumber,
  });

  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData([
    'currentUser',
  ]) as UserLoginInformation;

  const fetchInvoices = async () => {
    const { data } = await axios.get(
      `${LoadOverviewServiceEndpoints.getInvoices}?${queryStrings}&customerId=${currentUser.customerId}`,
    );

    return {
      invoices: data.Result ? data.Result.Results : [],
      invoiceError: !!(data.Errors && Object.keys(data.Errors).length),
      total: data.Result ? data.Result.TotalRecordCount : 0,
    };
  };

  return useQuery(
    ['invoices', OrderByColumn, PageNumber, PageSize, ReferenceNumber],
    fetchInvoices,
  );
};
