import { format } from 'date-fns-tz';
import { DateTime } from 'luxon';

// @Types
import { QuoteHistoryResponse, SingleQuote } from './QuoteHistory.types';

export const getLocationString = (
  city?: string | null,
  state?: string | null,
) => {
  return !!city?.length && !!state?.length
    ? `${city}, ${state}`
    : `${city ?? ''}${state ?? ''}`;
};

export const transformEquipmentType = (equipmentType?: string) => {
  if (equipmentType === 'Dry Van') {
    return 'V';
  } else if (equipmentType === 'Reefer') {
    return 'R';
  } else {
    return null;
  }
};

export const transformQuoteResult = (
  quote: QuoteHistoryResponse,
): SingleQuote => {
  return {
    deliveryLocation: getLocationString(
      quote.deliveryCity,
      quote.deliveryState,
    ),
    deliveryPostalCode: quote.deliveryPostalCode,
    equipmentType: transformEquipmentType(quote.equipmentType),
    // This is currently hardcoded because we dont have a real LTL option
    mode: 'FTL',
    pickupDate: DateTime.fromISO(quote.pickupDate)
      .setZone('America/Chicago')
      .toFormat('MMMM dd, yyyy'),
    pickupLocation: getLocationString(quote.pickupCity, quote.pickupState),
    pickupPostalCode: quote.pickupPostalCode,
    quoteDate: format(new Date(quote.createdOn), 'MMMM dd, yyyy'),
    quoteId: quote.quoteId,
    quoteReference: quote.quoteId,
    rate: Math.floor(quote.totalRate),
    totalMiles: quote.totalMiles,
  } as SingleQuote;
};
