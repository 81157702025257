import React from 'react';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Icons
import DESTINATION_ICON from '@arrive/ui-icons/svg/ic_location.svg';

// @Styles
import { palette } from 'theme/Theme';
import {
  LocationContainer,
  LocationDetails,
} from '../ShipmentDetailsPanelTabStyles';

// @Types
import { ShipmentDetailsTrackingInfo } from 'components/Shipments/ShipmentPage.types';

// @Utils
import { getDateStampForGivenIanaTimeZoneAndFormat } from 'utils/utils';

const ShipmentDetailsLastDestination = ({
  lastDestination,
}: {
  lastDestination: ShipmentDetailsTrackingInfo | undefined;
}) => {
  if (!lastDestination) {
    return <></>;
  }
  return (
    <LocationContainer>
      <LocationDetails data-testid="last-destination-location">
        <DESTINATION_ICON />
        <Typography variant="subtitle1">
          Last in {lastDestination?.City}, {lastDestination?.StateCode}{' '}
          {lastDestination?.PostalCode}
        </Typography>
      </LocationDetails>
      {lastDestination?.EventDate ? (
        <Typography
          variant="body2"
          color={palette.common.smokey}
          data-testid="last-destination-date"
        >
          {getDateStampForGivenIanaTimeZoneAndFormat(
            lastDestination?.EventDate,
            'ccc, MMM dd, HH:mm ZZZZ',
            lastDestination?.IanaTimeZone,
          )}
        </Typography>
      ) : null}
    </LocationContainer>
  );
};

export default ShipmentDetailsLastDestination;
