import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

// @Statics
import { axiosInstance } from 'api/axios';
import { InternalAxiosRequestConfig } from 'axios';

const useAxios = () => {
  const auth = useOktaAuth();

  useEffect(() => {
    /* attach request interceptor */
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const interceptedConfig = config;
        if (auth?.authState?.isAuthenticated) {
          const accessToken = auth?.oktaAuth.getAccessToken();
          //if authorization header does not exist that means this is not a retry, it is the first attempt at making the request
          interceptedConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return interceptedConfig;
      },
      (error) => Promise.reject(error),
    );

    /* attach response interceptor */
    const responseIntercept = axiosInstance.interceptors.response.use(
      // if response is valid return the response
      (response) => {
        return response;
      },
      // if the access token is expired refresh the token
      async (error) => {
        const prevRequest = error?.config;
        // check request is failing due to expired token and if sent does not exist
        // we need to retry only once and not get stuck in endless loop of 403
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = auth?.oktaAuth.getAccessToken();
          prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(prevRequest);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      // clean up the interceptors
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [auth?.authState?.isAuthenticated, auth?.oktaAuth]);

  return axiosInstance;
};

export default useAxios;
