import React, { ReactElement } from 'react';
import { useQueryClient, QueryClient } from '@tanstack/react-query';

//@arrive

// @CommonComponents
import SearchableDropdown from 'commonComponents/Dropdowns/SearchableDropdown';

// @Styles
import { FormContainer, ShipmentFormSubHeading } from '../ShipmentFormStyles';
import { CargoContainer } from './LoadFormStyles';
import { TextEntry } from '../PickupDeliveryForm/PickupDeliveryFormStyles';

// @Types
import { LoadFormSectionProps } from '../ShipmentForm.types';
import {
  PieceUnits,
  ShipmentDetails,
  ShipmentFormEnums,
  ShipyardEnumFormat,
} from 'types/Shipment.types';

// @Utils
import { onlyAllowNumbers } from 'utils/utils';

const Items = ({
  commodityName,
  handleShipmentFormInputChange,
  pieceCount,
  pieceUnit,
  weightLbs,
}: LoadFormSectionProps): ReactElement => {
  const queryClient: QueryClient = useQueryClient();
  const ShipmentFormEnums = queryClient.getQueryData([
    'shipmentFormEnums',
  ]) as ShipmentFormEnums;

  const handleShipmentFormNumberInputChange = (
    label: keyof ShipmentDetails,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const onlyNumbersValue = parseInt(onlyAllowNumbers(e.target.value), 10);

    const isValidNumber = !!onlyNumbersValue && !isNaN(onlyNumbersValue);

    handleShipmentFormInputChange(
      label,
      isValidNumber ? onlyNumbersValue : undefined,
    );
  };

  return (
    <FormContainer>
      <ShipmentFormSubHeading>Items</ShipmentFormSubHeading>
      <TextEntry
        data-testid="shipment-form-commodity"
        label="Commodity Description"
        maxLength={250}
        name="commodityName"
        onChange={(e) =>
          handleShipmentFormInputChange('commodityName', e.target.value)
        }
        required
        value={commodityName || ''}
      />
      <CargoContainer>
        <TextEntry
          data-testid="shipment-form-total-pieces"
          errorText={
            !!pieceCount && pieceCount > 1000 && 'Max piece count is 1000.'
          }
          label="Total Pieces"
          name="pieceCount"
          onChange={(e) => handleShipmentFormNumberInputChange('pieceCount', e)}
          required
          value={!!pieceCount ? pieceCount : ''}
        />
        <SearchableDropdown
          aria-label="packaging"
          dataTestId="packaging-type"
          getOptionLabel={(option) =>
            (option as ShipyardEnumFormat).displayName
          }
          getOptionValue={(option) => (option as ShipyardEnumFormat).enumValue}
          isClearable
          name="pieceUnit"
          onChange={(e) =>
            handleShipmentFormInputChange(
              'pieceUnit',
              (e as ShipyardEnumFormat)?.enumValue,
            )
          }
          options={(ShipmentFormEnums?.pieceUnits as PieceUnits) || []}
          placeholder="Packaging Type"
          required
          value={
            ShipmentFormEnums?.pieceUnits.find(
              (unit) => unit.enumValue === pieceUnit,
            ) || null
          }
        />
        <TextEntry
          aria-label="Total Weight"
          data-testid="shipment-form-total-weight"
          errorText={
            !!weightLbs && weightLbs > 47000 && 'Max weight is 47,000 lbs.'
          }
          label="Total Weight (lbs)"
          name="weightLbs"
          onChange={(e) => handleShipmentFormNumberInputChange('weightLbs', e)}
          required
          value={!!weightLbs ? weightLbs : ''}
        />
      </CargoContainer>
    </FormContainer>
  );
};

export default Items;
