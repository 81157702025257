import React from 'react';
import styled from '@emotion/styled';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Theme
import { spacing } from 'theme/Units';

export const TitleDetailsContainer = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  min-width: 200px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface TitleDetailsProps {
    title: string;
    details: string[] | string;
}

const TitleDetails = ({
    title,
    details
}: TitleDetailsProps) => {

    const renderDetails = () => {
        if (typeof details === "string") {
            return <Typography margin="0px">{details}</Typography>;
        }
        if (Array.isArray(details) && !!details?.length) {
            return details.map((detail, index) => !!detail?.length && <Typography key={detail} margin="0px">{detail}</Typography>)
        }
    }

    return (
        <TitleDetailsContainer>
            <TitleContainer>
                <Typography variant="subtitle1" margin={`${spacing.xxsmall} ${spacing.none}`}>
                    {title}
                </Typography>
            </TitleContainer>
            <DetailsContainer>
                {renderDetails()}
            </DetailsContainer>
        </TitleDetailsContainer>
    );
};

export default TitleDetails;
