import { useQuery, UseQueryResult } from '@tanstack/react-query';

// @Api
import { LoadOverviewServiceEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from './useAxios';

// @Types
import { StatusEnumFormat } from 'types/Shipment.types';

export const useGetShipmentFilterEnums = (): UseQueryResult<
  Array<StatusEnumFormat>
> => {
  const axios = useAxios();
  const fetchFilterEnums = async () => {
    const { data } = await axios.get(
      LoadOverviewServiceEndpoints.getShipmentFilterEnums,
    );

    return data.Result.StatusTypes;
  };
  return useQuery(['shipmentsFilterEnums'], fetchFilterEnums, {
    useErrorBoundary: true,
  });
};
