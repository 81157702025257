import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

// @Api
import { ShipyardEndpoints } from 'api/endpoints';

// @Helpers
import {
  getExpirationStatus,
  convertMinutesToTimeLeft,
} from 'utils/ExpirationMessageUtils';

// @Hooks
import useAxios from 'hooks/useAxios';

// @Types
import {
  QuoteHistoryType,
  QuotesStatus,
  QuoteStatus,
  CheckAcceptance,
} from 'types/quoteTypes';
import { PaginationOptions } from 'types/pagination';

export const useGetQuoteHistoryStatus = ({
  pageNumber,
  pageSize,
}: PaginationOptions): UseQueryResult<QuotesStatus> => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const quoteHistory: QuoteHistoryType | undefined = queryClient.getQueryData([
    'quoteHistory',
    pageNumber,
    pageSize,
  ]);

  const quoteReferenceList =
    quoteHistory?.quotes.map((quote) => quote.quoteReference) || [];

  const updatedQuoteStatus = (quotesStatus: CheckAcceptance): QuotesStatus => {
    return Object.keys(quotesStatus).reduce((acc, quoteReference) => {
      const { accepted, shipmentId, minutesUntilQuoteExpires } =
        quotesStatus[quoteReference];
      const expirationStatus = getExpirationStatus(
        minutesUntilQuoteExpires,
        accepted,
      );
      const timeLeftUntilQuoteExpires = convertMinutesToTimeLeft(
        minutesUntilQuoteExpires,
      );
      acc[quoteReference] = {
        accepted,
        shipmentId,
        expirationStatus,
        timeLeftUntilQuoteExpires,
      };
      return acc;
    }, {} as QuotesStatus);
  };

  const fetchCheckQuoteAcceptance = async () => {
    const { data } = await axios.post(
      ShipyardEndpoints.quote.checkAcceptance,
      quoteReferenceList,
    );
    return data;
  };

  return useQuery(
    ['quoteStatus', pageNumber, pageSize],
    fetchCheckQuoteAcceptance,
    {
      enabled: !!quoteHistory,
      select: (resp) => updatedQuoteStatus(resp),
    },
  );
};

export const useGetQuoteResultStatus = (
  quoteReference: string,
): UseQueryResult<QuoteStatus> => {
  const axios = useAxios();

  const fetchCheckQuoteAcceptance = async () => {
    const { data } = await axios.post(ShipyardEndpoints.quote.checkAcceptance, [
      quoteReference,
    ]);

    return data;
  };
  return useQuery(['quoteStatus', quoteReference], fetchCheckQuoteAcceptance, {
    enabled: !!quoteReference,
    select: (resp) => {
      return {
        accepted: resp[quoteReference].accepted,
        shipmentId: resp[quoteReference].shipmentId,
        expirationStatus: getExpirationStatus(
          resp[quoteReference].minutesUntilQuoteExpires,
          resp[quoteReference].accepted,
        ),
        timeLeftUntilQuoteExpires: convertMinutesToTimeLeft(
          resp[quoteReference].minutesUntilQuoteExpires,
        ),
      };
    },
  });
};
