import styled from '@emotion/styled';
import { spacing } from 'theme/Units';

export const EquipmentContainer = styled.div`
  display: flex;

  & > div {
    width: 208px;
    margin-right: ${spacing.gutter};
  }
`;

export const CargoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex-basis: 90%;
    margin-right: ${spacing.gutter};
  }

  & > div:last-of-type {
    margin-right: 0;
  }
`;
export const TemperatureControlledContainer = styled.div`
  display: flex;
  margin-top: ${spacing.gutter};
  align-items: center;
  justify-content: space-between;
  & > div:first-of-type {
    padding-right: ${spacing.gutter};
    border-right: ${({ theme }) => `1px solid ${theme.palette.common.chrome}`};
  }
`;
