import React from 'react';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Styles
import { ReferenceContainer, ReferenceValue } from './TableStyles';

// @Theme
import { spacing } from 'theme/Units';

// @Types
import { TableReferenceNumbersProps } from './Table.types';

const TableReferenceNumbers = ({
  arriveId,
  referenceNumbers,
}: TableReferenceNumbersProps) => {
  const { TotalCount, Records } = referenceNumbers;
  const valuesToShow = Object.entries(Records);
  const extraNumbers = TotalCount - valuesToShow.length;

  const extraNumbersLabel = (
    <>
      {extraNumbers > 0 && (
        <Typography variant="caption" margin={`0 ${spacing.xsmall}`}>
          {`+${extraNumbers} more`}
        </Typography>
      )}
    </>
  );

  return (
    <>
      <ReferenceContainer>
        <ReferenceValue variant="body2" as="p" margin="0">
          Arrive # {arriveId}
        </ReferenceValue>
        {!valuesToShow.length && extraNumbersLabel}
      </ReferenceContainer>
      {valuesToShow.map((referenceNumber, index) => (
        <ReferenceContainer key={`${referenceNumber[0]}-${referenceNumber[1]}`}>
          <ReferenceValue
            margin="0"
            variant="body2"
            as="p"
          >{`${referenceNumber[0]} ${referenceNumber[1]}`}</ReferenceValue>
          {index === valuesToShow.length - 1 && extraNumbersLabel}
        </ReferenceContainer>
      ))}
    </>
  );
};

export default TableReferenceNumbers;
