import { useState, useEffect } from 'react';

// @Types
import { QuoteStatus, QuotesStatus } from 'types/quoteTypes.d';

// @Utils
import { updateQuoteStatusTimeAndExpiration } from 'utils/ExpirationMessageUtils';

export const useExpirationCountdown = (
  quoteStatus: QuoteStatus | QuotesStatus | undefined,
  singleStatus: boolean,
): {
  singleUpdatedExpiration: QuoteStatus | null;
  multiUpdatedExpiration: QuotesStatus | null;
} => {
  const [singleUpdatedExpiration, setSingleUpdatedExpiration] =
    useState<QuoteStatus | null>(null);
  const [multiUpdatedExpiration, setMultiUpdatedExpiration] =
    useState<QuotesStatus | null>(null);

  useEffect(() => {
    if (!!quoteStatus) {
      if (singleStatus) {
        setSingleUpdatedExpiration(quoteStatus as QuoteStatus);
      } else {
        setMultiUpdatedExpiration(quoteStatus as QuotesStatus);
      }
    }
  }, [quoteStatus, singleStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!!singleUpdatedExpiration) {
        setSingleUpdatedExpiration(
          updateQuoteStatusTimeAndExpiration(singleUpdatedExpiration),
        );
      }

      if (!!multiUpdatedExpiration) {
        const updExpObj = {} as QuotesStatus;
        Object.keys(multiUpdatedExpiration).forEach((quoteReference) => {
          updExpObj[quoteReference] = updateQuoteStatusTimeAndExpiration(
            multiUpdatedExpiration[quoteReference],
          );
        });
        setMultiUpdatedExpiration(updExpObj);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [singleUpdatedExpiration, multiUpdatedExpiration]);

  return {
    singleUpdatedExpiration,
    multiUpdatedExpiration,
  };
};
