import { EquipmentOptionType } from 'types/quoteTypes';

export const EquipmentTypes = {
  V: {
    label: 'Dry Van',
    value: 'V',
    'data-testid': 'DRY_VAN',
    displayName: 'Dry Van',
  },
  R: {
    label: 'Refrigerated',
    value: 'R',
    'data-testid': 'REFRIGERATED_VAN',
    displayName: 'Refrigerated',
  },
} as {
  [key: string]: EquipmentOptionType;
};

export const EquipmentOptions = Object.values(EquipmentTypes);
