import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import qs, { ParsedQs } from 'qs';
import { nanoid } from 'nanoid';

// @Api
import {
  LoadOverviewServiceEndpoints,
  TrackingServiceEndpoints,
} from 'api/endpoints';

// @Helpers
import {
  convertQueryValueToDate,
  formatPaginatedTrackingData,
} from './ShipmentsHelpers';

// @Hooks
import useAxios from 'hooks/useAxios';
import { useQueryParams } from 'hooks/useReactRouterQueries';

// @Types
import { Shipment, StatusEnumFormat } from 'types/Shipment.types';
import {
  ShipmentDetailsByLoadNumber,
  ShipmentDetailsTrackingInfo,
  ShipmentFiltersActionKind,
  ShipmentFiltersReducerAction,
  ShipmentFilterState,
  ShipmentsTrackingDataType,
} from './ShipmentPage.types';
import { UserLoginInformation } from 'types/User.types';

export const initialStateForShipmentsFilters: ShipmentFilterState = {
  Statuses: [],
  ReferenceNumber: '',
  PickupDate: null,
  PickupDateEnd: null,
  DeliveryDate: null,
  DeliveryDateEnd: null,
};

export const useGetShipments = (): UseQueryResult<{
  shipments: Shipment[] | [];
  shipmentsError?: boolean;
  total: number;
}> => {
  const axios = useAxios();
  const {
    DeliveryDate,
    DeliveryDateEnd,
    OrderByColumn,
    pageNumber: PageNumber,
    pageSize: PageSize,
    PickupDate,
    PickupDateEnd,
    ReferenceNumber,
    Statuses,
  } = useQueryParams();

  const queryStrings = qs.stringify({
    DeliveryDate,
    DeliveryDateEnd,
    OrderByColumn,
    PageNumber,
    PageSize,
    PickupDate,
    PickupDateEnd,
    ReferenceNumber,
    Statuses,
  });

  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData([
    'currentUser',
  ]) as UserLoginInformation;

  const fetchShipments = async () => {
    const { data } = await axios.get(
      `${LoadOverviewServiceEndpoints.getShipments}?${queryStrings}&customerId=${currentUser.customerId}`,
    );

    return {
      shipments: data.Result ? data.Result.Results : [],
      shipmentsError: !!(data.Errors && Object.keys(data.Errors).length),
      total: data.Result ? data.Result.TotalRecordCount : 0,
    };
  };

  return useQuery(
    [
      'shipments',
      DeliveryDate,
      DeliveryDateEnd,
      OrderByColumn,
      PageNumber,
      PageSize,
      PickupDate,
      PickupDateEnd,
      ReferenceNumber,
      Statuses,
    ],
    fetchShipments,
  );
};

export const useGetShipmentByLoadId = (
  loadId: string,
): UseQueryResult<ShipmentDetailsByLoadNumber> => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData([
    'currentUser',
  ]) as UserLoginInformation;

  const fetchShipment = async () => {
    const { data } = await axios.get(
      `${LoadOverviewServiceEndpoints.getShipmentDetailsById(
        loadId,
      )}&customerId=${currentUser.customerId}`,
    );

    return data.Result;
  };

  return useQuery(['shipmentDetailsByLoadId', loadId], fetchShipment);
};

export const useGetTrackingDetailsByLoadId = (
  loadId: string,
): UseInfiniteQueryResult<ShipmentsTrackingDataType> => {
  const axios = useAxios();
  const fetchTrackingInfo = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(
      `${TrackingServiceEndpoints.getTrackingDetailsByLoadNumber(
        loadId,
      )}&PageSize=5&PageNumber=${pageParam}`,
    );

    return {
      trackingData: data.Result.Results.map(
        (trackingData: ShipmentDetailsTrackingInfo) => ({
          ...trackingData,
          id: nanoid(10),
        }),
      ),
      totalCount: data.Result.TotalRecordCount,
    };
  };
  return useInfiniteQuery(
    ['trackingDetailsByLoadId', loadId],
    (pageParam) => {
      return fetchTrackingInfo(pageParam);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const allFetchedData = formatPaginatedTrackingData(allPages);
        const nextPage =
          allFetchedData.totalCount !== allFetchedData.trackingData.length
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    },
  );
};

export const useShipmentFiltersReducer = (
  queryParams: {
    [key: string]: string | string[] | ParsedQs | ParsedQs[] | undefined;
  },
  setQueryParams: (
    newParams: {
      [key: string]: any;
    },
    reset?: boolean,
  ) => void,
) => {
  const queryClient = useQueryClient();
  const statusEnums = queryClient.getQueryData([
    'shipmentsFilterEnums',
  ]) as Array<StatusEnumFormat>;

  const {
    Statuses = [],
    PickupDate,
    PickupDateEnd,
    DeliveryDate,
    DeliveryDateEnd,
    ReferenceNumber = '',
  } = queryParams;

  const reducer = (
    state: ShipmentFilterState,
    action: ShipmentFiltersReducerAction,
  ): ShipmentFilterState => {
    const { type, payload } = action;

    switch (type) {
      case ShipmentFiltersActionKind.SET_REFERENCE_NUMBER: {
        return { ...state, ReferenceNumber: payload };
      }

      case ShipmentFiltersActionKind.SET_STATUSES: {
        return { ...state, Statuses: payload };
      }

      case ShipmentFiltersActionKind.SET_DATES: {
        return { ...state, ...payload };
      }

      case ShipmentFiltersActionKind.APPLY_FILTERS: {
        setQueryParams({ ...queryParams, ...payload });
        return state;
      }

      case ShipmentFiltersActionKind.RESET_STATE: {
        const appliedStatuses = statusEnums?.filter(
          (status: StatusEnumFormat) => {
            return (Statuses as string[]).includes(status.Name);
          },
        );

        return {
          ...state,
          Statuses: appliedStatuses,
          PickupDate: convertQueryValueToDate(PickupDate),
          PickupDateEnd: convertQueryValueToDate(PickupDateEnd),
          DeliveryDate: convertQueryValueToDate(DeliveryDate),
          DeliveryDateEnd: convertQueryValueToDate(DeliveryDateEnd),
          ReferenceNumber: ReferenceNumber as string,
        };
      }

      case ShipmentFiltersActionKind.CLEAR_ALL_FILTERS: {
        setQueryParams({
          ...queryParams,
          DeliveryDate: null,
          DeliveryDateEnd: null,
          PickupDate: null,
          PickupDateEnd: null,
          ReferenceNumber: null,
          Statuses: null,
          OrderByColumn: 'EarliestPickup',
          pageNumber: 1,
        });
        return initialStateForShipmentsFilters;
      }
    }
  };

  return reducer;
};
