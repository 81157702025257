import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

// @Endpoints
import { QuoteEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from 'hooks/useAxios';
import { useSetQueryParams } from 'hooks/useReactRouterQueries';

// @Types
import {
  GetQuoteRequest,
  SendQuoteOptions,
  RateResponse,
} from 'types/quoteTypes.d';
import { UserLoginInformation } from 'types/User.types';

export const useSendQuoteRequest = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const customerId = (
    queryClient.getQueryData(['currentUser']) as UserLoginInformation
  )?.customerId;
  const setQueryParams = useSetQueryParams();

  const getRateForQuote = async (quoteFormData: SendQuoteOptions) => {
    queryClient.removeQueries(['quoteHistory']);
    queryClient.removeQueries(['quoteStatus']);
    const updatedPickupDate = pickupDateInCST(quoteFormData.pickupDate as Date)
      .toUTC()
      .toISO({ suppressMilliseconds: true });

    const quoteRequestDetails = {
      customerId: customerId,
      equipmentType: quoteFormData.equipmentType,
      lane: [
        {
          stopNum: 0,
          city: quoteFormData.pickupLocation?.CityName,
          state: quoteFormData.pickupLocation?.CityState,
          countryCode2: 'US',
          postalCode: quoteFormData.pickupLocation?.PostalCode,
          stopType: 'Pickup',
          stopDateTime: updatedPickupDate,
        },
        {
          stopNum: 1,
          city: quoteFormData.deliveryLocation?.CityName,
          state: quoteFormData.deliveryLocation?.CityState,
          countryCode2: 'US',
          postalCode: quoteFormData.deliveryLocation?.PostalCode,
          stopType: 'Delivery',
        },
      ],
    } as GetQuoteRequest;

    const { data: rateResponse } = await axios.post(
      QuoteEndpoints.rateRequestShipperPortal,
      quoteRequestDetails,
    );

    queryClient.setQueryData(
      ['quoteDetails'],
      transformRateResultToQuoteDetails(quoteFormData, rateResponse.result),
    );

    return rateResponse.result;
  };

  return useMutation(
    (quoteFormData: SendQuoteOptions) => getRateForQuote(quoteFormData),
    {
      onSuccess: (resp) => {
        setQueryParams({ quoteResult: resp.quoteId });
      },
    },
  );
};

export const pickupDateInCST = (pickupDate: Date) => {
  return DateTime.fromJSDate(pickupDate)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .setZone('America/Chicago');
};

export const transformRateResultToQuoteDetails = (
  quoteFormData: SendQuoteOptions,
  rateResponse: RateResponse | null,
) => {
  const updatedPickupDate = pickupDateInCST(quoteFormData.pickupDate as Date);
  return {
    customerQuoteId: rateResponse?.customerQuoteId || null,
    deliveryLocation: `${quoteFormData?.deliveryLocation?.CityName},  ${quoteFormData?.deliveryLocation?.CityState}`,
    deliveryGeolocation: quoteFormData.deliveryLocation?.Geolocation,
    deliveryPostalCode: quoteFormData?.deliveryLocation?.PostalCode,
    equipmentType: quoteFormData.equipmentType,
    expirationDate: rateResponse?.expirationDate || null,
    fuelSurcharge: rateResponse?.fuelSurcharge || null,
    linehaul: rateResponse?.linehaul || null,
    mode: 'FTL',
    pickupDate: updatedPickupDate?.toUTC().toISO(),
    pickupLocation: `${quoteFormData?.pickupLocation?.CityName},  ${quoteFormData?.pickupLocation?.CityState}`,
    pickupPostalCode: quoteFormData?.pickupLocation?.PostalCode,
    pickupGeolocation: quoteFormData.pickupLocation?.Geolocation,
    rate: rateResponse ? Math.floor(rateResponse.totalRate) : null,
    quoteId: rateResponse?.quoteId || null,
    quoteReference: rateResponse?.quoteId || null,
    totalMiles: rateResponse?.totalMiles || null,
  };
};
