import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  LegacyRef,
} from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { DateTime } from 'luxon';

// @Components
import { DateContainer, DatePickerHeader } from './DateTimePickersElements';

// @Styles
import { DateRangeContainer, StyledDatePicker } from './DateTimePickerStyles';
import { StyledInputError } from 'styles/ErrorStyles';
import 'react-datepicker/dist/react-datepicker.css';
import { PillButton } from 'commonComponents/Buttons/ButtonStyles';

// @Types
import { DateRangePickerProps } from './DateTimePicker.types';

const getMonthsDifference = (date1: DateTime, date2: DateTime) => {
  const diffYears = date2.year - date1.year;
  const diffMonths = date2.month - date1.month + 12 * diffYears;
  return diffMonths;
};

export const PillDatePickerHeader = (
  props: ReactDatePickerCustomHeaderProps & {
    calendarFirstMonth?: Date | null;
    setCalendarFirstMonth: (date: Date) => void;
  },
) => {
  const {
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    monthDate,
    calendarFirstMonth,
    setCalendarFirstMonth,
  } = props;

  const handleIncreaseMonth = () => {
    const newMonth = DateTime.fromJSDate(monthDate).toJSDate();
    increaseMonth();
    setCalendarFirstMonth(newMonth);
  };

  const handleDecreaseMonth = () => {
    const newMonth = DateTime.fromJSDate(monthDate)
      .minus({ months: 1 })
      .toJSDate();
    decreaseMonth();
    setCalendarFirstMonth(newMonth);
  };

  useLayoutEffect(() => {
    if (customHeaderCount === 0) {
      const monthDateTime = DateTime.fromJSDate(monthDate);
      const currentDateTime = DateTime.fromJSDate(
        calendarFirstMonth || new Date(),
      );
      const monthDifference = getMonthsDifference(
        currentDateTime,
        monthDateTime,
      );
      for (let i = 0; i < Math.abs(monthDifference); i++) {
        monthDifference < 0 ? increaseMonth() : decreaseMonth();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarFirstMonth]);

  return (
    <DatePickerHeader
      {...props}
      increaseMonth={handleIncreaseMonth}
      decreaseMonth={handleDecreaseMonth}
    />
  );
};

type DateRangePillInputProps = {
  setIsSelecting: () => void;
  placeholderText: string;
  inputRef: React.RefObject<HTMLInputElement>;
};

const DateRangePillInput = forwardRef(
  (
    {
      setIsSelecting,
      placeholderText,
      inputRef,
      ...rest
    }: DateRangePillInputProps,
    _ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <PillButton
        {...rest}
        ref={inputRef}
        title={placeholderText}
        onClick={setIsSelecting}
      />
    );
  },
);

DateRangePillInput.displayName = 'DateRangePillInput';

export type PillDateRangePickerProps = Omit<
  DateRangePickerProps,
  'placeholderEnd'
> & {
  handleClear?: () => void;
  handleApply?: () => void;
  handleReset?: () => void;
};

export const PillDateRangePicker = ({
  placeholderStart,
  rangeEnd,
  rangeStart,
  setRangeEnd,
  setRangeStart,
  maxDate,
  minDate,
  dataTestId,
  errorText,
  handleClear,
  handleApply,
  handleReset,
  ...rest
}: PillDateRangePickerProps) => {
  const [isSelecting, setIsSelecting] = useState<string>('NONE');
  const [calendarFirstMonth, setCalendarFirstMonth] = useState<Date>(
    rangeStart || new Date(),
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDatePickerFocus = () => {
    if (isSelecting !== 'NONE') {
      inputRef?.current?.focus();
    } else {
      inputRef?.current?.blur();
    }
  };

  useEffect(() => {
    handleDatePickerFocus();
    if (isSelecting === 'NONE') {
      setCalendarFirstMonth(rangeStart || new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelecting]);

  const handleStartDateSelect = (date: Date) => {
    setRangeEnd(null);
    setRangeStart(date);
    setIsSelecting('END');
  };

  const handleEndDateSelect = (date: Date) => {
    setRangeEnd(date);
    setIsSelecting('START');
  };

  const handleClearDate = () => {
    setIsSelecting('NONE');
    handleClear && handleClear();
  };

  const handleApplyDate = () => {
    setIsSelecting('NONE');
    handleApply && handleApply();
  };

  const handleResetDate = () => {
    setIsSelecting('NONE');
    handleReset && handleReset();
  };

  return (
    <>
      <DateRangeContainer
        data-testid={dataTestId}
        aria-label={placeholderStart}
        pillInput
        onClick={handleDatePickerFocus}
      >
        <StyledDatePicker
          onClickOutside={handleResetDate}
          onChange={/* istanbul ignore next */ () => null}
          selectsStart
          monthsShown={2}
          open={isSelecting === 'START'}
          calendarContainer={(props) => (
            <DateContainer
              isSingleDate={false}
              handleApply={handleApplyDate}
              handleClear={handleClearDate}
              {...props}
            />
          )}
          selected={
            rangeEnd
              ? new Date(rangeEnd)
              : rangeStart
              ? new Date(rangeStart)
              : null
          }
          startDate={rangeStart}
          endDate={rangeEnd}
          customInput={
            <DateRangePillInput
              placeholderText={placeholderStart || ''}
              setIsSelecting={() => {
                isSelecting === 'NONE'
                  ? setIsSelecting('START')
                  : handleResetDate();
              }}
              inputRef={inputRef}
            />
          }
          renderCustomHeader={(props) => (
            <PillDatePickerHeader
              setCalendarFirstMonth={setCalendarFirstMonth}
              calendarFirstMonth={calendarFirstMonth}
              {...props}
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={handleStartDateSelect}
          {...rest}
        />
        <StyledDatePicker
          onClickOutside={handleResetDate}
          onChange={/* istanbul ignore next */ () => null}
          selectsEnd
          open={isSelecting === 'END'}
          monthsShown={2}
          calendarContainer={(props) => (
            <DateContainer
              isStartDate={false}
              isSingleDate={false}
              handleApply={handleApplyDate}
              handleClear={handleClearDate}
              {...props}
            />
          )}
          selected={rangeStart ? new Date(rangeStart) : null}
          startDate={rangeStart}
          endDate={rangeEnd}
          customInput={<></>}
          renderCustomHeader={(props) => (
            <PillDatePickerHeader
              setCalendarFirstMonth={setCalendarFirstMonth}
              calendarFirstMonth={calendarFirstMonth}
              {...props}
            />
          )}
          minDate={rangeStart || minDate}
          maxDate={maxDate}
          onSelect={handleEndDateSelect}
          {...rest}
        />
      </DateRangeContainer>
      {!!errorText?.length && <StyledInputError>{errorText}</StyledInputError>}
    </>
  );
};

export default PillDateRangePicker;
