import React from 'react';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';
import ReferenceNumberSearch from 'commonComponents/Search/ReferenceNumberSearch';

// @Styles
import {
  ClearFiltersButton,
  FilterOptions,
  FilterResults,
  FiltersContainer,
} from 'styles/Filters';

// @Types
import { InvoiceFiltersProps } from '../Invoices.types';

const InvoiceFilters = ({
  handleClearAllFilters,
  referenceNumber,
  setReferenceNumber,
  totalShipments,
  handleSearch,
}: InvoiceFiltersProps) => {
  return (
    <FiltersContainer>
      <FilterOptions>
        <ReferenceNumberSearch
          handleApply={handleSearch}
          referenceNumber={referenceNumber}
          setReferenceNumber={setReferenceNumber}
        />
        <ClearFiltersButton onClick={handleClearAllFilters}>
          Clear all
        </ClearFiltersButton>
      </FilterOptions>
      <FilterResults>
        <Typography variant="body1">
          {totalShipments} {totalShipments === 1 ? 'Shipment' : 'Shipments'}
        </Typography>
      </FilterResults>
    </FiltersContainer>
  );
};

export default InvoiceFilters;
