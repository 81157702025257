import React, {
  ReactElement,
  memo,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from 'react';
import styled from '@emotion/styled';
import { math } from 'polished';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash-es/debounce';

//@CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Components
import PickupDeliveryForm from './PickupDeliveryForm/PickupDeliveryForm';
import PickupDeliverySummary from './PickupDeliveryForm/PickupDeliverySummary';
import LoadForm from './LoadForm/LoadForm';

// @Hooks
import { useShipmentFormEnums } from './useShipmentForm';
import useLocalStorage from 'hooks/useLocalStorage';
import useReadLocalStorage from 'hooks/useReadLocalStorage';
import useGetTimeZones from 'hooks/useGetTimeZones';

// @Styles
import { spacing } from 'theme/Units';
import { ShipmentFormDisabledSectionHeader } from './ShipmentFormStyles';
import { LinkButton } from 'styles/Links';

// @Types
import { ShipmentDetails } from 'types/Shipment.types';
import { AddNewFacilityState } from './ShipmentForm.types';

// @Utils
import {
  updateFormStatus,
  validateLoadForm,
  validatePickupDeliveryForm,
} from './ShipmentFormHelpers';
import { convertToTitleCase } from 'utils/utils';

const ShipmentFormContainer = styled.div`
  padding: ${math(`${spacing.normal} * 3.5`)} ${spacing.xlarge};
`;

const ShipmentFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const ShipmentFormBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const initialFormState = {
  pickup: {
    readOnly: false,
    completed: false,
    hidden: false,
  },
  delivery: {
    readOnly: false,
    completed: false,
    hidden: true,
  },
  shipment: {
    readOnly: false,
    completed: false,
    hidden: true,
  },
};

const ShipmentForm = (): ReactElement => {
  // fetch enums
  const { isInitialLoading: isLoadingShipmentFormEnums } =
    useShipmentFormEnums();
  //read from local storage
  const selectedQuote = useReadLocalStorage('selectedQuote') as ShipmentDetails;
  const addNewFacility = useReadLocalStorage(
    'newFacility',
  ) as AddNewFacilityState;

  const [formState, updateFormState] = useReducer(
    updateFormStatus,
    initialFormState,
  );

  // location
  const history = useHistory();

  const { quoteId } = useParams<{ quoteId: string }>();

  // set local storage
  const [_selectedQuote, setLocalStorage] = useLocalStorage<ShipmentDetails>(
    'selectedQuote',
    selectedQuote,
  );

  const [shipmentDetails, setShipmentDetails] =
    useState<ShipmentDetails>(selectedQuote);
  const [isNewFacility, setIsNewFacility] =
    useState<AddNewFacilityState>(addNewFacility);

  useEffect(() => {
    if (quoteId && shipmentDetails.quoteId !== quoteId) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  // set add new facility for local storage
  const [_newFacility, setAddNewFacilityForLocalStorage] =
    useLocalStorage<AddNewFacilityState>('newFacility', {
      pickup: false,
      delivery: false,
    });

  //debounce local storage
  const debounceLocalStorage = useMemo(
    () =>
      debounce(
        (updatedShipmentDetails) => setLocalStorage(updatedShipmentDetails),
        700,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const debounceFacilityLocalStorage = useMemo(
    () =>
      debounce(
        (updatedFacilityState) =>
          setAddNewFacilityForLocalStorage(updatedFacilityState),
        700,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!validatePickupDeliveryForm(shipmentDetails['pickup'])) {
      return;
    }
    updateFormState({ type: 'COMPLETE', payload: { section: 'pickup' } });
    if (!validatePickupDeliveryForm(shipmentDetails['delivery'])) {
      return;
    }
    updateFormState({ type: 'COMPLETE', payload: { section: 'delivery' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: timezones } = useGetTimeZones([
    shipmentDetails.pickup.cityState,
    shipmentDetails.delivery.cityState,
  ]);

  const isValidShipment = validateLoadForm(shipmentDetails);

  const handleShipmentFormInputChange = (
    key: keyof ShipmentDetails,
    value: unknown,
  ) => {
    setShipmentDetails({ ...shipmentDetails, [key]: value });
  };

  /* istanbul ignore next */
  const handleBackToQuotes = () => {
    window.localStorage.removeItem('selectedQuote');
    history.replace('/');
  };

  /* istanbul ignore next */
  useEffect(() => {
    debounceLocalStorage(shipmentDetails);
    debounceFacilityLocalStorage(isNewFacility);
    // cleanup debounce
    return () => {
      debounceFacilityLocalStorage.cancel();
      return debounceLocalStorage.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentDetails, isNewFacility]);
  /* istanbul ignore end */

  const renderFormSection = (section: 'pickup' | 'delivery') => {
    if (formState[section].hidden) {
      return (
        <ShipmentFormDisabledSectionHeader>
          <Typography variant="h2" aria-label={`disabled-${section}`}>
            {convertToTitleCase(section)}
          </Typography>
        </ShipmentFormDisabledSectionHeader>
      );
    }

    return (
      <PickupDeliveryForm
        handleSectionSubmit={() =>
          updateFormState({ type: 'COMPLETE', payload: { section: section } })
        }
        handleShipmentFormInputChange={handleShipmentFormInputChange}
        isLoadingShipmentFormEnums={isLoadingShipmentFormEnums}
        pickupDeliveryState={shipmentDetails[section]}
        pickupDeliveryType={section}
        title={convertToTitleCase(section)}
        readOnly={formState[section].readOnly}
        handleEditPickupDelivery={() =>
          updateFormState({ type: 'EDIT', payload: { section: section } })
        }
        pickupDate={selectedQuote.pickupDate}
        timezone={timezones ? timezones[selectedQuote[section].cityState] : ''}
        setIsNewFacility={setIsNewFacility}
        setShipmentDetails={setShipmentDetails}
        shipmentDetails={shipmentDetails}
      />
    );
  };

  return (
    <ShipmentFormContainer>
      <ShipmentFormHeader>
        <Typography variant="display2">Build your shipment</Typography>
        <LinkButton onClick={handleBackToQuotes}>Back to Quotes</LinkButton>
      </ShipmentFormHeader>
      <ShipmentFormBody>
        <div>
          {renderFormSection('pickup')}
          {renderFormSection('delivery')}
          {!formState.shipment.hidden ? (
            <LoadForm
              handleShipmentFormInputChange={handleShipmentFormInputChange}
              isLoadingShipmentFormEnums={isLoadingShipmentFormEnums}
              setShipmentDetails={setShipmentDetails}
              shipmentDetails={shipmentDetails}
              isValidShipment={isValidShipment}
            />
          ) : (
            <ShipmentFormDisabledSectionHeader>
              <Typography variant="h2" aria-label="disabled-shipment">
                Shipment
              </Typography>
            </ShipmentFormDisabledSectionHeader>
          )}
        </div>
        <PickupDeliverySummary selectedQuote={selectedQuote} />
      </ShipmentFormBody>
    </ShipmentFormContainer>
  );
};

export default memo(ShipmentForm);
