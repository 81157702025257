import { GroupBase, OptionsOrGroups } from 'react-select';

// @Api
import { LoadServiceEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from './useAxios';
import { onlyAllowZipCode } from 'utils/utils';

// @Types
import { LoadLocation } from 'types/quoteTypes';

export const useGetLoadLocation = () => {
  const axios = useAxios();

  return async (
    input: string,
  ): Promise<OptionsOrGroups<LoadLocation, GroupBase<LoadLocation>>> => {
    const zipOnlyInput = onlyAllowZipCode(input);
    return zipOnlyInput.length >= 3
      ? await new Promise((resolve) =>
          setTimeout(
            () =>
              resolve(
                axios
                  .get(`${LoadServiceEndpoints.getLocations(zipOnlyInput)}`)
                  .then((response) => {
                    return response.data.Result.filter(
                      (option: LoadLocation) => !!option.CityName,
                    );
                  }),
              ),
            1000,
          ),
        )
      : [];
  };
};
