import React, { ReactElement, ReactNode } from 'react';

// @Hooks
import useDisableBodyScroll from 'hooks/useDisableBodyScroll';

// @Styles
import { SidePanelOverlay, SidePanelDialog } from './SidePanelStyles';

// @Types
type SidePanelProps = {
  show: boolean;
  width?: string;
  children: ReactNode;
  handleCloseSideModal: () => void;
};

const SidePanel = ({
  show,
  width = '320px',
  children,
  handleCloseSideModal,
}: SidePanelProps): ReactElement => {
  // @ts-ignore @typescript-eslint/no-unused-vars
  const _disableBodyScroll = useDisableBodyScroll(show);

  return (
    <>
      <SidePanelOverlay
        show={show}
        role="dialog"
        aria-labelledby="side-panel"
        onClick={handleCloseSideModal}
      ></SidePanelOverlay>
      <SidePanelDialog role="document" show={show} width={width}>
        {children}
      </SidePanelDialog>
    </>
  );
};

export default SidePanel;
