import React, { useState, useRef } from 'react';
import { useTheme } from '@emotion/react';

// @Components
import SearchableDropdown from './SearchableDropdown';
import Typography from '../Typography/Typography';

// @Hooks
import useEventListener from 'hooks/useEventListener';

// @SVG
import Check from '@arrive/ui-icons/svg/ic_check.svg';

// @Styles
import {
  getConfigStyles,
  SortByOptionItem,
  SortByOptionRow,
} from './DropdownStyles';

//@Types
import { SearchableDropdownSelectProps } from './DropdownTypes.types';
import { ValueContainerProps, OptionProps, components } from 'react-select';
import type { SortByOptionsType } from './DropdownTypes.types';

const { Option, ValueContainer } = components;
const OptionOverride = (props: OptionProps) => {
  return (
    <Option {...props}>
      <SortByOptionRow isSelected={props.isSelected}>
        <div data-testid="selected-check">
          <Check />
        </div>
        <SortByOptionItem
          isSelected={props.isSelected}
          data-testid="option-item"
        >
          <Typography>{props.label}</Typography>
        </SortByOptionItem>
      </SortByOptionRow>
    </Option>
  );
};

const ValueContainerOverride = (props: ValueContainerProps) => {
  return (
    <ValueContainer {...props}>
      <div data-testid="sort-by-value-container">
        Sort By: {(props.getValue()[0] as SortByOptionsType)?.label}
      </div>
    </ValueContainer>
  );
};

const SortByDropdown = ({ ...props }: SearchableDropdownSelectProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEventListener('mousedown', (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      return setShowMenu(false);
    }
  });

  const theme = useTheme();
  const commonStyles = getConfigStyles(theme);

  const getValueContainer = (props: any) => (
    <ValueContainerOverride {...props} />
  );

  const getOptionOverride = (props: any) => <OptionOverride {...props} />;

  return (
    <div
      role="button"
      data-testid="sort-by-dropdown-container"
      onClick={() => setShowMenu(!showMenu)}
      ref={menuRef}
    >
      <SearchableDropdown
        {...props}
        components={{
          ValueContainer: getValueContainer,
          Option: getOptionOverride,
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={showMenu}
        blackIcons
        dataTestId="sort-by-dropdown"
        options={props.options}
        styles={{
          ...commonStyles,
          control: (styles) => ({
            ...styles,
            border: 'none',
            width: '259px',
          }),
          //@ts-ignore
          dropdownIndicator: (styles) => ({
            ...styles,
            transition: 'all .2s ease',
            transform: showMenu ? 'rotate(180deg)' : null,
          }),
          valueContainer: (styles) => ({
            ...styles,
            display: 'flex',
            justifyContent: 'end',
            fontSize: theme.fontSizes.small,
            '&:hover': {
              color: theme.palette.common.arrive,
              cursor: 'pointer',
            },
          }),
        }}
      />
    </div>
  );
};

export default SortByDropdown;
