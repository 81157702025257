export const getLatitudeAndLongitude = (point?: string | null) => {
  const pointsArray = point
    ? point?.replace(/(POINT|\(|\))/g, '').split(' ')
    : [];

  if (pointsArray?.length > 2) {
    const [_, lng, lat] = pointsArray;

    return { lng: parseFloat(lng), lat: parseFloat(lat) };
  }

  return { lng: 0, lat: 0 };
};

export const getZoomLevel = (totalMiles: number): number => {
  switch (true) {
    case totalMiles < 25:
      return 9;
    case totalMiles < 100:
      return 7;
    case totalMiles < 200:
      return 6;
    case totalMiles < 300:
      return 5.5;
    case totalMiles < 400:
      return 5;
    default:
      return 3.5;
  }
};
