import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import theme from 'theme/Theme';

export const LinkButton = styled.a<{
  fontSize?: string;
}>`
  text-decoration: none;
  color: ${theme.palette.common.arrive};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : theme.fontSizes.small)};
  cursor: pointer;
`;

export const EmailLink = styled(Link)`
  color: ${({ theme: { palette } }) => palette.common.arrive};
  text-decoration: none;
  cursor: pointer;
`;
