import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// @arrive
import Button from '@arrive/button';

// @Components
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Styles
import { ShipmentFormSectionFooterContainer } from './ShipmentFormStyles';
import { ShipmentFormSectionFooterProps } from './ShipmentForm.types';
import { LoadingButton } from 'styles/Buttons';

const ShipmentFormSectionFooter = ({
  handleClearSection,
  handleSectionSubmit,
  isValid,
  primaryButtonText,
  isLoadingSubmit = false,
  handleAutoPopulate,
}: ShipmentFormSectionFooterProps) => {
  const { enableDevInputs } = useFlags();

  return (
    <ShipmentFormSectionFooterContainer>
      <Button
        text
        type="secondary"
        onClick={handleClearSection}
        data-testid="shipment-form-clear-button"
      >
        Clear
      </Button>
      <LoadingButton
        disabled={!isValid || isLoadingSubmit}
        onClick={handleSectionSubmit}
        data-testid="shipment-form-submit-button"
      >
        {isLoadingSubmit && <SpinningLoadingIndicator />}
        {primaryButtonText}
      </LoadingButton>
      {
        /* istanbul ignore next */
        enableDevInputs && (
          <Button style={{ marginLeft: '12px' }} onClick={handleAutoPopulate}>
            Auto Populate
          </Button>
        )
        /* istanbul ignore end */
      }
    </ShipmentFormSectionFooterContainer>
  );
};

export default ShipmentFormSectionFooter;
