import React from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';

// @Common Components
import Section from 'commonComponents/Section/Section';

// @Components
import InformationSection from './InformationSection';

// @Hooks
import { useGetContactInformation } from 'hooks/useGetContactInformation';

// @Types
import { ContactType } from './ContactInformation.types';
import { UserLoginInformation } from 'types/User.types';

// @Styles
import { EmailLink } from 'styles/Links';

const ContactInformationContainer = styled.div`
  display: flex;
  justify-content: start;
`;

const ContactInformation = () => {
  const queryClient = useQueryClient();
  const { customerId } = queryClient.getQueryData([
    'currentUser',
  ]) as UserLoginInformation;

  const { data: contactInformation } = useGetContactInformation(customerId, [
    'SALES_REP',
    'SALES_MANAGER',
    'CUSTOMER_RELATIONS_REP',
  ]);

  return (
    <Section title="Contact us with any questions" variant="subtitle">
      <ContactInformationContainer data-testid="contact-information">
        {contactInformation?.length ? (
          contactInformation?.map((contact: ContactType) => (
            <InformationSection key={contact.userId} contact={contact} />
          ))
        ) : (
          <EmailLink
            data-testid="contact-footer-email-link"
            to="#"
            onClick={(e) => {
              window.location.href = 'mailto: nowShipperCS@arrivelogistics.com';
              e.preventDefault();
            }}
          >
            nowShipperCS@arrivelogistics.com
          </EmailLink>
        )}
      </ContactInformationContainer>
    </Section>
  );
};

export default ContactInformation;
