import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import theme from 'theme/Theme';

export const NavContainer = styled.nav`
  background-color: ${theme.palette.common.ethanol};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  color: ${theme.palette.common.axle};
  display: flex;
  align-items: center;
  height: 52px;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 10;
`;

export const ArriveLogo = styled.svg`
  padding: 0 ${theme.spacing.gutter} 0 ${theme.spacing.normal};
  width: 196px;
`;

export const NavLink = styled(Link)<{ selected: boolean }>`
  color: ${theme.palette.common.axle};
  font-size: ${theme.fontSizes.medium};
  font-weight: 400;
  padding: ${({ selected }) =>
    selected
      ? `${theme.spacing.small} ${theme.spacing.normal}`
      : `14px ${theme.spacing.normal}`};
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  border-bottom: ${({ selected }) =>
    selected
      ? `${theme.spacing.xxsmall} solid ${theme.palette.common.arrive}`
      : 'none'};

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${theme.palette.common.arrive};
    height: ${theme.spacing.xxsmall};
    transform: translateY(${theme.spacing.xxsmall});
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:before {
    cursor: pointer;
    transform: translateY(0);
  }
`;

export const LogoutLink = styled(Link)`
  align-items: center;
  border: none;
  border-left: 1px solid ${theme.palette.common.chrome};
  color: ${theme.palette.common.grease};
  display: flex;
  margin-left: auto;
  padding: 0 ${theme.spacing.large} 0 ${theme.spacing.large};
  text-decoration: none;
  width: 124px;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.common.arrive};
  }
`;
