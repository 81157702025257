import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';

// @Common
import { fontSizes, spacing } from 'theme/Units';
import { palette } from 'theme/Theme';
import { css } from '@emotion/react';

export const StyledTimeContainer = styled.div`
  background: {
  }
  width: 100%;
  display: flex;
  font-size: ${fontSizes.small};
  color: ${(props) => props.theme.palette.common.axle};

  & .react-datepicker__time-container {
    width: 100%;
    border: none;
    float: none;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%), 0 8px 8px 0 rgb(0 0 0 / 24%);

    & .react-datepicker__header--time {
      display: none;
    }

    & .react-datepicker__time .react-datepicker__time-box {
      width: 100%;
    }

    & .react-datepicker__time-list-item {
      min-height: 24px;
      padding: ${spacing.xsmall};
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${(props) => props.theme.fontFamily};
    }

    &
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      background-color: ${(props) => props.theme.palette.common.bandit};
    }

    &
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${(props) => props.theme.palette.common.bandit};
      color: ${(props) => props.theme.palette.common.axle};
      font-weight: normal;
    }

    &
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--disabled {
      display: none;
    }
  }
`;

export const DateRangeContainer = styled.div<{ pillInput?: boolean }>`
  display: flex;
  position: relative;

  .react-datepicker-popper {
    transform: unset !important;
  }

  .react-datepicker-wrapper:not(:first-of-type) {
    display: ${({ pillInput }) => pillInput && 'none'};
  }
`;

export const TimeRangePickerContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  box-sizing: border-box;
  position: static;
`;

export const StyledDatePickerContainer = styled.div<{
  hasValue: boolean;
  disabled: boolean;
}>`
  padding: ${`0px ${spacing.small}`};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  border: 1px solid ${palette.common.chrome};
  border-radius: 3px;
  box-sizing: border-box;
  height: 52px;
  display: flex;
  align-items: center;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
    width: 100%;

    svg {
      height: 24px;
      width: 24px;
      fill: ${palette.common.arrive};
    }
  }

  label {
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) =>
      props.disabled ? palette.common.LFGray : palette.common.smokey};
    left: 42px;
    position: absolute;
    max-width: 80%;
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${fontSizes.medium};
    transform-origin: 0% 0%;
    transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    font-weight: ${({ hasValue }) => hasValue && '400'};
    transform: ${({ hasValue }) => hasValue && 'translateY(-10px) scale(0.75)'};
  }

  input {
    margin-left: ${`-${spacing.xsmall}`};
    border: 0;
    box-sizing: border-box;
    font-size: ${fontSizes.medium};
    outline: none;
    padding-top: ${({ hasValue }) => hasValue && spacing.normal};
    color: ${(props) => props.disabled && palette.common.LFGray};
  }

  ::selection {
    background: ${palette.common.axle};
    color: ${palette.common.ethanol};
  }

  &:hover {
    border-color: ${palette.common.axle};
  }

  &:focus-within {
    border: ${`2px solid ${palette.common.arrive}`};
    border-radius: 3px;
  }
`;

export const StyledDateHeader = styled.div`
  margin-bottom: ${spacing.small};
  display: flex;
  justify-content: center;

  .react-datepicker__current-month {
    font-size: ${fontSizes.medium};
    font-weight: inherit;
    color: ${palette.common.smokey};
  }
`;

export const CalendarArrowButton = styled.button<{
  disabled: boolean;
  isRight: boolean;
}>`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${(props) => !props.isRight && '228px'};
  left: ${(props) => props.isRight && '228px'};
  top: 100px;
  z-index: 2;
  border: ${`solid 1px ${palette.common.chrome}`};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: ${palette.common.ethanol};
  fill: ${palette.common.smokey};
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), 0 2px 4px 0 rgb(0 0 0 / 8%);

  &:hover {
    fill: ${({ disabled }) =>
      disabled ? palette.common.LFGray : palette.common.smokey};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  svg {
    right: ${(props) => (props.isRight ? '6px' : '8px')};
    position: absolute;
    top: 3px;
    width: 20px;
    height: 20px;
  }
`;

const focusStyles = css`
  background-color: ${palette.common.arrive} !important;
  border: ${`solid 2px ${palette.common.arrive} !important`};
  color: ${palette.common.ethanol} !important;
`;

const hoverStyles = css`
  background-color: ${palette.common.bandit};
  border: ${`solid 2px ${palette.common.bandit}`};
  color: ${palette.common.axle};
`;

const weekendStyles = css`
  background-color: ${palette.common.tandem};
  border: ${`solid 2px ${palette.common.ethanol}`};
  color: ${palette.common.axle};
`;

const defaultStyles = css`
  background-color: ${palette.common.ethanol};
  border: ${`solid 2px ${palette.common.ethanol}`};
  color: ${palette.common.axle};
`;

export const StyledDateHorizontalContainer = styled.div<{
  isStartDate: boolean;
}>`
  width: 521px;
  background-color: ${palette.common.ethanol};
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: ${spacing.gutter};

  .react-datepicker__month-container:first-of-type {
    border-right: solid 1px ${palette.common.chrome};
  }

  & .react-datepicker__header {
    background-color: ${palette.common.ethanol};
    text-align: left;
    border: none;
    color: ${palette.common.smokey};
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  & .react-datepicker__day-name {
    font-size: ${fontSizes.small};
    line-height: none;
    height: 30px;
    width: 30px;
    margin: 0px;
    align-items: center;
    color: ${palette.common.smokey};
  }

  & .react-datepicker__month-container {
    padding: ${`0px ${spacing.normal}`};
  }

  & .react-datepicker__month {
    margin: 0;
  }

  & .react-datepicker__week {
    display: flex;
  }

  & .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__month {
    & .react-datepicker__day {
      ${defaultStyles}
      box-sizing: border-box;
      font-size: ${fontSizes.small};
      line-height: none;
      height: 30px;
      width: 30px;
      margin: 0px;
      border-radius: 0px;
      align-items: center;
    }

    & .react-datepicker__day--today {
      font-weight: normal;
      border: solid 2px ${palette.common.axle};
      border-radius: 4px;
    }

    // Weekend styles
    &
      .react-datepicker__day--weekend:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__day--disabled
      ) {
      ${weekendStyles};
    }

    &
      .react-datepicker__day--weekend.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range
      ) {
      ${({ isStartDate }) => (isStartDate ? weekendStyles : hoverStyles)}
    }

    // In range styles
    &
      .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range
      ) {
      ${({ isStartDate }) => (isStartDate ? defaultStyles : hoverStyles)}
    }

    &
      .react-datepicker__day--in-range:not(
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
      ) {
      ${hoverStyles}
    }

    & .react-datepicker__day {
      &:hover {
        ${hoverStyles}
      }
    }

    // Start and end dates styles
    & .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start {
      ${({ isStartDate }) => !isStartDate && focusStyles}
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & .react-datepicker__day--range-end {
      ${({ isStartDate }) => isStartDate && focusStyles}
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    & .react-datepicker__day--selected,
    .react-datepicker__day--range-end,
    .react-datepicker__day--range-start {
      ${focusStyles}
    }

    // Disabled styles
    & .react-datepicker__day--disabled {
      ${weekendStyles}
      color: ${palette.common.LFGray};
      &:hover {
        ${weekendStyles}
        color: ${palette.common.LFGray};
        cursor: not-allowed;
      }
    }
  }
`;

export const StyledDateContainer = styled.div<{
  isSingleDate: boolean;
}>`
  font-family: ${(props) => props.theme.fontFamily};
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%), 0 8px 8px 0 rgb(0 0 0 / 24%);
  position: absolute;
  top: ${({ isSingleDate }) => (isSingleDate ? '0px' : '52px')};
  padding: ${`${spacing.small} 0px`};
`;
