// @Helpers
import {
  getMinimumDate,
  getMaximumPickupDate,
} from 'utils/ExpirationMessageUtils';

// @Types
import { LoadLocation, EquipmentTypeOptions } from 'types/quoteTypes';

export const isValidForm = (
  truckType: string | null,
  equipmentType: EquipmentTypeOptions | null,
  pickupLocation: LoadLocation | null,
  deliveryLocation: LoadLocation | null,
  pickupDate: Date | null,
) => {
  return (
    !!truckType?.length &&
    pickupLocation &&
    deliveryLocation &&
    pickupDate &&
    !!equipmentType?.length
  );
};

export const getValidPickupDates = () => {
  return {
    minimumPickupDate: getMinimumDate(),
    maximumPickupDate: getMaximumPickupDate(),
  };
};
