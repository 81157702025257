import React from 'react';
import ContentLoader from 'react-content-loader';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Icons
import DELIVERY_ICON from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';
import FTL_ICON from '@arrive/ui-icons/svg/ic_drop_trailer.svg';
import LOAD_ICON from '@arrive/ui-icons/svg/ic_load_detail.svg';
import MILES_ICON from '@arrive/ui-icons/svg/ic_miles.svg';
import PICKUP_ICON from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';
import REFRIGERATED_ICON from '@arrive/ui-icons/svg/ic_dry_van.svg';

// @Styles
import { palette } from 'theme/Theme';
import {
  ShipmentDestination,
  ShipmentIconsContainer,
  ShipmentPickupDeliveryContainer,
  ShipmentPickupDeliveryDetails,
  ShipmentReferenceNumbersContainer,
} from './ShipmentDetailsPanelStyles';
import {
  ShipmentDetailsPanelTabContentContainer,
  ShipmentDetailsTabContainer,
  SummaryContainer,
  SummaryDetails,
  SummaryTitles,
} from './ShipmentDetailsPanelTabs/ShipmentDetailsPanelTabStyles';

export const ShipmentDetailsPanelHeaderContentLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={550}
      height={60}
      viewBox="0 0 550 60"
      backgroundColor={palette.common.LFGray}
      foregroundColor={palette.common.axle}
      id="header-loading"
    >
      <rect x="175" y="0" rx="3" ry="3" width="200" height="36" />
      <rect x="75" y="40" rx="3" ry="3" width="400" height="18" />
    </ContentLoader>
  );
};

export const ShipmentPickupDeliveryContentLoader = () => {
  return (
    <ShipmentPickupDeliveryContainer>
      <div>
        <ShipmentDestination>
          <PICKUP_ICON />
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="pickup-city-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </ShipmentDestination>
        <ShipmentPickupDeliveryDetails>
          <ContentLoader
            speed={2}
            width={150}
            height={40}
            viewBox="0 0 150 40"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="pickup-destination-loading"
          >
            <rect x="0" y="1" rx="3" ry="3" width="150" height="16" />
            <rect x="0" y="20" rx="3" ry="3" width="150" height="16" />
          </ContentLoader>
        </ShipmentPickupDeliveryDetails>
      </div>
      <div>
        <ShipmentDestination>
          <DELIVERY_ICON />
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="delivery-city-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </ShipmentDestination>
        <ShipmentPickupDeliveryDetails>
          <ContentLoader
            speed={2}
            width={150}
            height={40}
            viewBox="0 0 150 40"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="delivery-destination-loading"
          >
            <rect x="0" y="1" rx="3" ry="3" width="150" height="16" />
            <rect x="0" y="20" rx="3" ry="3" width="150" height="16" />
          </ContentLoader>
        </ShipmentPickupDeliveryDetails>
      </div>
    </ShipmentPickupDeliveryContainer>
  );
};

export const ShipmentDetailsShipmentSummaryContentLoader = () => {
  return (
    <>
      <ShipmentReferenceNumbersContainer>
        <Typography variant="overline" color={palette.common.smokey} margin="0">
          Reference #s
        </Typography>
        <ContentLoader
          speed={2}
          width={245}
          height={20}
          viewBox="0 0 245 20"
          backgroundColor={palette.common.tandem}
          foregroundColor={palette.common.chrome}
          id="shipment-summary-reference-numbers-loading"
        >
          <rect x="0" y="0" rx="3" ry="3" width="245" height="20" />
        </ContentLoader>
      </ShipmentReferenceNumbersContainer>
      <ShipmentIconsContainer>
        <div>
          <MILES_ICON />
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-miles-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </div>
        <div>
          <FTL_ICON />
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-mode-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </div>
        <div>
          <REFRIGERATED_ICON />
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox="0 0 100 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-equipment-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </div>
      </ShipmentIconsContainer>
    </>
  );
};

export const ShipmentDetailsPanelActivityTabContentLoader = () => {
  return (
    <ShipmentDetailsPanelTabContentContainer>
      <ContentLoader
        speed={2}
        width={200}
        height={60}
        viewBox="0 0 200 60"
        backgroundColor={palette.common.tandem}
        foregroundColor={palette.common.chrome}
        id="activity-tab-loading"
      >
        <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
        <rect x="28" y="0" rx="3" ry="3" width="145" height="20" />
        <rect x="28" y="28" rx="3" ry="3" width="145" height="16" />
      </ContentLoader>
    </ShipmentDetailsPanelTabContentContainer>
  );
};

export const ShipmentDetailsTabContentLoader = () => {
  return (
    <ShipmentDetailsTabContainer data-testid="shipment-tab-loading">
      <SummaryContainer>
        <LOAD_ICON />
        <Typography variant="subtitle1">Shipment Details</Typography>
      </SummaryContainer>
      <SummaryDetails>
        <div>
          <SummaryTitles variant="overline" as="div">
            Total Rate
          </SummaryTitles>
          <ContentLoader
            speed={2}
            width={159}
            height={20}
            viewBox="0 0 150 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-reference-numbers-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
          </ContentLoader>
        </div>
        <div>
          <SummaryTitles variant="overline" as="div">
            Cargo Value
          </SummaryTitles>
          <ContentLoader
            speed={2}
            width={159}
            height={20}
            viewBox="0 0 150 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-reference-numbers-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
          </ContentLoader>
        </div>
      </SummaryDetails>
      <SummaryDetails>
        <div>
          <SummaryTitles variant="overline" as="div">
            Total Weight
          </SummaryTitles>
          <ContentLoader
            speed={2}
            width={159}
            height={20}
            viewBox="0 0 150 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-reference-numbers-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
          </ContentLoader>
        </div>
        <div>
          <SummaryTitles variant="overline" as="div">
            Requirements
          </SummaryTitles>
          <ContentLoader
            speed={2}
            width={159}
            height={20}
            viewBox="0 0 150 20"
            backgroundColor={palette.common.tandem}
            foregroundColor={palette.common.chrome}
            id="shipment-summary-reference-numbers-loading"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
          </ContentLoader>
        </div>
      </SummaryDetails>
    </ShipmentDetailsTabContainer>
  );
};
