import React from 'react';

// @Style
import styled from '@emotion/styled';
import theme from 'theme/Theme';
import { spacing } from 'theme/Units';

const StyledButton = styled.button<{
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: ${`0px ${spacing.small}`};
  height: 36px;
  background-color: ${theme.palette.common.ethanol};
  color: ${({ disabled }) =>
    disabled ? theme.palette.common.LFGray : theme.palette.common.burnout};
  border: ${({ disabled }) =>
    disabled
      ? `2px solid ${theme.palette.common.chrome}`
      : `2px solid ${theme.palette.common.burnout}`};

  &:hover {
    background-color: ${({ disabled }) =>
    !disabled && theme.palette.common.burnout};
    color: ${({ disabled }) => !disabled && theme.palette.common.ethanol};
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }
  &:focus {
    background-color: ${({ disabled }) =>
    !disabled && theme.palette.common.chrome};
  }
`;

interface SecondaryButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  dataTestId: string;
  ariaLabel: string;
}

const SecondaryButton = ({
  children,
  onClick,
  isDisabled,
  dataTestId,
  ariaLabel,
}: SecondaryButtonProps) => {
  return (
    <StyledButton
      disabled={isDisabled}
      onClick={onClick}
      data-testid={`secondary-button-${dataTestId}`}
      aria-label={`${ariaLabel}-button`}
    >
      {children}
    </StyledButton>
  );
};

export default SecondaryButton;
