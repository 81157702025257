import Button from '@arrive/button';
import styled from '@emotion/styled';

// @Theme
import theme from 'theme/Theme';

export const QuoteResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuoteInformationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PickupDeliveryContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${theme.spacing.normal} ${theme.spacing.xxlarge};
  justify-content: space-between;

  svg {
    transform: scale(1);
  }
`;

export const QuoteMapContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;

  .mapboxgl-map {
    min-height: 400px;
  }

  > a {
    padding: ${theme.spacing.small};
    font-size: ${theme.fontSizes.medium};
    margin-left: auto;
  }
`;

export const QuoteInformationContainer = styled.div`
  padding: ${theme.spacing.large};
  border-left: ${`solid 1px ${theme.palette.common.chrome}`};
  width: 40%;
  background-color: ${theme.palette.common.roadhog};
  display: flex;
  flex-direction: column;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RateResultsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${theme.spacing.large};
`;

export const SubmitButton = styled(Button)`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const TruckInformation = styled.div<{ numberOfElements: number }>`
  display: grid;
  grid-template-columns: ${({ numberOfElements }) =>
    `repeat(${numberOfElements}, 1fr)`};
  justify-items: center;
  color: ${theme.palette.common.ethanol};
  border-top: solid 1px ${theme.palette.common.burnout};
  border-bottom: solid 1px ${theme.palette.common.burnout};
  padding: ${theme.spacing.gutter};

  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.xsmall};
    > svg {
      fill: ${theme.palette.common.ethanol};
    }
  }
`;

export const CopyQuoteReference = styled.div`
  width: 55px;
  cursor: pointer;
  svg {
    fill: ${theme.palette.common.arrive};
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.large} 0;
`;

export const QuoteIdContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: ${theme.spacing.xsmall};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xsmall};
  justify-content: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
