import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import Map, { Layer, Marker, Source } from 'react-map-gl';

// @Helpers
import { getLatitudeAndLongitude, getZoomLevel } from './QuoteMapHelpers';

// @Hooks
import { useGetRoute } from './useGetRoute';

// @Icons
import PickupImage from 'icons/AngleArrowUp.svg';
import DeliveryImage from 'icons/AngleArrowDown.svg';

// @Types
import { Position } from 'geojson';

interface QuoteMapProps {
  pickupGeolocation: string;
  deliveryGeolocation: string;
  hasQuoteRate: boolean;
  totalMiles: number;
}

mapboxgl.accessToken = process.env.MAPBOX_TOKEN || '';

const QuoteMap = ({
  pickupGeolocation,
  deliveryGeolocation,
  hasQuoteRate,
  totalMiles,
}: QuoteMapProps) => {
  const { lat: pickupLat, lng: pickupLng } =
    getLatitudeAndLongitude(pickupGeolocation);
  const { lat: deliveryLat, lng: deliveryLng } =
    getLatitudeAndLongitude(deliveryGeolocation);
  const avgLat = (pickupLat + deliveryLat) / 2;
  const avgLng = (pickupLng + deliveryLng) / 2;

  const { mutate: getRoute, data: route } = useGetRoute();

  useEffect(() => {
    if (hasQuoteRate) {
      getRoute({ pickupLat, pickupLng, deliveryLat, deliveryLng });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupGeolocation, deliveryGeolocation]);

  const renderMapElements = () => {
    return (
      <div data-testid="quote-map">
        <Marker
          style={{ height: '30px', width: '30px' }}
          longitude={pickupLng}
          latitude={pickupLat}
          anchor="center"
        >
          <PickupImage />
        </Marker>
        <Marker
          style={{ height: '30px', width: '30px' }}
          longitude={deliveryLng}
          latitude={deliveryLat}
          anchor="center"
        >
          <DeliveryImage />
        </Marker>
        {route!.geometry.coordinates.map(
          (coordinateRoute: Position[], index: number) => (
            <Source
              key={index}
              id="my-data"
              type="geojson"
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: coordinateRoute,
                },
              }}
            >
              <Layer
                type="line"
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': '#2aaae2',
                  'line-width': 4,
                }}
              />
            </Source>
          ),
        )}
      </div>
    );
  };

  return (
    <Map
      initialViewState={{
        longitude: avgLng,
        latitude: avgLat,
        zoom: getZoomLevel(totalMiles),
      }}
      mapStyle="mapbox://styles/arrivelogistics/ckhksfsvt022l19phx9nyervn"
    >
      {hasQuoteRate && !!route ? renderMapElements() : null}
    </Map>
  );
};

export default QuoteMap;
