import Button from '@arrive/button';
import styled from '@emotion/styled';
import { spacing } from 'theme/Units';

export const LoadingButton = styled(Button)`
  margin-left: ${spacing.normal};
  display: flex;
  align-items: center;
  justify-content: center;
`;
