import styled from '@emotion/styled';
import { palette } from 'theme/Theme';
import { fontSizes, spacing } from 'theme/Units';
import { math } from 'polished';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactInformationContainer = styled.div`
  padding: 0 ${math(`${spacing.xlarge} * 3.5`)};
`;

export const ExternalInformationContainer = styled.div`
  align-items: start;
  bottom: 0;
  background-color: ${palette.common.tandem};
  display: flex;
  flex-direction: column;
  height: 185px;
  justify-content: space-between;
  padding: 0 ${math(`${spacing.xlarge} * 3.5`)};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: space-between;
  padding-top: ${spacing.normal};
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-top: 1px solid ${palette.common.chrome};
  width: 100%;
  height: ${spacing.xlarge};
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 64px;
`;

export const FooterLink = styled.a`
  color: ${palette.common.axle};
  text-decoration: none;
  font-size: ${fontSizes.small};

  &:hover {
    color: ${palette.common.arrive};
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100px;
`;

export const SocialMediaLink = styled.a`
  padding: 0 ${spacing.xsmall};
  fill: ${palette.common.axle};
  &:hover {
    fill: ${palette.common.arrive};
  }
`;
