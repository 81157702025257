import React, { ReactElement, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';
import { SquareLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Components
import { ShipmentDetailsPanelActivityTabContentLoader } from 'components/Shipments/ShipmentDetailsPanel/ShipmentDetailsContentLoaders';
import ShipmentDetailsLastDestination from './ShipmentDetailsLastDestination';

// @Hooks
import { useQueryParams } from 'hooks/useReactRouterQueries';
import { useGetTrackingDetailsByLoadId } from 'components/Shipments/useGetShipments';

// @Icons
import SCROLL_TO_TOP_ICON from '@arrive/ui-icons/svg/ic_drawer.svg';

// @Styles
import { palette } from 'theme/Theme';
import {
  ShipmentDetailsDefaultTrackingContent,
  ShipmentDetailsPanelTabContentContainer,
  ActivityDot,
  ActivityListItem,
  TrackingDetailsScrollToTop,
  TimeLineContainer,
} from '../ShipmentDetailsPanelTabStyles';

// @Types
import {
  ShipmentDetailsByLoadNumber,
  ShipmentDetailsTrackingInfo,
} from 'components/Shipments/ShipmentPage.types';

// @Utils
import { getDateStampForGivenIanaTimeZoneAndFormat } from 'utils/utils';
import { formatPaginatedTrackingData } from 'components/Shipments/ShipmentsHelpers';

const DefaultNoTrackingDetailsText = (): ReactElement => {
  return (
    <ShipmentDetailsDefaultTrackingContent>
      <Typography variant="subtitle1">
        Your shipment has been received.
      </Typography>
      <Typography variant="body2" margin="0">
        Tracking information will be available once a driver has been
        dispatched.
      </Typography>
    </ShipmentDetailsDefaultTrackingContent>
  );
};

const ShipmentDetailsPanelActivityTab = ({
  isLoadingShipmentDetails,
}: {
  isLoadingShipmentDetails: boolean;
}): ReactElement => {
  const queryClient = useQueryClient();

  // router queries
  const { loadNumber } = useQueryParams();
  const shipment = queryClient.getQueryData([
    'shipmentDetailsByLoadId',
    loadNumber,
  ]) as ShipmentDetailsByLoadNumber;

  // ref to fetch tracking data
  const { ref, inView } = useInView();

  // ref to scroll to top
  const scrollToTopRef = useRef<HTMLDivElement>(null);

  // fetch tracking info
  const {
    data: TrackingActivityPagedData,
    isInitialLoading: isLoadingTrackingDetails,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetTrackingDetailsByLoadId(loadNumber as string);

  // readable Tracking Information
  const FormattedTrackingInformation = formatPaginatedTrackingData(
    TrackingActivityPagedData?.pages,
  );

  const TrackingActivity = FormattedTrackingInformation.trackingData;

  // filter the data
  const filteredTrackingActivity = TrackingActivity?.filter(
    (activity) => activity.CheckCallType !== 'Location Update',
  );

  const handleScrollToTop = () => {
    scrollToTopRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // loading indicator
  if (isLoadingShipmentDetails || isLoadingTrackingDetails) {
    return <ShipmentDetailsPanelActivityTabContentLoader />;
  }

  // default view
  if (TrackingActivity?.length === 0) {
    return <DefaultNoTrackingDetailsText />;
  }

  const lastDestination =
    shipment.Status === 'Delivered' ? undefined : TrackingActivity[0];

  return (
    <ShipmentDetailsPanelTabContentContainer>
      <div ref={scrollToTopRef} />
      <ShipmentDetailsLastDestination lastDestination={lastDestination} />
      <TimeLineContainer>
        <ul>
          {filteredTrackingActivity?.map(
            (trackingInfo: ShipmentDetailsTrackingInfo, idx) => {
              const lastElement = idx === filteredTrackingActivity.length - 1;
              return (
                <ActivityListItem
                  key={trackingInfo.id}
                  ref={lastElement ? ref : null}
                  data-testid={`tracking-item-${idx}`}
                >
                  <ActivityDot></ActivityDot>
                  <div>
                    <Typography variant="subtitle1" as="div">
                      {trackingInfo.CheckCallType}
                    </Typography>
                    <Typography variant="subtitle2" as="div">
                      {trackingInfo.City}, {trackingInfo.StateCode}{' '}
                      {trackingInfo.PostalCode}
                    </Typography>
                    {trackingInfo.IanaTimeZone && trackingInfo.EventDate ? (
                      <Typography
                        variant="body2"
                        color={palette.common.smokey}
                        as="div"
                        data-testid={`tracking-time-stamp-${idx}`}
                      >
                        {getDateStampForGivenIanaTimeZoneAndFormat(
                          trackingInfo.EventDate,
                          'ccc, MMM dd, HH:mm ZZZZ',
                          trackingInfo.IanaTimeZone,
                        )}
                      </Typography>
                    ) : null}
                    {lastElement && isFetchingNextPage && (
                      <div data-testid="loading-paged-data-for-tracking">
                        <SquareLoadingIndicator />
                      </div>
                    )}
                  </div>
                </ActivityListItem>
              );
            },
          )}
          <TrackingDetailsScrollToTop>
            <button
              aria-label="scroll to top"
              onClick={handleScrollToTop}
              data-testid="scroll-to-top"
            >
              <SCROLL_TO_TOP_ICON />
            </button>
          </TrackingDetailsScrollToTop>
        </ul>
      </TimeLineContainer>
    </ShipmentDetailsPanelTabContentContainer>
  );
};

export default ShipmentDetailsPanelActivityTab;
