import React, { ReactElement } from 'react';

// @Arrive
import TabBar, { TabBarHeader, TabBarContent } from '@arrive/tab-bar';

// @Components
import ShipmentDetailsPanelShipmentTab from './ShipmentDetailsShipmentTab/ShipmentDetailsPanelShipmentTab';
import ShipmentDetailsPanelActivityTab from './ShipmentDetailsActivityTab/ShipmentDetailsPanelActivityTab';

// @Styles
import { ShipmentDetailsPanelTabContainer } from './ShipmentDetailsPanelTabStyles';

const ShipmentDetailsPanelTabs = ({
  isLoadingShipmentDetails,
}: {
  isLoadingShipmentDetails: boolean;
}): ReactElement => {
  return (
    <ShipmentDetailsPanelTabContainer>
      <TabBar
        initialSelectedTab="activity"
        tabs={
          <>
            <TabBarHeader title="Activity" tabId="activity" />
            <TabBarHeader title="Shipment Details" tabId="shipment-details" />
          </>
        }
      >
        <TabBarContent tabId="activity">
          <ShipmentDetailsPanelActivityTab
            isLoadingShipmentDetails={isLoadingShipmentDetails}
          />
        </TabBarContent>
        <TabBarContent tabId="shipment-details">
          <ShipmentDetailsPanelShipmentTab
            isLoadingShipmentDetails={isLoadingShipmentDetails}
          />
        </TabBarContent>
      </TabBar>
    </ShipmentDetailsPanelTabContainer>
  );
};

export default ShipmentDetailsPanelTabs;
