import React from 'react';
import { math } from 'polished';
import styled from '@emotion/styled';
import { format } from 'date-fns';

// @Common components
import TitleSubtitle from 'commonComponents/TitleSubtitle';

// @Constants
import { EquipmentTypes } from 'constants/EquipmentType';

// @Theme
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';

// @Types
import { ShipmentDetails } from 'types/Shipment.types';

// @Icons
import ArrowPickup from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';
import ArrowDelivery from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';

const PickupDeliverySummaryContainer = styled.div`
  margin-top: ${math(`${spacing.medium} * 3`)};
  padding: ${spacing.gutter};
  border: solid 1px ${palette.common.chrome};
  display: flex;
  flex-direction: column;
  gap: ${spacing.gutter};
  height: fit-content;
  min-width: 318px;
`;

const PickupDeliverySummary = ({
  selectedQuote,
}: {
  selectedQuote: ShipmentDetails;
}) => {
  return (
    <PickupDeliverySummaryContainer>
      <TitleSubtitle
        overline="Your Rate"
        title={`$ ${selectedQuote.allInRate.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })}`}
        icon={<></>}
        variants={{ title: 'h2' }}
      />
      <TitleSubtitle
        overline="Pickup"
        title={selectedQuote.pickup?.cityState}
        subtitle={format(selectedQuote.pickupDate * 1000, 'MMMM dd, yyyy')}
        icon={<ArrowPickup />}
      />
      <TitleSubtitle
        overline="Delivery"
        title={selectedQuote.delivery?.cityState}
        icon={<ArrowDelivery />}
      />
      <TitleSubtitle
        dataTestId="pickupDelivery-equipmentType"
        title={
          selectedQuote.equipmentType
            ? EquipmentTypes[selectedQuote.equipmentType]?.displayName
            : ''
        }
        overline="EQ"
        icon={<></>}
      />
      <TitleSubtitle
        title={selectedQuote.shipmentType}
        overline="MODE"
        icon={<></>}
      />
    </PickupDeliverySummaryContainer>
  );
};

export default PickupDeliverySummary;
