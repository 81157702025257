import React, { useMemo } from 'react';

// @Constants
import { DateSortOptions } from 'constants/DateSort';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';
import SortByDropdown from 'commonComponents/Dropdowns/SortByDropdown';
import ReferenceNumberSearch from 'commonComponents/Search/ReferenceNumberSearch';

// @Components
import ShipmentDateFilters from './ShipmentDateFilters';
import ShipmentStatusFilter from './ShipmentStatusFilter';

// @Hooks
import { useSetQueryParams } from 'hooks/useReactRouterQueries';

// @Styles
import {
  ClearFiltersButton,
  FilterOptions,
  FilterResults,
  FiltersContainer,
} from 'styles/Filters';

// @Types
import {
  ShipmentFiltersActionKind,
  ShipmentFiltersProps,
  ShipmentTableFilterOptions,
} from 'components/Shipments/ShipmentPage.types';
import type { SortByOptionsType } from 'commonComponents/Dropdowns/DropdownTypes.types';

const ShipmentFilters = ({
  handleClearAllFilters,
  state,
  dispatch,
  totalShipments,
  queryParams,
}: ShipmentFiltersProps) => {
  // query params
  const setQueryParams = useSetQueryParams({ arrayFormat: 'repeat' });
  const {
    OrderByColumn = 'EarliestPickup',
    ReferenceNumber: QueryParamsRefNumber,
  } = queryParams;

  const handleApplyFilters = (
    filterOption: keyof ShipmentTableFilterOptions,
    value: string,
  ) => {
    setQueryParams({
      ...queryParams,
      [filterOption]: value,
    });
  };

  const memoizedHandleApplyFilters = useMemo(
    () => handleApplyFilters,
    [QueryParamsRefNumber],
  );

  const sortBy = DateSortOptions.find(
    (option) => option.value === (OrderByColumn as string),
  );

  return (
    <FiltersContainer>
      <FilterOptions>
        <ReferenceNumberSearch
          handleApply={memoizedHandleApplyFilters}
          referenceNumber={state.ReferenceNumber}
          setReferenceNumber={(referenceNumber: string) =>
            dispatch({
              type: ShipmentFiltersActionKind.SET_REFERENCE_NUMBER,
              payload: referenceNumber,
            })
          }
        />
        <ShipmentDateFilters
          dispatch={dispatch}
          dateFilters={{ date: state.PickupDate, dateEnd: state.PickupDateEnd }}
          stopType="Pickup"
        />
        <ShipmentDateFilters
          dispatch={dispatch}
          dateFilters={{
            date: state.DeliveryDate,
            dateEnd: state.DeliveryDateEnd,
          }}
          stopType="Delivery"
        />
        <ShipmentStatusFilter dispatch={dispatch} statuses={state.Statuses} />
        <ClearFiltersButton onClick={handleClearAllFilters}>
          Clear all
        </ClearFiltersButton>
      </FilterOptions>
      <FilterResults>
        <Typography variant="body1">
          {totalShipments} {totalShipments === 1 ? 'Shipment' : 'Shipments'}
        </Typography>
        <SortByDropdown
          dataTestId="shipments-sort-by"
          aria-label="shipments-sort-by"
          value={sortBy}
          onChange={(e) =>
            handleApplyFilters('OrderByColumn', (e as SortByOptionsType).value)
          }
          options={DateSortOptions}
        />
      </FilterResults>
    </FiltersContainer>
  );
};

export default ShipmentFilters;
