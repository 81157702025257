import React, { ReactElement } from 'react';
import { useQueryClient, QueryClient } from '@tanstack/react-query';
import { nanoid } from 'nanoid';

//@arrive
import AddIcon from '@arrive/ui-icons/svg/ic_plus.svg';
import MinusIcon from '@arrive/ui-icons/svg/ic_minus.svg';
import TextEntry from '@arrive/text-entry';
import CircularButton from '@arrive/circular-button';

// @CommonComponents
import SearchableDropdown from 'commonComponents/Dropdowns/SearchableDropdown';
import Typography from 'commonComponents/Typography/Typography';

// @Constants
import { initialReferenceNumberState } from 'components/ShipmentForm/useShipmentForm';

// @Styles
import {
  CircularButtonContainer,
  FormContainer,
  NotesContainer,
  ShipmentFormSubHeading,
  ReferenceContainer,
} from '../ShipmentFormStyles';

// @Types
import { PickupDeliveryNotesProps } from '../ShipmentForm.types';
import { ReferenceNumberType } from 'types/quoteTypes';
import {
  ShipmentFormEnums,
  ReferenceNumberWithNanoId,
  PickupDeliveryType,
} from 'types/Shipment.types';

const PickupDeliveryNotes = ({
  handlePickupDeliveryInputChange,
  isLoadingShipmentFormEnums,
  notes,
  referenceNumbers = { [nanoid(10)]: initialReferenceNumberState },
  type,
}: PickupDeliveryNotesProps): ReactElement => {
  const queryClient: QueryClient = useQueryClient();
  const referenceNumbersLength = Object.values(referenceNumbers).length;
  const ShipmentFormEnums = queryClient.getQueryData([
    'shipmentFormEnums',
  ]) as ShipmentFormEnums;

  const handleAddReferenceNumber = () => {
    handlePickupDeliveryInputChange('referenceNumbers', {
      ...referenceNumbers,
      [nanoid(10)]: initialReferenceNumberState,
    });
  };

  const handleRemoveReferenceNumber = (id: string) => {
    delete referenceNumbers[id];
    return handlePickupDeliveryInputChange(
      'referenceNumbers',
      referenceNumbers,
    );
  };

  const handleReferenceNumberChange = (
    key: keyof ReferenceNumberWithNanoId,
    field: 'type' | 'number',
    value: string | null,
  ) => {
    const referenceNumberToBeUpdated = {
      ...referenceNumbers[key],
      [field]: value,
    };

    handlePickupDeliveryInputChange('referenceNumbers', {
      ...referenceNumbers,
      [key]: referenceNumberToBeUpdated,
    });
  };

  return (
    <FormContainer>
      <ShipmentFormSubHeading>
        {type === 'pickup' ? 'Pickup Notes' : 'Delivery Notes'}
      </ShipmentFormSubHeading>
      <NotesContainer
        data-testid={`${type}-notes`}
        label={!!notes?.length ? '' : 'Additional notes or comments'}
        name="notes"
        onChange={(e) =>
          handlePickupDeliveryInputChange(
            e.target.name as keyof PickupDeliveryType,
            e.target.value,
          )
        }
        value={notes || ''}
        maxLength={300}
      />

      {Object.keys(referenceNumbers).map((refId) => (
        <ReferenceContainer length={referenceNumbersLength} key={refId}>
          <SearchableDropdown
            aria-label={`reference-number-type-${refId}`}
            dataTestId={`reference-number-type-${refId}`}
            getOptionLabel={(option) =>
              (option as ReferenceNumberType).displayName
            }
            getOptionValue={(option) =>
              (option as ReferenceNumberType).enumValue
            }
            isClearable
            isLoading={isLoadingShipmentFormEnums}
            options={ShipmentFormEnums?.referenceNumberTypes}
            placeholder="Reference Type"
            required
            value={referenceNumbers[refId].type || null}
            onChange={(e) =>
              handleReferenceNumberChange(refId, 'type', e as string)
            }
          />
          <TextEntry
            data-testid={`reference-number-${refId}`}
            label="Ref #"
            maxLength={250}
            name="number"
            onChange={(e) =>
              handleReferenceNumberChange(
                refId,
                e.target.name as 'number',
                e.target.value,
              )
            }
            required
            value={referenceNumbers[refId].number || ''}
          />
          {referenceNumbersLength >= 2 && (
            <CircularButton onClick={() => handleRemoveReferenceNumber(refId)}>
              <MinusIcon />
            </CircularButton>
          )}
        </ReferenceContainer>
      ))}
      {referenceNumbersLength !== 4 && (
        <CircularButtonContainer>
          <CircularButton
            onClick={handleAddReferenceNumber}
            data-testid="add-new-reference"
          >
            <AddIcon />
          </CircularButton>
          <Typography>Add another reference</Typography>
        </CircularButtonContainer>
      )}
    </FormContainer>
  );
};

export default PickupDeliveryNotes;
