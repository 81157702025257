import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

// @API
import { ShipyardEndpoints } from 'api/endpoints';

// @arrive
import { ToastContext } from '@arrive/toasts';

// @Hooks
import useAxios from './useAxios';

const useValidatePhoneNumber = (phoneNumber: string | null | undefined) => {
  const addToastNotification = useContext(ToastContext);
  const axios = useAxios();

  const fetchValidation = async () => {
    const { data } = await axios.get(
      ShipyardEndpoints.address.validatePhoneNumber(phoneNumber!),
    );
    return data.isValid;
  };

  const { data: isValidPhoneNumber, isInitialLoading: isLoadingPhoneNumber } =
    useQuery(['isValidPhoneNumber', phoneNumber], fetchValidation, {
      enabled: !!phoneNumber && phoneNumber.length > 9,
      initialData: true,

      onError: () => {
        addToastNotification({
          id: 'address-suggestion-error',
          type: 'error',
          content: 'Error validating phone number',
        });
      },
    });

  return {
    isValidPhoneNumber,
    isLoadingPhoneNumber,
  };
};

export default useValidatePhoneNumber;
