import useAxios from './useAxios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// @Types
import { TimeZones } from 'types/Shipment.types';
import qs from 'qs';
import { ShipyardEndpoints } from 'api/endpoints';

const useGetTimeZones = (
  cityStateArray: string[],
): UseQueryResult<TimeZones> => {
  const axios = useAxios();

  return useQuery(
    ['locationTimezones'],
    async () => {
      const cityStateCodes = qs.stringify(
        {
          cityStateCodes: cityStateArray,
        },
        { arrayFormat: 'repeat' },
      );
      const { data } = await axios.get(
        ShipyardEndpoints.address.getTimezones(cityStateCodes),
      );
      return data;
    },
    {
      enabled: !!cityStateArray.length,
    },
  );
};

export default useGetTimeZones;
