import React, { ReactElement, memo } from 'react';

// @CommonComponents
import TimePicker from 'commonComponents/DateTimePickers/TimePicker';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import PickupDeliveryReadOut from './PickupDeliveryReadOut';
import Facility from './Facility';
import PickupDeliveryNotes from './PickupDeliveryNotes';
import Scheduling from './Scheduling';
import ShipmentFormSectionFooter from '../ShipmentFormSectionFooter';
import Appointments from './Appointments';

// @Constants
import { initialReferenceNumberState } from 'components/ShipmentForm/useShipmentForm';

// @Dev Data
import { PickupDeliveryFormDevData } from 'dev/ShipmentFormDevData';

// @Helpers
import { confirmValidPhoneNumber } from './PickupDeliveryFormHelpers';

// @Hooks
import useValidatePhoneNumber from 'hooks/useValidatePhoneNumber';
// @Libraries
import { format } from 'date-fns';

// @Styles
import { spacing } from 'theme/Units';
import { FacilityHoursContainer } from './PickupDeliveryFormStyles';
import {
  ShipmentSectionWrapper,
  ShipmentFormSubHeading,
} from '../ShipmentFormStyles';

// @Types
import { FacilityHoursProps, PickupFormProps } from '../ShipmentForm.types';
import { AppointmentsType, PickupDeliveryType } from 'types/Shipment.types';

// @Utils
import { buildObjectWithNanoId } from 'utils/utils';
import {
  getDefaultAppointment,
  validatePickupDeliveryForm,
} from '../ShipmentFormHelpers';

const clearedPickupDeliveryState = {
  address1: '',
  address2: '',
  cityState: '',
  facilityName: '',
  facilityClose: null,
  facilityOpen: null,
  notes: '',
  postalCode: '',
  referenceNumbers: buildObjectWithNanoId([], initialReferenceNumberState),
  schedulingContact: {
    fullName: '',
    phoneNumber: '',
    email: '',
  },
  schedulingType: null,
  stopType: 'Pickup',
} as PickupDeliveryType;

export const FacilityHours = ({
  facilityOpen,
  facilityClose,
  handlePickupDeliveryInputChange,
  timezone,
  ...rest
}: FacilityHoursProps) => {
  return (
    <FacilityHoursContainer>
      <TimePicker
        ariaLabel="Open Time"
        dataTestId="open-hours"
        onChange={(e) =>
          e
            ? handlePickupDeliveryInputChange(
                'facilityOpen',
                format(e, 'HH:mm'),
              )
            : null
        }
        timeFormat="HH:mm"
        placeholderText="Open Time"
        value={facilityOpen || ''}
        open={rest.facilityHourOpen}
      />
      <Typography margin={`${spacing.normal}`}>to</Typography>
      <TimePicker
        ariaLabel="Close Time"
        dataTestId="close-hours"
        onChange={(e) =>
          e
            ? handlePickupDeliveryInputChange(
                'facilityClose',
                format(e, 'HH:mm'),
              )
            : null
        }
        timeFormat="HH:mm"
        placeholderText="Close Time"
        value={facilityClose || ''}
        open={rest.facilityHourClose}
      />
      <Typography margin={`${spacing.normal}`} data-testid="facility-timezone">
        {timezone}
      </Typography>
    </FacilityHoursContainer>
  );
};

const PickupDeliveryForm = ({
  handleEditPickupDelivery,
  handleSectionSubmit,
  handleShipmentFormInputChange,
  isLoadingShipmentFormEnums,
  pickupDeliveryState,
  pickupDeliveryType,
  readOnly = false,
  pickupDate,
  timezone,
  setIsNewFacility,
  setShipmentDetails,
  shipmentDetails,
  title,
}: PickupFormProps): ReactElement => {
  const handlePickupDeliveryInputChange = (
    field: keyof PickupDeliveryType,
    value: unknown,
  ) => {
    const updatedChanges = {
      ...pickupDeliveryState,
      appointment:
        field === 'schedulingType' && value !== 'APPOINTMENT'
          ? getDefaultAppointment(pickupDeliveryType, pickupDate)
          : pickupDeliveryState.appointment,
      [field]: value,
    };
    handleShipmentFormInputChange(pickupDeliveryType, updatedChanges);
  };

  const { isValidPhoneNumber, isLoadingPhoneNumber } = useValidatePhoneNumber(
    pickupDeliveryState.schedulingContact.phoneNumber,
  );

  const handleClear = () => {
    handleShipmentFormInputChange(pickupDeliveryType, {
      ...clearedPickupDeliveryState,
      postalCode: pickupDeliveryState.postalCode,
      cityState: pickupDeliveryState.cityState,
    });
  };

  if (readOnly) {
    return (
      <ShipmentSectionWrapper>
        <PickupDeliveryReadOut
          pickupDelivery={pickupDeliveryState}
          handleEditPickupDelivery={handleEditPickupDelivery}
          title={title}
          timezone={timezone}
        />
      </ShipmentSectionWrapper>
    );
  }

  return (
    <ShipmentSectionWrapper>
      <Typography variant="h2">{title}</Typography>
      <Facility
        address1={pickupDeliveryState.address1}
        address2={pickupDeliveryState.address2}
        cityState={pickupDeliveryState.cityState}
        facilityId={pickupDeliveryState.facilityId}
        facilityName={pickupDeliveryState.facilityName}
        handlePickupDeliveryInputChange={handlePickupDeliveryInputChange}
        isValidatedLocation={pickupDeliveryState.isValidatedLocation}
        pickupDeliveryType={pickupDeliveryType}
        postalCode={pickupDeliveryState.postalCode}
        setIsNewFacility={setIsNewFacility}
        setShipmentDetails={setShipmentDetails}
        shipmentDetails={shipmentDetails}
      />

      <ShipmentFormSubHeading>Facility Hours</ShipmentFormSubHeading>
      <FacilityHours
        handlePickupDeliveryInputChange={handlePickupDeliveryInputChange}
        facilityOpen={pickupDeliveryState.facilityOpen}
        facilityClose={pickupDeliveryState.facilityClose}
        timezone={timezone}
      />
      <Scheduling
        handlePickupDeliveryInputChange={handlePickupDeliveryInputChange}
        schedulingType={pickupDeliveryState.schedulingType}
        schedulingContact={pickupDeliveryState.schedulingContact}
        isValidPhoneNumber={isValidPhoneNumber}
        isLoadingPhoneNumber={isLoadingPhoneNumber}
      />
      {pickupDeliveryState.schedulingType === 'APPOINTMENT' &&
        !!pickupDeliveryState.appointment && (
          <Appointments
            appointment={pickupDeliveryState.appointment}
            handleAppointmentsChange={(appointment: AppointmentsType) =>
              handlePickupDeliveryInputChange('appointment', appointment)
            }
            pickupDeliveryType={pickupDeliveryType}
            pickupDate={pickupDate}
            timezone={timezone}
          />
        )}
      <PickupDeliveryNotes
        isLoadingShipmentFormEnums={isLoadingShipmentFormEnums}
        handlePickupDeliveryInputChange={handlePickupDeliveryInputChange}
        notes={pickupDeliveryState.notes}
        referenceNumbers={pickupDeliveryState.referenceNumbers}
        type={pickupDeliveryType}
      />
      <ShipmentFormSectionFooter
        handleClearSection={() => handleClear()}
        handleSectionSubmit={() => handleSectionSubmit(pickupDeliveryType)}
        isValid={
          validatePickupDeliveryForm(pickupDeliveryState) &&
          confirmValidPhoneNumber(
            pickupDeliveryState.schedulingContact.phoneNumber,
            isValidPhoneNumber,
          )
        }
        primaryButtonText="Continue"
        handleAutoPopulate={() => {
          /* istanbul ignore next */
          handleShipmentFormInputChange(pickupDeliveryType, {
            ...PickupDeliveryFormDevData,
            cityState: pickupDeliveryState.cityState,
            postalCode: pickupDeliveryState.postalCode,
          });
          /* istanbul ignore end */
        }}
      />
    </ShipmentSectionWrapper>
  );
};

export default memo(PickupDeliveryForm);
