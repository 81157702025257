import React from 'react';

// @Common Components
import { TableDataCell } from 'commonComponents/Table/TableStyles';
import Typography from 'commonComponents/Typography/Typography';

// @Helpers
import { getInvoiceDate } from 'components/Invoices/InvoicesTable/InvoiceHelpers';

// @Styles
import { LinkButton } from 'styles/Links';

// @Theme
import { palette } from 'theme/Theme';
import { spacing } from 'theme/Units';

// @Types
import { Invoice } from 'types/Shipment.types';

const InvoiceDetails = ({
  invoice,
  idx = 0,
}: {
  invoice: Invoice;
  idx?: number;
}) => {
  const invoiceDate = getInvoiceDate(invoice);
  const {
    InvoiceRecordNumber,
    NumberOfInvoices,
    InvoiceNumber,
    InvoiceDocumentLink,
  } = invoice;

  const numberOfInvoicesSummary =
    NumberOfInvoices > 1
      ? `(${InvoiceRecordNumber} of ${NumberOfInvoices})`
      : '';

  return (
    <TableDataCell data-testid={`invoice-${idx}-details`}>
      <Typography variant="body2" as="p" margin={`10px 0`}>
        {InvoiceNumber ?? '-'} {numberOfInvoicesSummary}
      </Typography>
      {invoiceDate && (
        <Typography
          variant="body2"
          color={palette.common.smokey}
          margin={`${spacing.small} 0 ${spacing.xsmall} 0`}
          as="p"
        >
          {invoiceDate}
        </Typography>
      )}
      {InvoiceDocumentLink && (
        <LinkButton href={InvoiceDocumentLink} data-testid="download-invoice">
          {NumberOfInvoices > 1 ? 'Download Invoices' : 'Download Invoice'}
        </LinkButton>
      )}
    </TableDataCell>
  );
};

export default InvoiceDetails;
