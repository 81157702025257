import React, { ReactElement } from 'react';
import { useQueryClient, QueryClient } from '@tanstack/react-query';

// @CommonComponents
import SearchableDropdown from 'commonComponents/Dropdowns/SearchableDropdown';
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Styles
import {
  ContactInfoContainer,
  LoadingSpinnerContainer,
  TextEntry,
} from './PickupDeliveryFormStyles';
import { FormContainer, ShipmentFormSubHeading } from '../ShipmentFormStyles';

// @Types
import { SchedulingProps } from '../ShipmentForm.types';
import {
  SchedulingTypes,
  ShipmentFormEnums,
  ShipyardEnumFormat,
} from 'types/Shipment.types';

// @Utils
import {
  insertDashesInPhoneNumber,
  isValidEmail,
  onlyAllowNumbers,
} from 'utils/utils';

const Scheduling = ({
  handlePickupDeliveryInputChange,
  isValidPhoneNumber,
  isLoadingPhoneNumber,
  schedulingType,
  schedulingContact,
}: SchedulingProps): ReactElement => {
  const queryClient: QueryClient = useQueryClient();
  const ShipmentFormEnums = queryClient.getQueryData([
    'shipmentFormEnums',
  ]) as ShipmentFormEnums;

  const updateSchedulingContact = (field: string, value: string) => {
    if (schedulingContact) {
      handlePickupDeliveryInputChange('schedulingContact', {
        ...schedulingContact,
        [field]: value,
      });
    }
  };

  const LoadingSpinner = (
    <LoadingSpinnerContainer data-testid="pickup-delivery-form-loading">
      <SpinningLoadingIndicator />
    </LoadingSpinnerContainer>
  );

  return (
    <FormContainer>
      <ShipmentFormSubHeading>Scheduling</ShipmentFormSubHeading>
      <SearchableDropdown
        aria-label="scheduling"
        dataTestId="scheduling-type"
        data-testid="scheduling-type"
        getOptionLabel={(option) => (option as ShipyardEnumFormat).displayName}
        getOptionValue={(option) => (option as ShipyardEnumFormat).enumValue}
        isClearable
        name="schedulingType"
        onChange={(e) =>
          handlePickupDeliveryInputChange(
            'schedulingType',
            (e as ShipyardEnumFormat)?.enumValue,
          )
        }
        options={(ShipmentFormEnums?.schedulingTypes as SchedulingTypes) || []}
        placeholder="Scheduling Type"
        value={
          ShipmentFormEnums?.schedulingTypes?.find(
            (scheduleType) => scheduleType?.enumValue === schedulingType,
          ) || null
        }
        required
      />
      <ContactInfoContainer>
        <TextEntry
          data-testid="contact-name"
          label="Contact Name"
          maxLength={250}
          name="fullName"
          onChange={(e) =>
            updateSchedulingContact(e.target.name, e.target.value)
          }
          value={schedulingContact?.fullName || ''}
        />
        <TextEntry
          autoComplete="off"
          data-testid="contact-phone-number"
          errorText={
            !isValidPhoneNumber && 'Please enter a valid phone number.'
          }
          label="Contact Phone Number"
          name="phoneNumber"
          maxLength={12}
          onChange={(e) =>
            updateSchedulingContact(
              e.target.name,
              onlyAllowNumbers(e.target.value),
            )
          }
          pattern="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
          suffix={isLoadingPhoneNumber && LoadingSpinner}
          value={insertDashesInPhoneNumber(
            schedulingContact?.phoneNumber || '',
          )}
        />
      </ContactInfoContainer>

      <TextEntry
        data-testid="contact-email"
        label="Contact Email"
        name="email"
        onChange={(e) => updateSchedulingContact(e.target.name, e.target.value)}
        type="email"
        pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
        value={schedulingContact?.email || ''}
        errorText={
          schedulingContact?.email &&
          !isValidEmail(schedulingContact?.email) &&
          'Please enter a valid email.'
        }
      />
    </FormContainer>
  );
};
export default Scheduling;
