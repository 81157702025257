import React from 'react';
import { nanoid } from 'nanoid';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common Components
import TableContentLoader from 'commonComponents/Table/TableContentLoader';

// @Styles
import {
  TableRowLoading,
  TableDataCell,
  TableRowLoadingColumn,
} from 'commonComponents/Table/TableStyles';

const LoadingRow = ({ index }: { index: number }) => {
  return (
    <TableRowLoading data-testid={`shipment-row-loading-${index}`}>
      <TableDataCell>
        <TableContentLoader
          containerWidth={190}
          width={190}
          id={`references-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableContentLoader
          containerWidth={80}
          width={80}
          id={`status-loading-${index}`}
        />
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            width={100}
            id={`pickup-location-loading-${index}`}
          />
          <TableContentLoader width={200} id={`pickup-date-loading-${index}`} />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader
            width={100}
            id={`delivery-location-loading-${index}`}
          />
          <TableContentLoader
            width={200}
            id={`delivery-date-loading-${index}`}
          />
        </TableRowLoadingColumn>
      </TableDataCell>
      <TableDataCell>
        <TableRowLoadingColumn>
          <TableContentLoader width={100} id={`equipment-loading-${index}`} />
          <TableContentLoader width={60} id={`mode-loading-${index}`} />
        </TableRowLoadingColumn>
      </TableDataCell>
    </TableRowLoading>
  );
};

const ShipmentsRowContentLoader = () => {
  return (
    <TableBody data-testid="shipment-loading-container">
      {Array.from({ length: 8 }).map((_, index) => (
        <LoadingRow key={`loading-row-${nanoid(10)}`} index={index} />
      ))}
    </TableBody>
  );
};

export default ShipmentsRowContentLoader;
