export const spacing = {
  none: 0,
  xxsmall: '4px',
  xsmall: '8px',
  small: '12px',
  normal: '16px',
  medium: '20px',
  gutter: '24px',
  large: '32px',
  xlarge: '48px',
  xxlarge: '96px',
};

export const fontSizes = {
  xsmall: '0.75rem', //12
  small: '0.875rem', // 14
  medium: '1rem', // 16
  standard: '1.063rem', // 17
  large: '1.125rem', // 18
  xlarge: '1.25rem', // 20
  xxlarge: '1.5rem', // 24
};
