import React, { ReactElement } from 'react';

//@arrive
import TextEntry from '@arrive/text-entry';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Components
import AdditionalServices from './AdditionalServices';
import Items from './Items';
import ShipmentFormSectionFooter from '../ShipmentFormSectionFooter';

// @Constants
import { EquipmentOptions } from 'constants/EquipmentType';

// Dev Data
import { LoadFormDevData } from 'dev/ShipmentFormDevData';

// @Helpers
import { cargoValueErrorText } from '../ShipmentFormHelpers';

// @Hooks
import { useCreateShipment } from '../useShipmentForm';

// @Styles
import {
  FormContainer,
  NotesContainer,
  ShipmentSectionWrapper,
  ShipmentFormSubHeading,
} from '../ShipmentFormStyles';
import { EquipmentContainer } from './LoadFormStyles';

// @Types
import { LoadFormProps, LoadFormSectionProps } from '../ShipmentForm.types';
import { ShipmentDetails } from 'types/Shipment.types';

// @Utils
import { onlyAllowNumbersAndCommas } from 'utils/utils';

export const clearedLoadForm = {
  commodityName: '',
  pieceUnit: null,
  pieceCount: 0,
  weightLbs: 0,
  cargoValue: '',
  maxTempF: '',
  minTempF: '',
  preCoolTempF: '',
  loadComments: '',
  externalShipmentId: '',
};

export const ShipmentValue = ({
  cargoValue,
  handleShipmentFormInputChange,
}: LoadFormSectionProps): ReactElement => {
  return (
    <FormContainer>
      <ShipmentFormSubHeading>Shipment Value</ShipmentFormSubHeading>
      <TextEntry
        label="Cargo Value"
        errorText={cargoValueErrorText(cargoValue || '')}
        name="cargoValue"
        onChange={(e) =>
          handleShipmentFormInputChange(
            e.target.name as keyof ShipmentDetails,
            onlyAllowNumbersAndCommas(e.target.value ? e.target.value : ''),
          )
        }
        required
        value={cargoValue}
      />
    </FormContainer>
  );
};

export const ShipmentNotes = ({
  loadComments,
  handleShipmentFormInputChange,
}: LoadFormSectionProps) => {
  return (
    <FormContainer>
      <ShipmentFormSubHeading>Shipment Notes</ShipmentFormSubHeading>
      <NotesContainer
        aria-label="Additional notes or comments"
        data-testid="shipment-form-notes"
        label={!!loadComments?.length ? '' : 'Additional notes or comments'}
        maxLength={300}
        names="loadComments"
        onChange={(e) =>
          handleShipmentFormInputChange('loadComments', e.target.value)
        }
        value={loadComments || ''}
      />
    </FormContainer>
  );
};

export const ReferenceNumbers = ({
  externalShipmentId = '',
  handleShipmentFormInputChange,
}: LoadFormSectionProps): ReactElement => {
  return (
    <FormContainer>
      <ShipmentFormSubHeading>Reference Numbers</ShipmentFormSubHeading>
      <TextEntry
        data-testid="shipment-form-reference-numbers"
        label="Shipment ID"
        maxLength={250}
        name="externalShipmentId"
        onChange={(e) =>
          handleShipmentFormInputChange('externalShipmentId', e.target.value)
        }
        required
        value={externalShipmentId || ''}
      />
    </FormContainer>
  );
};

const LoadForm = ({
  handleShipmentFormInputChange,
  setShipmentDetails,
  shipmentDetails,
  isValidShipment,
}: LoadFormProps): ReactElement => {
  // only clear the load section
  const handleClearLoadSection = () => {
    setShipmentDetails({
      ...shipmentDetails,
      ...clearedLoadForm,
    });
  };

  const { mutate: createShipment, isLoading: isSubmittingShipment } =
    useCreateShipment();

  const handleSubmitShipment = () => {
    return createShipment(shipmentDetails);
  };

  return (
    <ShipmentSectionWrapper>
      <Typography variant="h2">Shipment</Typography>
      <FormContainer>
        <EquipmentContainer>
          <TextEntry
            disabled
            label="Equipment"
            name="equipment"
            onChange={
              /* istanbul ignore next */ () =>
                handleShipmentFormInputChange(
                  'equipmentType',
                  shipmentDetails.equipmentType,
                )
            }
            /* istanbul ignore end */
            value={
              EquipmentOptions.find(
                (eq) => eq.value === shipmentDetails.equipmentType,
              )?.displayName
            }
            data-testid="shipment-form-equipment"
          />
          <TextEntry
            disabled
            label="Mode"
            name="shipmentType"
            value={shipmentDetails.shipmentType}
            onChange={
              /* istanbul ignore next */ () =>
                handleShipmentFormInputChange(
                  'shipmentType',
                  shipmentDetails.shipmentType,
                )
              /*istanbul ignore end */
            }
            data-testid="shipment-form-mode"
          />
        </EquipmentContainer>
      </FormContainer>
      <Items
        commodityName={shipmentDetails.commodityName}
        handleShipmentFormInputChange={handleShipmentFormInputChange}
        pieceCount={shipmentDetails.pieceCount}
        pieceUnit={shipmentDetails.pieceUnit}
        weightLbs={shipmentDetails.weightLbs}
      />
      <ShipmentValue
        cargoValue={shipmentDetails.cargoValue}
        handleShipmentFormInputChange={handleShipmentFormInputChange}
      />
      {shipmentDetails.equipmentType === 'R' && (
        <AdditionalServices
          handleShipmentFormInputChange={handleShipmentFormInputChange}
          maxTempF={shipmentDetails.maxTempF}
          minTempF={shipmentDetails.minTempF}
          preCoolTempF={shipmentDetails.preCoolTempF}
        />
      )}
      <ShipmentNotes
        loadComments={shipmentDetails.loadComments}
        handleShipmentFormInputChange={handleShipmentFormInputChange}
      />
      <ReferenceNumbers
        handleShipmentFormInputChange={handleShipmentFormInputChange}
        externalShipmentId={shipmentDetails.externalShipmentId}
      />
      <ShipmentFormSectionFooter
        handleClearSection={handleClearLoadSection}
        handleSectionSubmit={handleSubmitShipment}
        isValid={isValidShipment}
        primaryButtonText="Confirm & Accept"
        isLoadingSubmit={isSubmittingShipment}
        handleAutoPopulate={() => {
          /* istanbul ignore next */
          return setShipmentDetails({ ...shipmentDetails, ...LoadFormDevData });
          /* istanbul ignore end */
        }}
      />
    </ShipmentSectionWrapper>
  );
};

export default LoadForm;
