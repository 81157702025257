import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { ThemeProvider, Global } from '@emotion/react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import ToastProvider from '@arrive/toasts';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { nanoid } from 'nanoid';

// Launch Darkly
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';

// @Common
import './config.scss';
import theme from 'theme/Theme';
import GlobalReset from 'theme/GlobalReset';
import ErrorComponent from 'commonComponents/Error/ErrorComponent';
import NavBar from 'commonComponents/NavBar/NavBar';
import Footer from 'commonComponents/Footer/Footer';
import NoAccess from 'commonComponents/Error/NoAccess';

// @Components
const Quotes = React.lazy(() => import('components/Quotes/Quotes'));
const Terms = React.lazy(() => import('components/Terms/Terms'));
const ShipmentForm = React.lazy(
  () => import('components/ShipmentForm/ShipmentForm'),
);
const ShipmentConfirmation = React.lazy(
  () => import('components/ShipmentForm/ConfirmationPage'),
);
const Shipments = React.lazy(() => import('components/Shipments/Shipments'));
const Invoices = React.lazy(() => import('components/Invoices/Invoices'));

// @Hooks
import { useGetShipperPortalUserInfo } from 'hooks/useUserAccess';

// @Okta
import { SecureRoute, useOktaAuth, LoginCallback } from '@okta/okta-react';

// @Static
import ROUTES from './routes';

// @Types
import { UserLoginInformation } from 'types/User.types';

// @Utils
import { initializePendo } from 'utils/PendoUtils';
import { getReactRouterItems, getPathSegments } from 'utils/RouterUtils';

const App = (): ReactElement => {
  const { authState, oktaAuth } = useOktaAuth();
  const ldClient = useLDClient();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const [currentUser, setCurrentUser] = useState<UserLoginInformation>({
    name: null,
    email: null,
  });

  // check whether the user has accepted terms
  const { data: userInformation, isInitialLoading: isLoadingUser } =
    useGetShipperPortalUserInfo(currentUser);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setCurrentUser({ name: null, email: null });
    } else {
      oktaAuth.getUser().then(({ name, email, ...rest }) => {
        queryClient.setQueryData(['currentUser'], { name, email, ...rest });
        setCurrentUser({ name, email, ...rest });
        process.env.WHICH_ENV === 'PROD' &&
          initializePendo({ name, email, ...rest });
      });

      currentUser.name &&
        ldClient?.identify({
          name: currentUser.name as string,
          email: currentUser.email as string,
          key: currentUser.email as string,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth, currentUser.email]);

  const RouterDictionary = {
    R_SHIPPER_PORTAL_QUOTING: {
      path: ROUTES.QUOTES,
      exact: true,
      title: 'Quotes',
      showNavbar: true,
      showFooter: true,
      component: Quotes,
      dependents: [
        {
          path: ROUTES.SHIPMENTFORM,
          exact: false,
          title: 'Quotes',
          showNavbar: false,
          showFooter: false,
          component: ShipmentForm,
        },
        {
          path: ROUTES.SHIPMENTCONFIRMATION,
          exact: false,
          title: 'Quotes',
          showNavbar: true,
          showFooter: true,
          component: ShipmentConfirmation,
        },
      ],
    },
    R_SHIPPER_PORTAL_TRACKING: {
      path: ROUTES.SHIPMENTS,
      exact: true,
      title: 'Shipments',
      showNavbar: true,
      showFooter: true,
      component: Shipments,
      dependents: [],
    },
    R_SHIPPER_PORTAL_BILLING: {
      path: ROUTES.INVOICES,
      exact: true,
      title: 'Invoices',
      showNavbar: true,
      showFooter: true,
      component: Invoices,
      dependents: [],
    },
  };

  // get react router items
  const RouterItems = getReactRouterItems(
    RouterDictionary,
    userInformation?.RoleCodes,
  );

  const availablePaths = RouterItems.map((item) => {
    return getPathSegments(item.path);
  });

  // Extract the base path
  const currentPath = getPathSegments(pathname);

  useEffect(() => {
    const pageTitle =
      RouterItems.find((item) => {
        return item.path === currentPath;
      })?.title || 'Quotes';
    document.title = `${pageTitle} | ARRIVEnow`;
  }, [RouterItems, currentPath]);

  if (isLoadingUser) {
    return <div>Loading...</div>;
  }

  const renderRoutes = () => {
    if (!userInformation?.UserHasAcceptedAgreement) {
      return (
        <SecureRoute
          component={() => (
            <>
              <NavBar />
              <Terms />
            </>
          )}
          path={ROUTES.DEFAULT}
          exact
        />
      );
    }

    if (RouterItems.length && availablePaths.includes(currentPath)) {
      return RouterItems.map((route) => (
        <SecureRoute
          key={nanoid(10)}
          component={() => {
            return (
              <>
                {route.showNavbar && <NavBar />}
                {<route.component />}
                {route.showFooter && <Footer />}
              </>
            );
          }}
          path={route.path}
          exact={route.exact}
        />
      ));
    }

    // render no access page if user returns to a path that doesn't have access to
    if (!availablePaths.includes(currentPath)) {
      return (
        <SecureRoute
          path="*"
          component={() => {
            return (
              <>
                <NavBar />
                <NoAccess />
                <Footer />
              </>
            );
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Global styles={GlobalReset} />
        <ToastProvider>
          <ErrorBoundary
            fallback={({ error }) => (
              <ErrorComponent error={error as AxiosError} />
            )}
            beforeCapture={(scope) =>
              scope.setUser({
                name: currentUser.name,
                //@ts-ignore sentry doesn't like when the value is null
                email: currentUser.email,
              })
            }
          >
            <Switch>
              <Suspense fallback={<div>Loading...</div>}>
                <Route path={ROUTES.LOGIN_CALLBACK} component={LoginCallback} />
                {renderRoutes()}
              </Suspense>
            </Switch>
          </ErrorBoundary>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

export default withLDProvider({
  clientSideID: process.env.LD_CLIENT_SIDE_ID as string,
  options: {
    bootstrap: 'localStorage',
  },
})(App);
