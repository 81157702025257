import React from 'react';

// @Common Components
import Section from 'commonComponents/Section/Section';
import { StyledTable } from 'commonComponents/Table/TableStyles';
import TableHeader from 'commonComponents/Table/TableHeader';

// @Components
import QuoteHistoryTable from './QuoteHistoryTable/QuoteHistoryTable';

// @Hooks
import { useGetQuoteHistory } from './useGetQuoteHistory';
import { useQueryParams } from 'hooks/useReactRouterQueries';
import { useGetQuoteHistoryStatus } from 'hooks/useGetQuoteStatus';
import { useExpirationCountdown } from 'hooks/useExpirationCountdown';

// @Types
import { QuoteHistoryProps } from './QuoteHistory.types';

const quoteHistoryHeaders = [
  'Pickup',
  'Delivery',
  'Quote',
  'Miles',
  'Quote Date',
  'EQ',
  'MODE',
  'Status',
  '',
];

const QuoteHistory = ({
  dataTestId,
  submitQuote,
  submitDisabled = false,
}: QuoteHistoryProps) => {
  const { pageNumber = 1, pageSize = 25 } = useQueryParams();

  const {
    data: quotesHistory,
    isInitialLoading: isLoadingQuoteHistory,
    error: quoteHistoryError,
  } = useGetQuoteHistory({ pageNumber, pageSize });

  const {
    data: quotesStatusData,
    isInitialLoading: isLoadingQuotesStatus,
    error: quoteStatusError,
  } = useGetQuoteHistoryStatus({ pageNumber, pageSize });

  const { multiUpdatedExpiration: updatedQuotesStatus } =
    useExpirationCountdown(quotesStatusData, false);

  return (
    <>
      <Section title="Quote History" variant="subtitle" hasBorder>
        <StyledTable data-testid={dataTestId}>
          <TableHeader dataTestId={dataTestId} headers={quoteHistoryHeaders} />
          <QuoteHistoryTable
            quotesData={quotesHistory}
            quotesError={!!quoteHistoryError || !!quoteStatusError}
            isLoading={isLoadingQuoteHistory || isLoadingQuotesStatus}
            submitQuote={submitQuote}
            submitDisabled={submitDisabled}
            updatedQuotesStatus={updatedQuotesStatus}
          />
        </StyledTable>
      </Section>
    </>
  );
};

export default QuoteHistory;
