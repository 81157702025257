import React from 'react';
import styled from '@emotion/styled';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common components
import ErrorMessage from './ErrorMessage';

// @Types
import { TableErrorContainerProps } from './Error.types';

const NoResultTableDataCell = styled.td`
  padding-top: 40px;
  height: 200px !important;
  text-align: center;
`;

const TableErrorMessage = ({ col, ...props }: TableErrorContainerProps) => {
  return (
    <TableBody>
      <tr>
        <NoResultTableDataCell colSpan={col} data-testid={props.dataTestId}>
          <ErrorMessage {...props} />
        </NoResultTableDataCell>
      </tr>
    </TableBody>
  );
};

export default TableErrorMessage;
