import React, { createContext } from 'react';

// @Hooks
import useAxios from 'hooks/useAxios';

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthContext = createContext({});
const AuthProvider = ({ children }: AuthProviderType) => {
  const axiosInstance = useAxios();
  return (
    <AuthContext.Provider value={axiosInstance}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
