import { lighten, math } from 'polished';
import { Breakpoint } from './themeType';
import { fontSizes, spacing } from './Units';

const white = '#fff';
const black = '#1d1e20';
const disabled = '#d4d6d9';
const fontFamily = 'CircularXX, Helvetica Neue, Arial, sans-serif;';

const breakpointValues = { xs: 0, sm: 768, md: 1248, lg: 2016 };

const breakpoints = {
  values: breakpointValues,
  up: (breakpoint: string) => {
    return `@media (min-width:${breakpointValues[breakpoint as Breakpoint]}px)`;
  },
  down: (breakpoint: string) => {
    return `@media (max-width:${breakpointValues[breakpoint as Breakpoint]}px)`;
  },
  between: (start: string, end: string) => {
    return `@media (min-width:${
      breakpointValues[start as Breakpoint]
    }px) and (max-width:${breakpointValues[end as Breakpoint]}px)`;
  },
};

export const palette = {
  common: {
    accessibleYellow: '#BE5B05',
    arrive: '#2aaae2',
    axle: '#1d1e20',
    bandit: '#e0f3fb',
    bigSky: '#b1e1f5',
    black,
    blueBayou: '#039ddd',
    blueridge: '#0062c0',
    burnout: '#46494f',
    chrome: '#d4d6d9',
    diesel: '#f1ae11',
    disabled,
    ethanol: '#fff',
    frogger: '#2b731a',
    gasoline: '#feefcb',
    gatorguts: '#4caf50',
    grease: '#0a0a0b',
    jackknife: '#cb2024',
    LFGray: '#9ea2a9',
    mileMarker: '#e8f5e9',
    nightTime: '#007dbc',
    roadhog: '#303236',
    smokey: '#60646c',
    tandem: '#f4f5f5',
    white,
    whoaNelly: '#ffedf1',
  },
  primary: {
    main: '#2aaae2',
    light: '#b1e1f5',
    contrastText: white,
  },
  error: {
    main: '#cb2024',
    light: lighten(0.1, '#cb2024'),
    contrastText: white,
  },
  pillColors: {
    text: {
      text: '#1d1e20',
      background: '#f4f5f5',
    },
    warning: {
      text: '#BE5B05',
      background: '#feefcb',
    },
    success: {
      text: '#4caf50',
      background: '#e8f5e9',
    },
    error: {
      text: '#cb2024',
      background: '#ffedf1',
    },
  },
};

export const sectionSettings = {
  smokey: {
    variant: 'subtitle1',
    color: palette.common.smokey,
    padding: spacing.normal,
  },
  subtitle: {
    variant: 'subtitle1',
    color: palette.common.axle,
    padding: spacing.gutter,
  },
  heading: {
    variant: 'h2',
    color: palette.common.axle,
    padding: math(`${spacing.medium} * 2`),
  },
  title: {
    variant: 'h1',
    color: palette.common.axle,
    padding: spacing.xlarge,
  },
} as { [key: string]: { variant: string; color: string; padding: string } };

const typography = {
  fontFamily: fontFamily,
  fontSize: fontSizes.small, //14
  body1: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.standard, //17
    fontWeight: 400,
    letterSpacing: '0.1px',
    lineHeight: '140%',
  },
  body2: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.small, //14
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '130%',
  },
  button: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.small, //14
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '140%',
  },
  caption: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.xsmall, //12
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '120%',
  },
  display1: {
    color: black,
    fontFamily: fontFamily,
    fontSize: '2.625rem', //42
    fontWeight: 400,
    letterSpacing: '-0.75px',
    lineHeight: '115%',
  },
  display2: {
    color: black,
    fontFamily: fontFamily,
    fontSize: '2rem', //32
    fontWeight: 400,
    letterSpacing: '-.35px',
    lineHeight: '120%',
  },
  h1: {
    color: black,
    fontFamily: fontFamily,
    fontSize: '1.625rem', //26
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '125%',
  },
  h2: {
    color: black,
    fontFamily: fontFamily,
    fontSize: '1.375rem', //22
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '115%',
  },
  overline: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.small, //14
    fontWeight: 400,
    letterSpacing: '0.75px',
    lineHeight: '130%',
    textTransform: 'uppercase',
  },
  subtitle1: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.standard, //17
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '140%',
  },
  subtitle2: {
    color: black,
    fontFamily: fontFamily,
    fontSize: fontSizes.small, //14
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '130%',
  },
};

const theme = {
  palette,
  typography,
  spacing,
  breakpoints,
  fontSizes,
  fontFamily,
};
export default theme;
