import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

// @Api
import { AccountEndpoints } from 'api/endpoints';

// @Constants
import { RoleToNavMap } from 'constants/Permissions';

// @Hooks
import useAxios from './useAxios';

// @Routes
import ROUTES from '../../routes';

// @Types
import {
  ShipperPortalUserInformation,
  UserLoginInformation,
} from 'types/User.types';
import { NavItemType, RoleToNavMapType } from 'types/NavItem.types';

export const useGetShipperPortalUserInfo = (
  currentUser: UserLoginInformation,
): UseQueryResult<ShipperPortalUserInformation> => {
  const axios = useAxios();

  return useQuery(
    ['shipperPortalUserInfo'],
    async () => {
      const { data } = await axios.get(AccountEndpoints.getUserInformation);
      return data.Result;
    },
    {
      enabled: !!currentUser.name,
    },
  );
};

export const useGetAccessibleNavItems = (): NavItemType[] | [] => {
  // set up queryClient
  const queryClient = useQueryClient();
  // get user info
  const userPermissions = queryClient.getQueryData([
    'shipperPortalUserInfo',
  ]) as ShipperPortalUserInformation;

  // get permissions from user roles
  const userRoles = userPermissions?.RoleCodes;
  // create a new array that returns new route and title

  if (userRoles) {
    //@ts-ignore because of this issue https://github.com/microsoft/TypeScript/issues/44063
    const updatedNavItems = userRoles.reduce((acc, role, idx) => {
      const navItem = RoleToNavMap[role as keyof RoleToNavMapType];

      if (navItem) {
        const route = idx === 0 ? ROUTES.DEFAULT : navItem.route;
        return [...acc, { title: navItem.title, route }];
      }

      return acc;
    }, []);

    // @ts-ignore
    return updatedNavItems;
  }
  return [];
};
