import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import qs from 'qs';

// @Api
import { IPServiceEndpoints } from 'api/endpoints';

// @Helpers
import { transformQuoteResult } from './QuoteHistoryHelpers';

// @Hooks
import useAxios from 'hooks/useAxios';

// @Types
import { QuoteHistoryResponse, QuoteHistoryType } from './QuoteHistory.types';
import { PaginationOptions } from 'types/pagination';
import { UserLoginInformation } from 'types/User.types';

export const useGetQuoteHistory = ({
  pageNumber,
  pageSize,
}: PaginationOptions): UseQueryResult<QuoteHistoryType> => {
  const queryClient = useQueryClient();
  const customerId = (
    queryClient.getQueryData(['currentUser']) as UserLoginInformation
  )?.customerId;

  const request = {
    CustomerId: customerId,
    PageSize: pageSize,
    PageNumber: pageNumber,
    OrderByColumns: 'CreatedOn',
    OrderByDirection: 1,
  };
  const axios = useAxios();

  return useQuery(['quoteHistory', pageNumber, pageSize], async () => {
    const { data } = await axios.get(
      `${IPServiceEndpoints.getQuoteHistory}?${qs.stringify(request)}`,
    );

    return {
      quotes: data.result
        ? data.result.results.map((quote: QuoteHistoryResponse) =>
            transformQuoteResult(quote),
          )
        : [],
      total: data.result ? data.result.totalRecordCount : 0,
    };
  });
};
