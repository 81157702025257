import React from 'react';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Types
import { InvoiceBalanceDueProps } from 'components/Invoices/Invoices.types';

// @Utils
import { formatNumberAmericanStandard } from 'utils/utils';

const InvoiceBalanceDue = ({
  status,
  balanceDue,
  totalAmount,
}: InvoiceBalanceDueProps) => {
  if (status === 'Paid') {
    return (
      <Typography variant="body1" as="p">
        $0
      </Typography>
    );
  }
  if (status === 'Partially Paid') {
    return (
      <Typography variant="body1" as="p">
        {balanceDue !== null
          ? `$${formatNumberAmericanStandard(balanceDue, true)}`
          : '-'}
      </Typography>
    );
  }
  if (status === 'Invoiced') {
    return (
      <Typography variant="body1" as="p">
        {!!totalAmount
          ? `$${formatNumberAmericanStandard(totalAmount, true)}`
          : '-'}
      </Typography>
    );
  }
  return (
    <Typography variant="body1" as="p">
      Pending
    </Typography>
  );
};

export default InvoiceBalanceDue;
