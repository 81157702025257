import React, { ReactElement, useLayoutEffect, useMemo, useRef } from 'react';
import debounce from 'lodash-es/debounce';

// @Hooks
import { useQueryParams } from 'hooks/useReactRouterQueries';

// @Icons
import SearchIcon from '@arrive/ui-icons/svg/ic_magnifying_glass.svg';

// @Styles
import { ReferenceSearch } from 'styles/Filters';

// @Types
import { ShipmentTableFilterOptions } from 'components/Shipments/ShipmentPage.types';

type ReferenceNumberSearchProps = {
  handleApply: (
    filterOption: keyof ShipmentTableFilterOptions,
    referenceNumber: string,
  ) => void;
  referenceNumber: string;
  setReferenceNumber: (referenceNumber: string) => void;
  timeout?: number;
};

const ReferenceNumberSearch = ({
  handleApply,
  referenceNumber,
  setReferenceNumber,
  timeout = 500,
}: ReferenceNumberSearchProps): ReactElement => {
  const searchRef = useRef(null) as React.RefObject<HTMLInputElement>;
  const { ReferenceNumber } = useQueryParams();

  useLayoutEffect(() => {
    if (ReferenceNumber) {
      searchRef.current?.focus();
    }
  }, []);

  // debounce the handle apply filter
  const debouncedHandleApplyFilter = useMemo(
    () =>
      debounce(
        (value) =>
          handleApply(
            'ReferenceNumber',
            value && !!value.length ? value : null,
          ),
        timeout,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleApply],
  );

  return (
    <div data-testid="reference-number-search-container">
      <ReferenceSearch
        aria-label="Reference Number Search"
        data-testid="reference-number-search"
        label="Search by reference number"
        name="ReferenceNumberSearch"
        onChange={(event) => {
          setReferenceNumber(event.target.value);
          debouncedHandleApplyFilter(event.target.value);
        }}
        prefix={<SearchIcon />}
        value={referenceNumber || ''}
        ref={searchRef}
      />
    </div>
  );
};

export default ReferenceNumberSearch;
