import React from 'react';
import { useTheme } from '@emotion/react';

// @Components
import SearchableDropdown from './SearchableDropdown';
import { getConfigStyles } from './DropdownStyles';

// @Icons
import DryVanIcon from 'icons/DryVan.svg';

//@Types
import { SearchableDropdownSelectProps } from './DropdownTypes.types';
import { EquipmentOptions } from 'constants/EquipmentType';

const EquipmentDropdown = ({
  ...props
}: Partial<SearchableDropdownSelectProps>) => {
  const theme = useTheme();
  const commonStyles = getConfigStyles(theme);
  return (
    <SearchableDropdown
      {...props}
      blackIcons
      aria-label="select equipment type"
      dataTestId="equipment-dropdown"
      options={EquipmentOptions}
      placeholder="Equipment"
      prefix={<DryVanIcon />}
      required
      styles={{
        ...commonStyles,
        control: (styles, { isDisabled, isFocused }) => ({
          ...styles,
          borderRadius: 40,
          backgroundColor: theme.palette.common.ethanol,
          borderColor:
            (isDisabled && theme.palette.common.tandem) ||
            (isFocused && theme.palette.common.arrive) ||
            theme.palette.common.chrome,
          boxShadow: isFocused
            ? `0 0 0 1px ${theme.palette.common.arrive}`
            : 'none',
          ':hover': {
            borderColor:
              (isDisabled && theme.palette.common.tandem) ||
              (isFocused && theme.palette.common.arrive) ||
              theme.palette.common.axle,
          },
        }),
      }}
    />
  );
};

export default EquipmentDropdown;
