import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

// @Icons
import ArrowLeft from '@arrive/ui-icons/svg/ic_left_arrow.svg';
import ArrowRight from '@arrive/ui-icons/svg/ic_right_arrow.svg';

// @Styles
import { Asterisk } from 'styles/Asterisk';
import {
  CalendarArrowButton,
  StyledDateContainer,
  StyledDateHeader,
  StyledDateHorizontalContainer,
  StyledDatePickerContainer,
} from './DateTimePickerStyles';

// @Types
import type {
  DateContainerProps,
  DateTimePickerInputProps,
} from './DateTimePicker.types';
import ApplyButtonBar from '../Buttons/ApplyButtonBar';

export const DateContainer = ({
  children,
  isStartDate = true,
  isSingleDate = true,
  handleClear,
  handleApply,
}: DateContainerProps & {
  isStartDate?: boolean;
  isSingleDate?: boolean;
  handleClear?: () => void;
  handleApply?: () => void;
}) => {
  return (
    <StyledDateContainer isSingleDate={isSingleDate}>
      <StyledDateHorizontalContainer isStartDate={isStartDate}>
        {children}
      </StyledDateHorizontalContainer>
      {(handleClear || handleApply) && (
        <ApplyButtonBar handleApply={handleApply} handleClear={handleClear} />
      )}
    </StyledDateContainer>
  );
};

export const DateTimePickerInput = ({
  hasValue,
  disabled = false,
  required = false,
  onClick,
  children,
  placeholderText,
  svgIcon,
}: DateTimePickerInputProps) => {
  return (
    <StyledDatePickerContainer
      hasValue={hasValue}
      onClick={onClick}
      disabled={disabled || false}
    >
      <label>
        {placeholderText} {required && <Asterisk isDisabled={disabled} />}
      </label>
      <div>
        {svgIcon}
        {children}
      </div>
    </StyledDatePickerContainer>
  );
};

export const DatePickerHeader = ({
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  monthDate,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <StyledDateHeader>
      {customHeaderCount === 0 && (
        <CalendarArrowButton
          aria-label="See previous month"
          onClick={decreaseMonth}
          isRight={false}
          disabled={prevMonthButtonDisabled}
        >
          <ArrowLeft />
        </CalendarArrowButton>
      )}
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      {customHeaderCount === 1 && (
        <CalendarArrowButton
          aria-label="See next month"
          onClick={increaseMonth}
          isRight
          disabled={nextMonthButtonDisabled}
        >
          <ArrowRight />
        </CalendarArrowButton>
      )}
    </StyledDateHeader>
  );
};
