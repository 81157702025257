import styled from '@emotion/styled';
import { math } from 'polished';

// @Theme
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';

export const ShipmentPanelHeader = styled.div`
  background-color: ${palette.common.axle};
  color: ${palette.common.ethanol};

  & > div:nth-of-type(2) {
    padding: ${math(`${spacing.medium} * 2`)} ${spacing.gutter};
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      color: ${palette.common.ethanol};
      margin: 0px;
      max-width: 400px;
      text-align: center;
    }
  }
`;

export const ShipmentPanelSubheader = styled.div`
  border-bottom: 1px solid ${palette.common.burnout};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.gutter};

  & > button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }

  svg {
    fill: ${palette.common.ethanol};
  }
`;

export const ShipmentPickupDeliveryContainer = styled.div`
  padding: ${spacing.gutter};
  display: flex;
  justify-content: space-between;

  & > div {
    width: 502px;
  }
`;

export const ShipmentPickupDeliveryDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${math(`${spacing.small} * 3`)};

  span {
    margin: 0 0 ${math(`${spacing.xxsmall}/2`)} 0;
  }
`;

export const ShipmentDestination = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 0 6px 0;
  }

  svg {
    fill: ${palette.common.arrive};
    margin-right: ${spacing.small};
  }
`;

export const ShipmentReferenceNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.gutter} ${spacing.small} ${spacing.gutter};

  > span:nth-of-type(2) {
    margin: 2px 0 0 0;
  }
`;

export const ShipmentIconsContainer = styled.div`
  display: flex;
  padding: ${spacing.small} ${spacing.gutter} ${spacing.gutter};
  border-bottom: 1px solid ${palette.common.chrome};
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: ${spacing.xsmall};
    fill: ${palette.common.arrive};
    transform: scale(0.75);
  }
`;
