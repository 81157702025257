import React from 'react';
import styled from '@emotion/styled';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

const NoAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
  min-height: 868px;
`;
const NoAccess = () => {
  return (
    <NoAccessContainer>
      <Typography as="div" variant="h2">
        You don&apos;t have access to view this information.
      </Typography>
      <Typography as="div" variant="subtitle2" margin="0 0 8px 0">
        Please reach out to your Arrive Now representative for access.
      </Typography>
    </NoAccessContainer>
  );
};

export default NoAccess;
