import React, { ReactElement } from 'react';

//@arrive
import TextEntry from '@arrive/text-entry';

// @CommonComponents
import Typography from 'commonComponents/Typography/Typography';

// @Styles
import { FormContainer, ShipmentFormSubHeading } from '../ShipmentFormStyles';
import { TemperatureControlledContainer } from './LoadFormStyles';
import { TextEntryError } from 'styles/ErrorStyles';

// @Types
import { LoadFormSectionProps } from '../ShipmentForm.types';
import { ShipmentDetails } from 'types/Shipment.types';

// @Utils
import { onlyAllowNumbersAndMinus } from 'utils/utils';

// @Helpers
import { getTemperatureError } from '../ShipmentFormHelpers';

const AdditionalServices = ({
  handleShipmentFormInputChange,
  maxTempF,
  minTempF,
  preCoolTempF,
}: LoadFormSectionProps): ReactElement => {
  const handleTemperatureBlur = (
    name: keyof ShipmentDetails,
    value?: string | null,
  ) => {
    const temperatureInt = parseInt(value || '', 10);
    const isValidInt =
      !isNaN(temperatureInt) && (!!temperatureInt || temperatureInt === 0);
    const temperatureString = isValidInt ? temperatureInt.toString() : '';
    handleShipmentFormInputChange(name, temperatureString);
  };

  const tempErrors = getTemperatureError(preCoolTempF, minTempF, maxTempF);

  return (
    <FormContainer>
      <ShipmentFormSubHeading>Additional Services</ShipmentFormSubHeading>
      {tempErrors.map((error, index) => (
        <TextEntryError key={index}> {error} </TextEntryError>
      ))}
      <TemperatureControlledContainer>
        <TextEntry
          label="Pre-Cool (ºF)"
          name="preCoolTempF"
          onChange={(e) =>
            handleShipmentFormInputChange(
              'preCoolTempF',
              onlyAllowNumbersAndMinus(e.target.value),
            )
          }
          value={preCoolTempF || ''}
          required
          onBlur={() => handleTemperatureBlur('preCoolTempF', preCoolTempF)}
          data-testid="shipment-form-precool-temp"
        />

        <TextEntry
          label="Min (ºF)"
          name="minTempF"
          onChange={(e) =>
            handleShipmentFormInputChange(
              'minTempF',
              onlyAllowNumbersAndMinus(e.target.value),
            )
          }
          value={minTempF || ''}
          required
          onBlur={() => handleTemperatureBlur('minTempF', minTempF)}
          data-testid="shipment-form-min-temp"
        />
        <Typography>to</Typography>
        <TextEntry
          label="Max (ºF)"
          name="maxTempF"
          onChange={(e) =>
            handleShipmentFormInputChange(
              'maxTempF',
              onlyAllowNumbersAndMinus(e.target.value),
            )
          }
          value={maxTempF || ''}
          required
          onBlur={() => handleTemperatureBlur('maxTempF', maxTempF)}
          data-testid="shipment-form-max-temp"
        />
      </TemperatureControlledContainer>
    </FormContainer>
  );
};

export default AdditionalServices;
