export const validateDateInRange = (
  date: Date,
  minValue?: Date | null,
  maxValue?: Date | null,
) => {
  return (
    (!minValue || (minValue && date >= minValue)) &&
    (!maxValue || (maxValue && date <= maxValue))
  );
};
