import React from 'react';
import styled from '@emotion/styled';

// @arrive
import Button from '@arrive/button';

// @Styles
import { LoadingButton } from 'styles/Buttons';
import { palette } from 'theme/Theme';
import { spacing } from 'theme/Units';

// @Types
import { ApplyButtonBarProps } from './ButtonTypes.types';

export const ButtonContainer = styled.div`
  padding: ${`${spacing.small} ${spacing.small} 0 ${spacing.small}`};
  border-top: ${`solid 1px ${palette.common.chrome}`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: ${palette.common.ethanol};
`;

const ApplyButtonBar = ({ handleApply, handleClear }: ApplyButtonBarProps) => {
  return (
    <ButtonContainer>
      {handleClear && (
        <Button
          text
          type="secondary"
          onClick={handleClear}
          aria-label="Clear Selected Dates"
        >
          Clear
        </Button>
      )}
      {handleApply && (
        <LoadingButton onClick={handleApply} aria-label="Apply Selected Dates">
          Apply
        </LoadingButton>
      )}
    </ButtonContainer>
  );
};

export default ApplyButtonBar;
