/*
 How to use the Typography component
 import Typography from 'commonComponents/Typography/Typography'
    <Typography>
        Typography Component
    </Typography>

  by `default` the component is rendered as a span tag. `as` tag allows you to convert it to any tag you like
     <Typography align="center" as="div">
        Typography Component
    </Typography>

  - by default `variant` is set for `body1`. if you like to use different variant use any variant that is available in the theme palette.
    If you use ts and intellisense you will get the options to select them.
  - align =  'center'| 'inherit'| 'justify'| 'left'| 'right'
  - children = react node component
  - as =  elementType|  'span' | The component used for the root node. Either a string to use a HTML element or a component.
  - variant = 'body1' | 'body2' | 'button' | 'caption' | 'display1' | 'display2' | 'h1' | 'h2' | 'overline' | 'subtitle1' | 'subtitle2' | 'body1' | Applies the theme typography styles.
  - margin =  string | '4 0 4 0' | Applies custom margin value
  - padding | string | | Applies custom padding value
  - styledConfig | object | | Override custom typography values
*/

import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import type { TypographyDetails, TypographyType } from 'theme/themeType';
import { isObjectEmpty } from 'utils/utils';
import defaultTheme from 'theme/Theme';

interface TypographyProps {
  align?: 'inherit' | 'center' | 'left' | 'justify' | 'right';
  color?: string;
  fontSize?: string;
  margin?: string;
  padding?: string;
  styleConfigObject?: TypographyDetails;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore - there is currently a bug open against TS for this issue.
  theme?: theme;
  variant?: keyof TypographyType;
}

const TypographyRoot = ({
  align = 'inherit',
  color = defaultTheme.palette.common.axle,
  fontSize,
  margin = `${defaultTheme.spacing.xsmall} ${defaultTheme.spacing.none} `,
  padding,
  styleConfigObject,
  variant = 'body1',
  theme,
}: TypographyProps) => {
  const configuredStyles = styleConfigObject &&
    !isObjectEmpty(styleConfigObject) && { ...styleConfigObject };

  const themeTypography = theme
    ? theme?.typography[variant]
    : (defaultTheme?.typography[variant] as TypographyDetails);

  return {
    ...themeTypography,
    fontSize: fontSize ?? themeTypography.fontSize,
    color,
    textAlign: align,
    margin,
    padding,
    ...configuredStyles,
  };
};

const typographyConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldForwardProp: (prop: any) => isPropValid(prop),
};

export default styled('span', typographyConfig)(TypographyRoot);
