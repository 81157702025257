import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// @Helpers
import { ExpirationOptions } from 'utils/ExpirationMessageUtils';
import { convertQuoteDetailsToShipmentDetails } from 'components/ShipmentForm/ShipmentFormHelpers';

// @Hooks
import { useGetLoadLocation } from 'hooks/useGetLoadLocation';
import useLocalStorage from './useLocalStorage';

// @State
import { initialSelectedQuoteState } from 'components/ShipmentForm/useShipmentForm';

// @Types
import { QuoteRate, SendQuoteOptions } from 'types/quoteTypes.d';
import { LoadLocation } from 'types/quoteTypes';

export const useSubmitQuote = (): {
  submitQuote: (quote: QuoteRate) => Promise<void>;
  isSubmittingQuote: boolean;
  defaultForm: SendQuoteOptions | null;
} => {
  const [defaultForm, setDefaultForm] = useState<SendQuoteOptions | null>(null);
  const [isSubmittingQuote, setIsSubmittingQuote] = useState(false);
  const history = useHistory();
  const getLocation = useGetLoadLocation();
  const [_, setLocalStorageValue] = useLocalStorage(
    'selectedQuote',
    initialSelectedQuoteState,
  );
  const [_newFacility, setAddNewFacility] = useLocalStorage('newFacility', {
    pickup: false,
    delivery: false,
  });

  const submitQuote = async (quote: QuoteRate) => {
    setIsSubmittingQuote(true);
    const executeFunction =
      ExpirationOptions[quote.expirationStatus]?.executeFunction;
    const convertedQuoteData = convertQuoteDetailsToShipmentDetails(quote);

    if (executeFunction === 'accept') {
      history.replace(`/shipment-form/${quote.quoteId}`);
      setLocalStorageValue(convertedQuoteData);
      setAddNewFacility({
        pickup: false,
        delivery: false,
      });
    } else {
      const pickupLocation = (await getLocation(
        quote.pickupPostalCode,
      )) as Array<LoadLocation>;
      const deliveryLocation = (await getLocation(
        quote.deliveryPostalCode,
      )) as Array<LoadLocation>;
      setDefaultForm({
        truckType: quote.mode,
        equipmentType: quote.equipmentType,
        pickupLocation:
          pickupLocation.find(
            (location) =>
              `${location.CityName}, ${location.CityState}` ===
              quote.pickupLocation,
          ) ?? pickupLocation[0],
        deliveryLocation:
          deliveryLocation.find(
            (location) =>
              `${location.CityName}, ${location.CityState}` ===
              quote.deliveryLocation,
          ) ?? deliveryLocation[0],
        pickupDate: null,
      });
    }
    setIsSubmittingQuote(false);
  };

  return { submitQuote, isSubmittingQuote, defaultForm };
};
