import React from 'react';
import styled from '@emotion/styled';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Theme
import { spacing } from 'theme/Units';
import { palette } from 'theme/Theme';

// @Types
import { StatusPillProps } from './StatusPillTypes';

const StatusText = styled(Typography)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 18px;
  padding: ${spacing.xxsmall} ${spacing.xsmall};
`;

const StatusPill = ({ title, color = 'text' }: StatusPillProps) => {
  return (
    <StatusText
      variant="caption"
      color={palette.pillColors[color].text}
      backgroundColor={palette.pillColors[color].background}
    >
      {title}
    </StatusText>
  );
};

export default StatusPill;
