import React from 'react';

// @Icons
import LTL from 'icons/LTL.svg';
import FTL from 'icons/FTL.svg';

export const truckOptions = {
  FTL: {
    label: 'Full Truckload',
    value: 'FTL',
    displayName: 'FTL',
    svg: <FTL />,
    isDisabled: false,
  },
  LTL: {
    label: 'Less-Than-Truckload',
    value: 'LTL',
    displayName: 'LTL',
    svg: <LTL />,
    isDisabled: true,
  },
};
