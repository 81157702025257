import { SendQuoteOptions } from 'types/quoteTypes';

export const SelectQuoteFormDevData: SendQuoteOptions = {
  truckType: 'FTL',
  equipmentType: 'V',
  pickupLocation: {
    Value: 'CITY_45584',
    Name: 'AUSTIN, TX',
    Id: 45584,
    Geolocation: 'POINT (-97.74 30.26)',
    CityName: 'Austin',
    CityState: 'TX',
    Zone: 'Z7',
    CountryId: 1,
    PostalCode: '73301',
  },
  deliveryLocation: {
    Value: 'CITY_55951',
    Name: 'CHICAGO, IL',
    Id: 55951,
    Geolocation: 'POINT (-87.65 41.85)',
    CityName: 'Chicago',
    CityState: 'IL',
    Zone: 'Z6',
    CountryId: 1,
    PostalCode: '60290',
  },
  pickupDate: new Date(),
};
