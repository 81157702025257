import React from 'react';
import styled from '@emotion/styled';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';
import {
  TableHeader as TableHeaderComponent,
  TableHeaderRow,
} from '@arrive/table';

import { spacing } from 'theme/Units';

interface TableHeaderProps {
  headers: Array<string>;
  dataTestId?: string;
}

const PaddedHeader = styled(Typography)`
  padding: 0px ${spacing.normal} 0px ${spacing.normal};
`;

const TableHeader = ({ headers, dataTestId }: TableHeaderProps) => {
  return (
    <TableHeaderRow>
      {!!headers?.length &&
        headers.map((header) => (
          <TableHeaderComponent key={header}>
            <PaddedHeader
              variant="overline"
              data-testid={`${dataTestId}-${header}`}
            >
              {header}
            </PaddedHeader>
          </TableHeaderComponent>
        ))}
    </TableHeaderRow>
  );
};

export default TableHeader;
