import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';

// @Arrive
import { TableRow } from '@arrive/table';

// @Common Components
import SecondaryButton from 'commonComponents/Buttons/SecondaryButton';
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';
import TitleSubtitle from 'commonComponents/TitleSubtitle';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import ExpirationMessage from 'components/Quotes/QuoteResults/ExpirationMessage';

// @Constants
import { EquipmentTypes } from 'constants/EquipmentType';

// @Hooks
import { useGetLoadLocation } from 'hooks/useGetLoadLocation';
import { useSetQueryParams } from 'hooks/useReactRouterQueries';

// @Styles
import theme from 'theme/Theme';

// @Types
import { LoadLocation, EquipmentTypeOptions } from 'types/quoteTypes';
import { QuoteRowProps } from '../QuoteHistory.types';

// @Utils

import { ExpirationOptions } from 'utils/ExpirationMessageUtils';

const RowData = styled.td<{ paddingBottom?: boolean }>`
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? theme.spacing.normal : '0px'};
  padding-left: ${theme.spacing.normal};
  cursor: pointer;
`;

const QuoteHistoryRow = ({
  data,
  index,
  submitQuote,
  submitDisabled,
}: QuoteRowProps) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const getLocation = useGetLoadLocation();
  const queryClient = useQueryClient();
  const setQueryParams = useSetQueryParams();

  const formattedPickupDate = DateTime.fromFormat(
    data.pickupDate,
    'MMMM dd, yyyy',
  ).toFormat('ccc, LLLL dd');

  const handleSubmitQuote = async () => {
    setIsLoadingSubmit(true);
    await submitQuote(data);
    setIsLoadingSubmit(false);
  };

  const handleShowQuoteResult = async () => {
    try {
      const pickupLocation = (await getLocation(
        data.pickupPostalCode,
      )) as Array<LoadLocation>;
      const deliveryLocation = (await getLocation(
        data.deliveryPostalCode,
      )) as Array<LoadLocation>;
      const quoteData = {
        ...data,
        deliveryGeolocation: deliveryLocation[0].Geolocation,
        pickupGeolocation: pickupLocation[0].Geolocation,
        pickupDateString: formattedPickupDate,
      };
      await queryClient.setQueryData(['quoteDetails'], quoteData);
    } catch (error) {
      console.error(error);
    } finally {
      setQueryParams({ quoteResult: data.quoteId });
    }
  };

  const buttonMessage = ExpirationOptions[data.expirationStatus]?.buttonMessage;

  return (
    <TableRow>
      <RowData
        data-testid={`pickup-${data.pickupLocation}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <TitleSubtitle
          title={data.pickupLocation}
          subtitle={formattedPickupDate}
        />
      </RowData>
      <RowData
        paddingBottom
        data-testid={`delivery-${data.deliveryLocation}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <Typography variant="body1">{data.deliveryLocation}</Typography>
      </RowData>
      <RowData
        data-testid={`quoteReference-${data.quoteReference}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <TitleSubtitle
          title={`$${data.rate.toLocaleString('en-us', {
            maximumFractionDigits: 0,
          })}`}
          subtitle={`${data.quoteReference?.substring(0, 5)}...`}
          variants={{ title: 'h2' }}
        />
      </RowData>
      <RowData
        data-testid={`quoteMiles-${data.totalMiles}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <TitleSubtitle
          title={data.totalMiles?.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          })}
          subtitle={`$${(data.rate / data.totalMiles).toFixed(2)}/mi`}
          variants={{ title: 'body1' }}
        />
      </RowData>
      <RowData
        data-testid={`quoteDate-${data.quoteDate}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <TitleSubtitle
          title={DateTime.fromFormat(data.quoteDate, 'MMMM dd, yyyy').toFormat(
            'LLL dd',
          )}
          subtitle={DateTime.fromFormat(
            data.quoteDate,
            'MMMM dd, yyyy',
          ).toFormat('yyyy')}
          variants={{ title: 'body1' }}
        />
      </RowData>
      <RowData
        paddingBottom
        data-testid={`equipmentType-${data.equipmentType}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <Typography variant="body1">
          {EquipmentTypes[data.equipmentType as EquipmentTypeOptions]
            ?.displayName || ''}
        </Typography>
      </RowData>
      <RowData
        paddingBottom
        data-testid={`mode-${data.mode}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <Typography variant="body1"> {data.mode}</Typography>
      </RowData>
      <RowData
        paddingBottom
        data-testid={`expirationStatus-${data.expirationStatus}-${index}`}
        onClick={() => handleShowQuoteResult()}
      >
        <ExpirationMessage
          expirationStatus={data.expirationStatus}
          timeLeft={data.timeLeftUntilQuoteExpires}
        />
      </RowData>

      <RowData align="center">
        <SecondaryButton
          isDisabled={submitDisabled || isLoadingSubmit}
          onClick={handleSubmitQuote}
          dataTestId={`quote-history-button-${index}`}
          ariaLabel={`quote-history-button-${index}`}
        >
          {isLoadingSubmit && <SpinningLoadingIndicator />}
          <Typography fontSize={theme.fontSizes.small}>
            {buttonMessage}
          </Typography>
        </SecondaryButton>
      </RowData>
    </TableRow>
  );
};

export default QuoteHistoryRow;
