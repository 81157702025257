import styled from "@emotion/styled";

// @Theme
import { palette, sectionSettings } from "theme/Theme";

export const SectionWrapper = styled.div<{ variant: "title" | "heading" | "smokey" | "subtitle", hasBorder?: boolean }>`
padding: ${({ variant }) => `${sectionSettings[variant].padding} 0px`};
border-top: ${({ hasBorder }) =>
      hasBorder && `solid 1px ${palette.common.chrome}`};
`;
