// @Libraries
import SVGIcon from '@arrive/svg-icon';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSizes, spacing } from 'theme/Units';

// @Types
import type { CSSObjectWithLabel, StylesConfig } from 'react-select';

const sizes: { [key: string]: { [key: string]: string } } = {
  md: {
    fontSize: fontSizes.small,
    height: '44px',
    inputPaddingTop: spacing.medium,
    inputSvgYAxis: '2px',
    menuFontSize: fontSizes.xsmall,
    selectedValueHoverHeight: '28px',
    selectedValueSvgPadding: `${spacing.xxsmall} 0px`,
    selectedValueTopPosition: '8px',
    prefixFlexSize: '24px',
    prefixSVGSize: '24px',
  },
  lg: {
    fontSize: fontSizes.medium,
    height: '52px',
    inputPaddingTop: spacing.normal,
    inputSvgYAxis: '2px',
    menuFontSize: fontSizes.small,
    selectedValueHoverHeight: '30px',
    selectedValueSvgPadding: `${spacing.xxsmall} 0px`,
    selectedValueTopPosition: '10px',
    prefixFlexSize: '32px',
    prefixSVGSize: '24px',
  },
  xl: {
    fontSize: fontSizes.large,
    height: '60px',
    inputPaddingTop: spacing.gutter,
    inputSvgYAxis: '6px',
    menuFontSize: fontSizes.medium,
    selectedValueHoverHeight: '30px',
    selectedValueSvgPadding: `${spacing.xxsmall} 0px`,
    selectedValueTopPosition: '12px',
    prefixFlexSize: '36px',
    prefixSVGSize: '28px',
  },
};

export const MenuListContainer = styled.div`
  > div {
    border-radius: 4px;
    padding: ${spacing.xsmall} 0px;
  }
`;

const getSizeForField = (size: string, field: string) =>
  size ? sizes[size][field] : sizes['lg'][field];

export const SelectedValue = styled.div<{
  size: string;
  placeholder: string;
}>`
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  margin-left: ${`-${spacing.xxsmall}`};
  padding: ${spacing.xxsmall};
  height: ${({ size }) => getSizeForField(size, 'selectedValueHoverHeight')};
  position: relative;
  top: ${({ size, placeholder }) =>
    !!placeholder.length && getSizeForField(size, 'selectedValueTopPosition')};
  & svg {
    display: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.common.bandit};
    cursor: pointer;
    & > svg {
      display: block;
      box-sizing: border-box;
      height: ${({ size }) =>
        getSizeForField(size, 'selectedValueHoverHeight')};
      width: ${({ size }) => getSizeForField(size, 'selectedValueHoverHeight')};
    }
  }
`;

export const OptionRow = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    transform: translateX(-4px) !important;
    margin-right: ${spacing.xsmall};
  }
`;

export const OptionPrefix = styled(SVGIcon)`
  padding-right: ${spacing.xsmall};
  margin-top: ${`-${spacing.xxsmall}`};
  margin-left: ${`-${spacing.normal}`};
  height: 24px;
  width: 24px;
  box-sizing: border-box;
`;

export const StyledContainer = styled.div<{
  blackIcons?: boolean;
  isDisabled: boolean;
}>`
  font-family: ${(props) => props.theme.fontFamily};
  svg {
    fill: ${({ theme, blackIcons, isDisabled }) =>
      (isDisabled && theme.palette.common.LFGray) ||
      (blackIcons && theme.palette.common.axle)} !important ;
  }
`;

export const PrefixContainer = styled.div<{ size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${({ size }) => getSizeForField(size, 'prefixFlexSize')};
  padding-left: ${spacing.small};
  padding-top: ${({ size }) => getSizeForField(size, 'prefixTopPadding')};

  svg {
    font-size: ${({ size }) => getSizeForField(size, 'prefixSVGSize')};
    fill: ${(props) => props.theme.palette.common.arrive};
  }
`;

export const getConfigStyles = (
  theme: Theme,
  isWarning = false,
): StylesConfig => {
  const {
    palette: { common },
  } = theme;
  return {
    control: (
      styles: CSSObjectWithLabel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { isDisabled, isFocused, selectProps }: any,
    ) => {
      return {
        ...styles,
        backgroundColor: common.ethanol,
        borderColor:
          (isWarning && common.accessibleYellow) ||
          (isDisabled && common.tandem) ||
          (isFocused && common.arrive) ||
          common.chrome,
        boxShadow:
          (isWarning && `0 0 0 1px ${common.accessibleYellow}`) ||
          (isFocused && `0 0 0 1px ${common.arrive}`) ||
          'none',
        fontSize: getSizeForField(selectProps.size, 'fontSize'),
        height: getSizeForField(selectProps.size, 'height'),
        ':hover': {
          borderColor:
            (isWarning && common.accessibleYellow) ||
            (isDisabled && common.tandem) ||
            (isFocused && common.arrive) ||
            common.axle,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indicatorsContainer: (styles: CSSObjectWithLabel, { isDisabled }: any) => ({
      ...styles,
      svg: {
        fill: isDisabled ? common.chrome : common.arrive,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (styles: CSSObjectWithLabel, { hasValue, selectProps }: any) => ({
      ...styles,
      color: common.axle,
      paddingTop:
        (!!selectProps.placeholder &&
          (hasValue || selectProps.inputValue) &&
          getSizeForField(selectProps.size, 'inputPaddingTop')) ||
        'inherit',
      margin: '0px',
    }),
    option: (
      styles: CSSObjectWithLabel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { isFocused, selectProps, isSelected, isMulti }: any,
    ) => ({
      ...styles,
      backgroundColor: isFocused ? common.bandit : common.ethanol,
      fontSize: getSizeForField(selectProps.size, 'menuFontSize'),
      paddingTop: spacing.small,
      paddingBottom: spacing.small,
      paddingLeft: isMulti ? spacing.large : spacing.normal,
      lineHeight: selectProps.size && selectProps.size === 'xl' && '30px',
      fontWeight: 400,
      maxHeight: 'fit-content',
      minHeight: '24px',
      alignItems: 'center',
      ':active': {
        backgroundColor: common.bandit,
      },
      color: selectProps.arriveMenuDropdownStyle ? common.smokey : 'inherit',
      svg: isSelected &&
        isMulti && {
          marginLeft: `-${spacing.gutter}`,
          transform: `translateY(${
            selectProps.size
              ? sizes[selectProps.size].inputSvgYAxis
              : sizes['lg'].inputSvgYAxis
          }) scale(1.4)`,
          position: 'absolute',
        },
    }),
    placeholder: (
      styles: CSSObjectWithLabel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { hasValue, isDisabled, selectProps }: any,
    ) => ({
      ...styles,
      fontWeight: '400',
      color: isDisabled ? common.chrome : common.smokey,
      position: 'absolute',
      left: selectProps.prefix ? '4px' : '12px',
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transformOrigin: '0% 0%',
      transition: 'transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s',
      transform:
        (hasValue || selectProps.inputValue) && 'translateY(-10px) scale(0.75)',
      whiteSpace: 'nowrap',
      marginLeft: spacing.xxsmall,
    }),
    valueContainer: (
      styles: CSSObjectWithLabel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { isDisabled, selectProps }: any,
    ) => ({
      ...styles,
      paddingLeft: selectProps.prefix ? spacing.xsmall : spacing.normal,
      paddingTop: '0px',
      display: 'flex',
      alignItems: 'center',
      color: isDisabled ? common.chrome : common.axle,
      height: '100%',
      pointerEvents: !isDisabled && selectProps.isClearable ? 'auto' : 'none',
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (styles: CSSObjectWithLabel, { selectProps }: any) => ({
      ...styles,
      border: selectProps.arriveMenuDropdownStyle
        ? `2px solid ${common.arrive}`
        : 'none',
      boxShadow: '0 4px 4px hsl(0deg 0% 0% / 25%)',
    }),
  };
};

export const NoOptionsMessage = styled.span`
  font-size: ${fontSizes.medium};
  display: flex;
  color: ${(props) => props.theme.palette.common.black};
  padding: ${spacing.xsmall} ${spacing.xsmall};
`;

export const AddNewOption = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.palette.common.arrive};
  display: flex;
  align-items: center;
  padding: ${spacing.normal};

  &:hover {
    background: ${(props) => props.theme.palette.common.bandit};
  }

  svg {
    fill: ${(props) => props.theme.palette.common.arrive};
    padding-right: ${spacing.xxsmall};
    font-size: ${fontSizes.large};
  }
`;

export const SortByOptionItem = styled.div<{ isSelected: boolean }>`
  font-size: ${fontSizes.medium};
  transform: translateX(44px);
`;

export const SortByOptionRow = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & svg {
    display: ${({ isSelected }) => !isSelected && 'none'};
    width: '24px';
    height: '24px';
    position: absolute;
  }
`;
