import React from 'react';
import { DateTime } from 'luxon';

// @Common components
import PillDateRangePicker from 'commonComponents/DateTimePickers/PillDateRangePicker';

// @Helpers
import {
  formatQueryDateString,
  getDateFilterTitle,
} from 'components/Shipments/ShipmentsHelpers';

// @Types
import {
  ShipmentDateFiltersProps,
  ShipmentFiltersActionKind,
} from 'components/Shipments/ShipmentPage.types';

const ShipmentDateFilters = ({
  dispatch,
  stopType,
  dateFilters,
}: ShipmentDateFiltersProps) => {
  const { date, dateEnd } = dateFilters;

  const minDate = DateTime.local().minus({ years: 1 }).toJSDate();
  const maxDate = DateTime.local().plus({ years: 1 }).toJSDate();

  const handleApply = () => {
    dispatch({
      type: ShipmentFiltersActionKind.APPLY_FILTERS,
      payload: {
        [`${stopType}Date`]: formatQueryDateString(date),
        [`${stopType}DateEnd`]: formatQueryDateString(dateEnd),
      },
    });
  };

  const handleClear = () => {
    const emptyDates = {
      [`${stopType}Date`]: null,
      [`${stopType}DateEnd`]: null,
    };
    dispatch({
      type: ShipmentFiltersActionKind.SET_DATES,
      payload: emptyDates,
    });
    dispatch({
      type: ShipmentFiltersActionKind.APPLY_FILTERS,
      payload: emptyDates,
    });
  };

  const handleReset = () => {
    dispatch({
      type: ShipmentFiltersActionKind.RESET_STATE,
    });
  };

  return (
    <>
      <PillDateRangePicker
        rangeStart={date}
        rangeEnd={dateEnd}
        setRangeStart={(range: Date | null) =>
          dispatch({
            type: ShipmentFiltersActionKind.SET_DATES,
            payload: {
              [`${stopType}Date`]: range,
            },
          })
        }
        setRangeEnd={(range: Date | null) =>
          dispatch({
            type: ShipmentFiltersActionKind.SET_DATES,
            payload: {
              [`${stopType}DateEnd`]: range,
            },
          })
        }
        placeholderStart={getDateFilterTitle(date, dateEnd, stopType)}
        minDate={minDate}
        maxDate={maxDate}
        handleReset={handleReset}
        handleClear={handleClear}
        handleApply={handleApply}
      />
    </>
  );
};

export default ShipmentDateFilters;
