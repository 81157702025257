import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';

const qsParseOptions = { ignoreQueryPrefix: true };
const defaultQueryParams = {
  quoteResult: null,
  pageSize: null,
  pageNumber: null,
};

/**
 * This hook deserializes the search param value using the qs library, which allow us to retrieve
 * values from the URL itself.
 *
 * Note: If expecting integer values, make sure you parseInt them prior using.
 *
 * How to use
 * const {customerId} = useQueryParams()
 * @return string value for the given parameter
 */
export const useQueryParams = (): ParsedQs => {
  const { search } = useLocation();

  return useMemo(() => {
    return qs.parse(search, qsParseOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};

/**
 * This hook serializes the search param value using the qs library, which permits any
 * JavaScript value to be safely URL-encoded.
 *
 * How to use
 * const setQueryParams = useSetQueryParams()
 * set the query inside in any handle function
 * setQueryParams({sortBy: 'desc', loggedBy: 'Someone'})
 *
 * @param {Object} stringifyOptions https://github.com/ljharb/qs#stringifying
 * @return JavaScript value that is safely URL-encoded.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSetQueryParams = (stringifyOptions = {}) => {
  const qsStringifyOptions = {
    skipNulls: true,
    addQueryPrefix: true,
  };

  const history = useHistory();
  const { pathname, search } = history.location;
  const queryParams = qs.parse(search, qsParseOptions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (newParams: { [key: string]: any }, reset = true) => {
    const currentQueryParams = reset ? defaultQueryParams : queryParams;
    const newQueryString = qs.stringify(
      { ...currentQueryParams, ...newParams },
      { ...qsStringifyOptions, ...stringifyOptions },
    );

    history.replace(`${pathname}${newQueryString}`);
  };
};
