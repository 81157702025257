import React from 'react';
import styled from '@emotion/styled';

// @Common
import { spacing } from 'theme/Units';

// @Components
import Typography from 'commonComponents/Typography/Typography';

// @Helpers
import {
  ExpirationOptions,
  ExpirationTypes,
} from 'utils/ExpirationMessageUtils';

// @Icons
import Clock from '@arrive/ui-icons/svg/ic_clock.svg';

// @Types
import { ExpirationCountdownType } from 'types/quoteTypes.d';

const StyledExpirationMessage = styled(Typography)<{
  color: string;
  direction: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => direction === 'row' && 'center'};
  gap: ${spacing.xsmall};

  svg {
    position: relative;
    margin-right: ${spacing.xxsmall};
    transform: scale(0.75);
    fill: ${({ color }) => color};
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ExpirationCountdownProps {
  countdown: ExpirationCountdownType;
  dataTestId?: string;
}

const ExpirationCountdown = ({
  countdown,
  dataTestId,
}: ExpirationCountdownProps) => {
  return (
    <CountdownContainer>
      <Clock />
      <span data-testid={`${dataTestId}-expiration-countdown`}>
        {`${countdown?.hours} HR ${countdown?.minutes} MIN`}
      </span>
    </CountdownContainer>
  );
};

interface ExpirationMessageProps {
  expirationStatus: ExpirationTypes;
  align?: 'inherit' | 'center' | 'left' | 'justify' | 'right';
  dataTestId?: string;
  timeLeft: ExpirationCountdownType;
  theme?: 'light' | 'dark';
  direction?: 'column' | 'row';
}

const ExpirationMessage = ({
  expirationStatus,
  align,
  dataTestId,
  timeLeft,
  theme = 'light',
  direction = 'column',
}: ExpirationMessageProps) => {
  const updatedExpirationStatus =
    expirationStatus === ExpirationTypes.EXPIRING && timeLeft.expired
      ? ExpirationTypes.EXPIRED
      : expirationStatus;

  const showCountdown = updatedExpirationStatus === ExpirationTypes.EXPIRING;
  const expirationOption = ExpirationOptions[updatedExpirationStatus];
  const expirationColor =
    theme === 'light' ? expirationOption?.color : expirationOption?.darkColor;

  return (
    <StyledExpirationMessage
      data-testid={`${dataTestId}-expiration-message`}
      variant="overline"
      align={align}
      color={expirationColor || ''}
      direction={direction}
    >
      {updatedExpirationStatus}
      {showCountdown && (
        <ExpirationCountdown countdown={timeLeft} dataTestId={dataTestId} />
      )}
    </StyledExpirationMessage>
  );
};

export default ExpirationMessage;
