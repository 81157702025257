import React, { forwardRef, ReactNode } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

// @arrive
import Clock from '@arrive/ui-icons/svg/ic_clock.svg';

// @Components
import { DateTimePickerInput } from './DateTimePickersElements';

// @Styles
import { StyledInputError } from 'styles/ErrorStyles';
import { StyledTimeContainer } from './DateTimePickerStyles';

type TimeContainerProps = { children: ReactNode[] };

const TimeContainer = ({ children }: TimeContainerProps) => {
  return <StyledTimeContainer>{children}</StyledTimeContainer>;
};

type TimeInputProps = ReactDatePickerProps & {
  ariaLabel: string;
  dataTestId: string;
  onClick: () => void;
};

const TimeInput = forwardRef(
  (
    {
      ariaLabel,
      dataTestId,
      disabled,
      onClick,
      placeholderText,
      required,
      value,
      ...rest
    }: TimeInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const renderInput = () => {
      return (
        // @ts-ignore - added to be able to use the onChange prop coming from the custom input
        <input
          {...rest}
          value={value}
          maxLength={5}
          aria-label={ariaLabel}
          data-testid={dataTestId}
          ref={ref}
        />
      );
    };
    return (
      <DateTimePickerInput
        svgIcon={<Clock />}
        hasValue={!!value}
        onClick={onClick}
        required={required}
        disabled={disabled}
        placeholderText={placeholderText}
      >
        {renderInput()}
      </DateTimePickerInput>
    );
  },
);

TimeInput.displayName = 'TimeInput';

interface TimePickerProps extends ReactDatePickerProps {
  ariaLabel: string;
  dataTestId: string;
  errorText?: string;
}

const TimePicker = ({
  placeholderText,
  errorText,
  ...rest
}: TimePickerProps) => {
  return (
    <div>
      <DatePicker
        calendarContainer={TimeContainer}
        customInput={
          <TimeInput
            ariaLabel={rest.ariaLabel}
            dataTestId={rest.dataTestId}
            placeholderText={placeholderText}
            onChange={() => {
              return;
            }}
            onClick={() => {
              return;
            }}
          />
        }
        timeIntervals={15}
        showTimeSelectOnly
        showTimeSelect
        dateFormat="HH:mm"
        popperModifiers={[
          {
            // @ts-ignore - sameWidth name is needed to make the options of the same width of the input
            name: 'sameWidth',
            enabled: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fn: ({ state }: { state: any }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            phase: 'beforeWrite',
            requires: ['computeStyles'],
          },
        ]}
        {...rest}
      />
      {!!errorText?.length && <StyledInputError>{errorText}</StyledInputError>}
    </div>
  );
};

export default TimePicker;
