import React from 'react';

//Common Components
import Typography from 'commonComponents/Typography/Typography';
import ContactInformation from 'commonComponents/Footer/ContactInformation/ContactInformation';

// @SVGs
import ArriveLogo from '@arrive/ui-icons/svg/arrive_logo_solo.svg';
import Twitter from 'icons/SocialMedia/Twitter.svg';
import Facebook from 'icons/SocialMedia/Facebook.svg';
import Instagram from 'icons/SocialMedia/Instagram.svg';
import LinkedIn from 'icons/SocialMedia/Linkedin.svg';

// @Styles
import {
  FooterContainer,
  ContactInformationContainer,
  ExternalInformationContainer,
  TopContainer,
  BottomContainer,
  CategoryContainer,
  FooterLink,
  SocialMediaContainer,
  SocialMediaLink,
} from './FooterStyles';

const Footer = () => {
  return (
    <FooterContainer>
      <ContactInformationContainer>
        <ContactInformation />
      </ContactInformationContainer>
      <ExternalInformationContainer data-testid="footer-container">
        <TopContainer data-testid="footer-information-container">
          <ArriveLogo />
          <CategoryContainer>
            <Typography
              variant="subtitle2"
              margin="0 0 0 0"
              padding="0 0 8px 0"
            >
              ABOUT
            </Typography>
            <FooterLink
              href="https://www.arrivelogistics.com/about/"
              target="_blank"
            >
              Our Story
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/careers/"
              target="_blank"
            >
              Careers
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/category/press/"
              target="_blank"
            >
              Press
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/category/awards/"
              target="_blank"
            >
              Awards
            </FooterLink>
          </CategoryContainer>
          <CategoryContainer>
            <Typography
              variant="subtitle2"
              margin="0 0 0 0"
              padding="0 0 8px 0"
            >
              RESOURCES
            </Typography>
            <FooterLink
              href="https://www.arrivelogistics.com/category/market-insights/"
              target="_blank"
            >
              Insights
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/glossary/"
              target="_blank"
            >
              Glossary
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/privacy-policy/"
              target="_blank"
            >
              Privacy
            </FooterLink>
            <FooterLink
              href="https://www.arrivelogistics.com/website-terms-of-use/"
              target="_blank"
            >
              Terms of Use
            </FooterLink>
          </CategoryContainer>
        </TopContainer>
        <BottomContainer data-testid="footer-copyright-social-media-container">
          <Typography variant="body2" margin="0 0 0 0">
            &copy; 2020 Arrive Logistics. All rights reserved
          </Typography>
          <SocialMediaContainer>
            <SocialMediaLink
              href="https://www.facebook.com/ArriveWithUs"
              target="_blank"
            >
              <Facebook />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://twitter.com/arrivelogistics"
              target="_blank"
            >
              <Twitter />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://www.instagram.com/arrivelogistics/"
              target="_blank"
            >
              <Instagram />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://www.linkedin.com/company/arrive-logistics"
              target="_blank"
            >
              <LinkedIn />
            </SocialMediaLink>
          </SocialMediaContainer>
        </BottomContainer>
      </ExternalInformationContainer>
    </FooterContainer>
  );
};

export default Footer;
