import React, { ReactElement } from 'react';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Components
import { ShipmentPickupDeliveryContentLoader } from './ShipmentDetailsContentLoaders';

// @Icons
import DELIVERY_ICON from '@arrive/ui-icons/svg/ic_down_right_arrow.svg';
import PICKUP_ICON from '@arrive/ui-icons/svg/ic_up_right_arrow.svg';

// @Styles
import {
  ShipmentPickupDeliveryContainer,
  ShipmentPickupDeliveryDetails,
  ShipmentDestination,
} from './ShipmentDetailsPanelStyles';
import { palette } from 'theme/Theme';

// @Types
import { ShipmentPickupDeliveryInformationProps } from 'components/Shipments/ShipmentPage.types';

// @Utils
import { formatDates } from 'utils/utils';

const ShipmentPickupDeliveryInformation = ({
  pickupDetails,
  deliveryDetails,
  isLoading,
}: ShipmentPickupDeliveryInformationProps): ReactElement => {
  const renderDate = (
    earliestTime: number | undefined,
    latestTime: number | undefined,
    timeZone: string | undefined,
  ) => {
    const date = formatDates(earliestTime, latestTime, timeZone)?.split('-');

    if (date) {
      if (date[1]) {
        return (
          <>
            <Typography
              variant="body2"
              data-testid="shipment-details-pickup-delivery-date"
            >
              {formatDates(earliestTime, undefined, timeZone)} -
            </Typography>
            <Typography variant="body2">
              {formatDates(undefined, latestTime, timeZone)}
            </Typography>
          </>
        );
      }

      return (
        <Typography
          variant="body2"
          data-testid="shipment-details-pickup-delivery-date"
        >
          {date[0]}
        </Typography>
      );
    }

    return null;
  };

  if (isLoading) {
    return <ShipmentPickupDeliveryContentLoader />;
  }

  return (
    <ShipmentPickupDeliveryContainer>
      <div>
        <ShipmentDestination>
          <PICKUP_ICON />
          <Typography
            variant="subtitle1"
            data-testid="shipment-pickup-city-state"
          >
            {pickupDetails?.CityState}
          </Typography>
        </ShipmentDestination>
        <ShipmentPickupDeliveryDetails data-testid="shipment-pickup-information">
          {renderDate(
            pickupDetails?.EarliestTime,
            pickupDetails?.LatestTime,
            pickupDetails?.TimeZoneIANA,
          )}
          <Typography
            variant="body2"
            color={palette.common.smokey}
            data-testid="shipment-pickup-scheduling-type"
          >
            {pickupDetails?.SchedulingType}
          </Typography>
        </ShipmentPickupDeliveryDetails>
      </div>
      <div>
        <ShipmentDestination>
          <DELIVERY_ICON />
          <Typography
            variant="subtitle1"
            data-testid="shipment-delivery-city-state"
          >
            {deliveryDetails?.CityState}
          </Typography>
        </ShipmentDestination>
        <ShipmentPickupDeliveryDetails data-testid="shipment-delivery-information">
          {renderDate(
            deliveryDetails?.EarliestTime,
            deliveryDetails?.LatestTime,
            deliveryDetails?.TimeZoneIANA,
          )}
          <Typography
            variant="body2"
            color={palette.common.smokey}
            data-testid="shipment-delivery-scheduling-type"
          >
            {deliveryDetails?.SchedulingType}
          </Typography>
        </ShipmentPickupDeliveryDetails>
      </div>
    </ShipmentPickupDeliveryContainer>
  );
};

export default ShipmentPickupDeliveryInformation;
