import { useMutation, useQueryClient } from '@tanstack/react-query';

// @Api
import { AccountEndpoints } from 'api/endpoints';

// @Hooks
import useAxios from 'hooks/useAxios';

export const useUpdateTermsAcceptance = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      await axios.put(AccountEndpoints.saveAgreement);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['shipperPortalUserInfo'], {
          UserHasAcceptedAgreement: true,
          AcceptanceDateTime: Date.now(),
        });
      },
      onSettled: () => {
        queryClient.resetQueries(['shipperPortalUserInfo']);
      },
    },
  );
};
