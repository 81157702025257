import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';
import SecondaryButton from 'commonComponents/Buttons/SecondaryButton';
import { SpinningLoadingIndicator } from 'commonComponents/InlineLoadingIndicators';

// @Hooks
import useCopyToClipboard from 'hooks/useCopyToClipboard';

// @Icons
import Copy from 'icons/Copy.svg';

// @Libraries
import { ToastContext } from '@arrive/toasts';
import Tooltip from '@arrive/tooltip';

// @Styles
import {
  RateResultsSection,
  SubmitButton,
  CopyQuoteReference,
  ButtonsContainer,
  QuoteIdContainer,
  SubmitContainer,
  RateContainer,
} from './QuoteResultsStyles';

// @Types
import { QuoteRate, FullQuoteDetails } from 'types/quoteTypes';

// @Utils
import {
  ExpirationOptions,
  ExpirationTypes,
} from 'utils/ExpirationMessageUtils';

// @Theme
import { palette } from 'theme/Theme';

type RateResultsProps = {
  quoteDetails: FullQuoteDetails;
  submitQuote: (quote: QuoteRate) => Promise<void>;
};

const RateResults = ({ quoteDetails, submitQuote }: RateResultsProps) => {
  const history = useHistory();
  const [_value, copyToClipboard] = useCopyToClipboard();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const addToastNotification = useContext(ToastContext);

  const buttonMessage =
    ExpirationOptions[quoteDetails.expirationStatus]?.buttonMessage;

  const handleSubmitQuote = async () => {
    setIsLoadingSubmit(true);
    await submitQuote(quoteDetails);
    setIsLoadingSubmit(false);
  };

  const handleCopyToClipboard = (quoteReference: string) => {
    copyToClipboard(quoteReference);
    addToastNotification({
      id: 'quoteRef-copied',
      type: 'message',
      content: 'Quote reference number is copied!',
    });
  };

  const handleViewConfirmation = () => {
    history.replace(`/shipment-confirmation/${quoteDetails.shipmentId}`);
  };

  if (!quoteDetails.rate) {
    return (
      <RateResultsSection data-testid="no-quote-rate">
        <Typography
          variant="body1"
          align="justify"
          as="p"
          margin="24px 0px"
          color={palette.common.ethanol}
        >
          Looks like we need more information to give you the best price. Reach
          out and we’ll get back to you ASAP!
        </Typography>
      </RateResultsSection>
    );
  }

  return (
    <RateResultsSection data-testid="quote-rate">
      <QuoteIdContainer>
        <Tooltip
          placement="top"
          popperContent={<div>{quoteDetails.quoteReference}</div>}
        >
          <Typography
            variant="body2"
            color={palette.common.LFGray}
            margin="0px"
            padding="0px"
          >
            {`Quote #${quoteDetails.quoteReference.substring(0, 8)}...`}
          </Typography>
        </Tooltip>
        <CopyQuoteReference
          data-testid="copy-quote-reference"
          onClick={() => handleCopyToClipboard(quoteDetails.quoteReference)}
        >
          <Copy />
        </CopyQuoteReference>
      </QuoteIdContainer>
      <SubmitContainer>
        <RateContainer>
          <Typography
            color={palette.common.ethanol}
            variant="display1"
            margin="0px"
          >
            {`$${quoteDetails.rate.toLocaleString('en-us', {
              maximumFractionDigits: 0,
            })}`}
          </Typography>
          <Typography
            color={palette.common.LFGray}
            variant="body2"
            margin="0px"
          >
            {' '}
            ${(quoteDetails.rate / quoteDetails.totalMiles).toFixed(2)}/mile
          </Typography>
        </RateContainer>
        <ButtonsContainer>
          <div>
            <SubmitButton
              onClick={handleSubmitQuote}
              disabled={isLoadingSubmit}
            >
              {isLoadingSubmit && <SpinningLoadingIndicator />}
              {buttonMessage}
            </SubmitButton>
          </div>
          {quoteDetails.expirationStatus === ExpirationTypes.ACCEPTED && (
            <SecondaryButton
              onClick={handleViewConfirmation}
              dataTestId="view-confirmation"
              ariaLabel="view-confirmation"
            >
              View Confirmation
            </SecondaryButton>
          )}
        </ButtonsContainer>
      </SubmitContainer>
    </RateResultsSection>
  );
};

export default RateResults;
