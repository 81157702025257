import React, { ReactElement, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// @Arrive
import Button from '@arrive/button';
import TextEntry from '@arrive/text-entry';

// @CommonComponents
import SearchableDropdown, {
  CreatableAsyncSearchableDropdown,
} from 'commonComponents/Dropdowns/SearchableDropdown';
import AddNewOptionDropdown from 'commonComponents/Dropdowns/AddNewOptionDropdown';

// @Helpers
import { getDefaultAppointment } from '../ShipmentFormHelpers';

//@Hooks
import { useGetAddressSuggestionLoadOptions } from 'hooks/useGetAddressSuggestions';
import { useGetFacilities } from 'components/ShipmentForm/useShipmentForm';
import useReadLocalStorage from 'hooks/useReadLocalStorage';

// @Styles
import {
  CityContainer,
  AddNewFacilityHeader,
} from './PickupDeliveryFormStyles';
import { FormContainer, ShipmentFormSubHeading } from '../ShipmentFormStyles';

// @Types
import { FacilityResult } from 'types/Facility.types';
import { LocationServiceAddress } from 'types/Address.types';
import { PickupDeliveryType, ShipmentFormEnums } from 'types/Shipment.types';
import { FacilityProps } from '../ShipmentForm.types';

const Facility = ({
  address1,
  address2,
  cityState,
  facilityId,
  facilityName,
  handlePickupDeliveryInputChange,
  pickupDeliveryType,
  postalCode,
  setIsNewFacility,
  setShipmentDetails,
  shipmentDetails,
  isValidatedLocation,
}: FacilityProps): ReactElement => {
  const queryClient = useQueryClient();
  const ShipmentFormEnums = queryClient.getQueryData([
    'shipmentFormEnums',
  ]) as ShipmentFormEnums;
  const [city, state] = cityState!.split(', ');
  const [disableAddressSection, setDisableAddressSection] =
    useState<boolean>(false);
  const isNewFacility = useReadLocalStorage<{
    pickup: boolean;
    delivery: boolean;
  }>('newFacility');

  const loadAddressOptions = useGetAddressSuggestionLoadOptions({
    city,
    state,
    postalCode,
  });

  const { data: Facilities, isInitialLoading: isLoadingFacilities } =
    useGetFacilities({
      city,
      state,
      postalCode,
    });

  useEffect(() => {
    setDisableAddressSection(!!Facilities?.length);
  }, [Facilities]);

  const resetFacilityInformation = () => {
    return setShipmentDetails({
      ...shipmentDetails,
      [pickupDeliveryType]: {
        ...shipmentDetails[pickupDeliveryType],
        address1: null,
        address2: '',
        facilityClose: null,
        facilityId: null,
        facilityName: '',
        facilityOpen: null,
        schedulingType: null,
        schedulingContact: {
          fullName: null,
          phoneNumber: null,
          email: null,
        },
      },
    });
  };

  const handleChangeFacility = (e: FacilityResult | null) => {
    if (e) {
      const { Address1, Address2, FacilityId, Name, FacilityHours, Contacts } =
        e;

      const { MondayTo, MondayFrom } = FacilityHours;

      return setShipmentDetails({
        ...shipmentDetails,
        [pickupDeliveryType]: {
          ...shipmentDetails[pickupDeliveryType],
          appointment: getDefaultAppointment(
            pickupDeliveryType,
            shipmentDetails.pickupDate,
          ),
          address1: Address1,
          address2: Address2,
          facilityClose: MondayTo ? MondayTo.slice(0, -3) : null,
          facilityId: FacilityId,
          facilityName: Name,
          facilityOpen: MondayFrom ? MondayFrom.slice(0, -3) : null,
          schedulingType: ShipmentFormEnums.schedulingTypes.find(
            (option) => option.accelerateId === FacilityHours.AppointmentTypeId,
          )?.enumValue,
          schedulingContact: !!Contacts.length
            ? {
                fullName: `${Contacts[0].FirstName} ${
                  Contacts[0].LastName ? Contacts[0].LastName : ''
                }`,
                phoneNumber: Contacts[0].OfficePhoneNumber,
                email: Contacts[0].EmailAddress,
              }
            : {
                fullName: null,
                phoneNumber: null,
                email: null,
              },
          isValidatedLocation: true,
        },
      });
    }

    return resetFacilityInformation();
  };

  const handleAddNewFacility = () => {
    // @ts-ignore
    setIsNewFacility({
      ...isNewFacility,
      [pickupDeliveryType]: true,
    });
    setDisableAddressSection(false);
    resetFacilityInformation();
  };

  const handleCancelAddNewFacility = () => {
    setDisableAddressSection(true);
    // @ts-ignore
    setIsNewFacility({
      ...isNewFacility,
      [pickupDeliveryType]: false,
    });
    resetFacilityInformation();
  };

  const renderFacilityInfo = () => {
    if (disableAddressSection && !isNewFacility![pickupDeliveryType]) {
      return (
        <>
          <AddNewOptionDropdown
            addNewOptionOnClick={handleAddNewFacility}
            addNewOptionString="Add New Facility"
            dataTestId="add-new-facility"
            getOptionLabel={(option) => (option as FacilityResult).Name}
            isClearable
            isLoading={isLoadingFacilities}
            noOptionMessageString="No matching facility found for the given selection"
            options={Facilities as FacilityResult[]}
            placeholder="Choose a facility"
            required
            getOptionSubtext={(option) =>
              `${(option as FacilityResult).Address1}, ${
                !!address2?.length
                  ? `${(option as FacilityResult).Address2},`
                  : ''
              } 
          ${(option as FacilityResult).City},
          ${(option as FacilityResult).StateCode} ${
                (option as FacilityResult).PostalCode
              }
          `
            }
            onChange={(e) => handleChangeFacility(e as FacilityResult)}
            value={Facilities?.find(
              (facility) => facility.FacilityId === facilityId,
            )}
          />
          <ShipmentFormSubHeading>Address</ShipmentFormSubHeading>
        </>
      );
    }

    return (
      <AddNewFacilityHeader>
        <ShipmentFormSubHeading>Add New Facility</ShipmentFormSubHeading>
        {!!Facilities?.length && (
          <Button text onClick={handleCancelAddNewFacility}>
            Cancel
          </Button>
        )}
      </AddNewFacilityHeader>
    );
  };

  /* istanbul ignore next  - Added test but for some reason disabled input brings test coverage down*/
  return (
    <FormContainer>
      <ShipmentFormSubHeading>Facility</ShipmentFormSubHeading>
      {renderFacilityInfo()}

      <TextEntry
        data-testid="facility-name"
        label="Facility Name"
        name="facilityName"
        onChange={(e) =>
          handlePickupDeliveryInputChange(
            e.target.name as keyof PickupDeliveryType,
            e.target.value,
          )
        }
        required
        value={facilityName || ''}
        disabled={disableAddressSection && !isNewFacility![pickupDeliveryType]}
      />
      <CreatableAsyncSearchableDropdown
        aria-label="address1"
        dataTestId="street-address1"
        getOptionLabel={(option) => (option as LocationServiceAddress).label}
        getOptionValue={(option) =>
          (option as LocationServiceAddress).streetAddress
        }
        isClearable
        isWarning={!!address1?.length && !isValidatedLocation}
        loadOptions={loadAddressOptions}
        name="address1"
        placeholder="Street Address"
        required
        value={address1?.length ? [{ address1, label: address1 }] : []}
        getNewOptionData={(inputValue: string) => {
          return {
            address1: inputValue,
            label: inputValue,
          };
        }}
        onChange={(e) => {
          if (e) {
            return setShipmentDetails({
              ...shipmentDetails,
              [pickupDeliveryType]: {
                ...shipmentDetails[pickupDeliveryType],
                address1: (e as LocationServiceAddress).streetAddress,
                isValidatedLocation: true,
              },
            });
          }
          return setShipmentDetails({
            ...shipmentDetails,
            [pickupDeliveryType]: {
              ...shipmentDetails[pickupDeliveryType],
              address1: null,
              isValidatedLocation: false,
            },
          });
        }}
        onCreateOption={(e) => {
          return setShipmentDetails({
            ...shipmentDetails,
            [pickupDeliveryType]: {
              ...shipmentDetails[pickupDeliveryType],
              address1: e,
              isValidatedLocation: false,
            },
          });
        }}
        errorText={
          !isValidatedLocation && !!address1?.length
            ? 'This address could not be confirmed. Using an incorrect address could result in delays or fees.'
            : ''
        }
        formatCreateLabel={(inputValue) => inputValue}
        isDisabled={
          disableAddressSection && !isNewFacility![pickupDeliveryType]
        }
      />
      <TextEntry
        data-testid="facility-address-two"
        label="Unit, Apartment, Ste"
        maxLength={250}
        name="address2"
        onChange={(e) =>
          handlePickupDeliveryInputChange(
            e.target.name as keyof PickupDeliveryType,
            e.target.value,
          )
        }
        value={address2 || ''}
        disabled={disableAddressSection && !isNewFacility![pickupDeliveryType]}
      />
      <CityContainer>
        <TextEntry
          data-testid="facility-city"
          disabled
          label="City"
          name="city"
          onChange={() => handlePickupDeliveryInputChange('cityState', city)}
          value={city || ''}
        />
        <SearchableDropdown
          aria-label="state"
          dataTestId="facility-state"
          isDisabled
          placeholder="State"
          value={{ label: state, value: state }}
        />
        <TextEntry
          data-testid="facility-postal-code"
          disabled
          label="Postal Code"
          name="postalCode"
          onChange={() =>
            handlePickupDeliveryInputChange('postalCode', postalCode)
          }
          value={postalCode || ''}
        />
      </CityContainer>
    </FormContainer>
  );
};

export default Facility;
