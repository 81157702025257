import styled from '@emotion/styled';
import { spacing } from 'theme/Units';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: ${spacing.gutter};
`;

export const PickupDeliveryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.normal};
`;

export const EQContainer = styled.div`
  margin-left: ${spacing.normal};
  width: 240px;
`;

export const DestinationContainer = styled.div`
  width: 600px;
  margin-right: ${spacing.normal};
`;
export const DatesContainer = styled.div`
  width: 280px;
`;
